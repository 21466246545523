export const lebanonData = [
	{
		"id": 1,
		"GovernorateDescription": "Akkar - عكار",
		"Districts": [
			{
				"id": 1,
				"DistrictDescription": "Akkar - عكار",
				"Cities": [
					{
						"id": 1944,
						"region_name": "Aadouiye - عدوي"
					},
					{
						"id": 1945,
						"region_name": "Aaidamoun - عيدمون"
					},
					{
						"id": 1946,
						"region_name": "Aaiyat - عيات"
					},
					{
						"id": 1947,
						"region_name": "Aakkar El Attiqa - عكار العتيقة"
					},
					{
						"id": 1948,
						"region_name": "Aamara - عماره"
					},
					{
						"id": 1949,
						"region_name": "Aaouinat - العوينات"
					},
					{
						"id": 1950,
						"region_name": "Aarab Jourmnaya - جرمنايا"
					},
					{
						"id": 1951,
						"region_name": "Aarida - عريضا"
					},
					{
						"id": 1952,
						"region_name": "Aarmeh - عرمه"
					},
					{
						"id": 1953,
						"region_name": "Aarqa - عرقا"
					},
					{
						"id": 1954,
						"region_name": "Aayoun - عيون "
					},
					{
						"id": 1955,
						"region_name": "Aayoun El Ghezlane - عيون الغزلان"
					},
					{
						"id": 1956,
						"region_name": "Abde - العبده"
					},
					{
						"id": 1957,
						"region_name": "Ain er Rsas - عين الراس"
					},
					{
						"id": 1958,
						"region_name": "Ain ez Zeit - عين الزيت"
					},
					{
						"id": 1959,
						"region_name": "Ain Faouar - عين الفوار"
					},
					{
						"id": 1960,
						"region_name": "Ain Tinta - عين تنتا"
					},
					{
						"id": 1961,
						"region_name": "Ain Yaaqoub - عين يعقوب"
					},
					{
						"id": 1962,
						"region_name": "Akroum - اكروم"
					},
					{
						"id": 1963,
						"region_name": "Al-Massoudieh - المسعودية"
					},
					{
						"id": 1964,
						"region_name": "Amaret el Baykat - عمار البيكات"
					},
					{
						"id": 1965,
						"region_name": "Amayer - العماير"
					},
					{
						"id": 1966,
						"region_name": "Andqat - عندقت"
					},
					{
						"id": 1967,
						"region_name": "Ard es Soud - ارض السود"
					},
					{
						"id": 1968,
						"region_name": "Awade - العواده"
					},
					{
						"id": 1969,
						"region_name": "Baaliye - بعليا"
					},
					{
						"id": 1970,
						"region_name": "Baddouaa - بدويا"
					},
					{
						"id": 1971,
						"region_name": "Bajaa - بجعة"
					},
					{
						"id": 1972,
						"region_name": "Balde - بلدي"
					},
					{
						"id": 1973,
						"region_name": "Bani Sakher - بني صخر"
					},
					{
						"id": 1974,
						"region_name": "Barcha - برشا"
					},
					{
						"id": 1975,
						"region_name": "Bebnine - ببنين"
					},
					{
						"id": 1976,
						"region_name": "Beino - بينو"
					},
					{
						"id": 1977,
						"region_name": "Beit Ali Adraa - بيت علي عدرة"
					},
					{
						"id": 1978,
						"region_name": "Beit Ayoub - بيت ايوب"
					},
					{
						"id": 1979,
						"region_name": "Beit Daoud - بيت داوود"
					},
					{
						"id": 1980,
						"region_name": "Beit el Haj - بيت الحاج"
					},
					{
						"id": 1981,
						"region_name": "Beit el Haouch - بيت الحوش"
					},
					{
						"id": 1982,
						"region_name": "Beit Ghattas - بيت غطاس"
					},
					{
						"id": 1983,
						"region_name": "Beit Khlaiyel - بيت خلايل"
					},
					{
						"id": 1984,
						"region_name": "Beit Mellat - بيت ملات"
					},
					{
						"id": 1985,
						"region_name": "Beit Younes - بيت يونس"
					},
					{
						"id": 1986,
						"region_name": "Bellanet el Hissa - بلانة الحيصة"
					},
					{
						"id": 1987,
						"region_name": "Berbara - برباره"
					},
					{
						"id": 1988,
						"region_name": "Berqayel - برقايل"
					},
					{
						"id": 1989,
						"region_name": "Bezbina - بزبينا"
					},
					{
						"id": 1990,
						"region_name": "Bire Akkar - البيره عكار"
					},
					{
						"id": 1991,
						"region_name": "Borj el Arab - برج العرب"
					},
					{
						"id": 1992,
						"region_name": "Boustane El Herch - بستان الحرش"
					},
					{
						"id": 1993,
						"region_name": "Bqayaa - البقيعه"
					},
					{
						"id": 1994,
						"region_name": "Bqerzala - بقرزلا"
					},
					{
						"id": 1995,
						"region_name": "Bzaita - بزيتا"
					},
					{
						"id": 1996,
						"region_name": "Bzal - بزال"
					},
					{
						"id": 1997,
						"region_name": "Chadra - شدره"
					},
					{
						"id": 1998,
						"region_name": "Chane - شان"
					},
					{
						"id": 1999,
						"region_name": "Chaqdouf - الشقدوف"
					},
					{
						"id": 2000,
						"region_name": "Charbila - شربيلا"
					},
					{
						"id": 2001,
						"region_name": "Chattaha - شطاحة"
					},
					{
						"id": 2002,
						"region_name": "Cheik Mohammad - الشيخ محمد"
					},
					{
						"id": 2003,
						"region_name": "Cheikh Aayash - الشيخ عياش"
					},
					{
						"id": 2004,
						"region_name": "Cheikh Hmairine - شيخ حمارين"
					},
					{
						"id": 2005,
						"region_name": "Cheikh Taba  - الشيخ طابا"
					},
					{
						"id": 2006,
						"region_name": "Cheikh Taba es Sahl - الشيخ طابا السهل"
					},
					{
						"id": 2007,
						"region_name": "Cheikh Zennad Talbibe - الشيخ زناد"
					},
					{
						"id": 2008,
						"region_name": "Cheikhlar - شيخلار"
					},
					{
						"id": 2009,
						"region_name": "Chir Hmairine - شير حمارين"
					},
					{
						"id": 2010,
						"region_name": "Dabadeb - داباديب"
					},
					{
						"id": 2011,
						"region_name": "Daghle - الدغلة"
					},
					{
						"id": 2012,
						"region_name": "Dahr Aayas - ضهر عياس"
					},
					{
						"id": 2013,
						"region_name": "Dahr El Ballane - ضهر البلانه"
					},
					{
						"id": 2014,
						"region_name": "Dahr el Houssain - ضهر الحسين"
					},
					{
						"id": 2015,
						"region_name": "Dahr Laissineh - ضهر ليسينه"
					},
					{
						"id": 2016,
						"region_name": "Danke et El Amriyeh - دنكة و العامرية"
					},
					{
						"id": 2017,
						"region_name": "Daousse Baghdadi - دوسة بغدادي"
					},
					{
						"id": 2018,
						"region_name": "Darine - دارين"
					},
					{
						"id": 2019,
						"region_name": "Dayret Nahr el Kabir - دائرة النهر الكبير"
					},
					{
						"id": 2020,
						"region_name": "Deir Dalloum - دير دلوم"
					},
					{
						"id": 2021,
						"region_name": "Deir Janine - دير جنين"
					},
					{
						"id": 2022,
						"region_name": "Deir Mar Jeryos - دير مار جريس"
					},
					{
						"id": 2023,
						"region_name": "Denke - دنكي"
					},
					{
						"id": 2024,
						"region_name": "Dibbabiye - دبابية"
					},
					{
						"id": 2025,
						"region_name": "Dinbou Ain al zahab - دنبو عين الذهب"
					},
					{
						"id": 2026,
						"region_name": "Doueir Aadouiye - دوير عدويه"
					},
					{
						"id": 2027,
						"region_name": "Ech Cheikh Maarouf - الشيخ معروف"
					},
					{
						"id": 2028,
						"region_name": "Ed Daoura - الدوره"
					},
					{
						"id": 2029,
						"region_name": "Ed Daousse - الدوسة"
					},
					{
						"id": 2030,
						"region_name": "Ed Debbabiye el Charqiye - الدبابية الشرقية"
					},
					{
						"id": 2031,
						"region_name": "Ed Debbabiye el Gharbiye - الدبابية الغربية"
					},
					{
						"id": 2032,
						"region_name": "El Aabboudiye - العبودية"
					},
					{
						"id": 2033,
						"region_name": "El Aamriye - العمارية"
					},
					{
						"id": 2034,
						"region_name": "El Aaouaichat - العويشات"
					},
					{
						"id": 2035,
						"region_name": "El Aarida - العريضة"
					},
					{
						"id": 2036,
						"region_name": "El Aayoun - العيون"
					},
					{
						"id": 2037,
						"region_name": "El Barde - بردي"
					},
					{
						"id": 2038,
						"region_name": "El Borj Akkar - البرج عكار"
					},
					{
						"id": 2039,
						"region_name": "El Fraidis - فريديس"
					},
					{
						"id": 2040,
						"region_name": "El Ghawaya - القبيات غوايا"
					},
					{
						"id": 2041,
						"region_name": "El Haissa - الحيصة"
					},
					{
						"id": 2042,
						"region_name": "El Hichi - الهيشه"
					},
					{
						"id": 2043,
						"region_name": "El Houaich - الحويش"
					},
					{
						"id": 2044,
						"region_name": "El Kharnoubeh - الخرنوبه"
					},
					{
						"id": 2045,
						"region_name": "El Khirbe - الخربة "
					},
					{
						"id": 2046,
						"region_name": "El Khirbe Msalla - الخربة مصلا"
					},
					{
						"id": 2047,
						"region_name": "EL Khirbe Qleiaat - الخربة قليعات"
					},
					{
						"id": 2048,
						"region_name": "El Khoder - الخضر اكروم"
					},
					{
						"id": 2049,
						"region_name": "El Kneisse - الكنيسة"
					},
					{
						"id": 2050,
						"region_name": "El Kouraniye - الكورانية"
					},
					{
						"id": 2051,
						"region_name": "El Krahne - الكراهنة"
					},
					{
						"id": 2052,
						"region_name": "El Kroum - الكروم"
					},
					{
						"id": 2053,
						"region_name": "El Majdal - المجدل العماير"
					},
					{
						"id": 2054,
						"region_name": "El Majdel - المجدل عكار"
					},
					{
						"id": 2055,
						"region_name": "El Mbar kiye - المباركية"
					},
					{
						"id": 2056,
						"region_name": "El Melkiye - الملكي"
					},
					{
						"id": 2057,
						"region_name": "El Mqaiteaa - المقيطع"
					},
					{
						"id": 2058,
						"region_name": "El Qantara - القنطرة"
					},
					{
						"id": 2059,
						"region_name": "El Qatlabe - القبيات القطلبه"
					},
					{
						"id": 2060,
						"region_name": "El Qlaiaat - القليعات"
					},
					{
						"id": 2061,
						"region_name": "El Qorne - القرنة"
					},
					{
						"id": 2062,
						"region_name": "El Qsair - القصير"
					},
					{
						"id": 2063,
						"region_name": "El Rama - الرامه"
					},
					{
						"id": 2064,
						"region_name": "En Nabi Khaled - النبي خالد"
					},
					{
						"id": 2065,
						"region_name": "Er Ransiye - الرانسية"
					},
					{
						"id": 2066,
						"region_name": "Er Rouaime - الرويمة"
					},
					{
						"id": 2067,
						"region_name": "Es Sayeh - السايح"
					},
					{
						"id": 2068,
						"region_name": "Es Souaisse - السويسة"
					},
					{
						"id": 2069,
						"region_name": "Ez Zouq - الذوق"
					},
					{
						"id": 2070,
						"region_name": "Fard - الفرض"
					},
					{
						"id": 2071,
						"region_name": "Fnaideq - فنيدق"
					},
					{
						"id": 2072,
						"region_name": "Fsiqine et Ain Echma - فسقين و عين اشما"
					},
					{
						"id": 2073,
						"region_name": "Ghzaile - الغزيله"
					},
					{
						"id": 2074,
						"region_name": "Habchit - حبشيت"
					},
					{
						"id": 2075,
						"region_name": "Haider - حيدر"
					},
					{
						"id": 2076,
						"region_name": "Haitla - هيتلا"
					},
					{
						"id": 2077,
						"region_name": "Haizouq - حيزوق"
					},
					{
						"id": 2078,
						"region_name": "Halba - حلبا"
					},
					{
						"id": 2079,
						"region_name": "Haouch - حوش"
					},
					{
						"id": 2080,
						"region_name": "Haouchab - هوشب"
					},
					{
						"id": 2081,
						"region_name": "Haret Beit Kessab - حارة بيت كساب"
					},
					{
						"id": 2082,
						"region_name": "Haret ej Jdide - حارة الجديدة"
					},
					{
						"id": 2083,
						"region_name": "Haret ej Jdide Mqaiteaa - حارة الجديدة المقيطع"
					},
					{
						"id": 2084,
						"region_name": "Hedd - الهد"
					},
					{
						"id": 2085,
						"region_name": "Hikr Janine - حكر جنين"
					},
					{
						"id": 2086,
						"region_name": "Hissa - الحيصا"
					},
					{
						"id": 2087,
						"region_name": "Hmaireh Aakkar - الحميرة"
					},
					{
						"id": 2088,
						"region_name": "Hmais - حميص"
					},
					{
						"id": 2089,
						"region_name": "Hnaider - حنيدر"
					},
					{
						"id": 2090,
						"region_name": "Hokr ech Cheikh Taba - حكر الشيخ طابا"
					},
					{
						"id": 2091,
						"region_name": "Hokr ed Dahri - حكر الضاهري"
					},
					{
						"id": 2092,
						"region_name": "Hokr el Kousse - حكر الكوسا"
					},
					{
						"id": 2093,
						"region_name": "Hokr El Mahmoudiye - حكر المحمودية"
					},
					{
						"id": 2094,
						"region_name": "Hokr Etti - حكر قتة"
					},
					{
						"id": 2095,
						"region_name": "Hokr Jouret Srar - حكر جورة سرار"
					},
					{
						"id": 2096,
						"region_name": "Hrar - حرار"
					},
					{
						"id": 2097,
						"region_name": "idbil - عدبل"
					},
					{
						"id": 2098,
						"region_name": "Ilat - ايلات"
					},
					{
						"id": 2099,
						"region_name": "Janine - جنين"
					},
					{
						"id": 2100,
						"region_name": "Jdaidet Ej Joumeh - جديدة الجومه"
					},
					{
						"id": 2101,
						"region_name": "Jdeide - جديدة"
					},
					{
						"id": 2102,
						"region_name": "Jdeidet El Qaitaa - جديدة القيطع"
					},
					{
						"id": 2103,
						"region_name": "Jebrayel - جبرائيل"
					},
					{
						"id": 2104,
						"region_name": "Jichet Aali Houssein - جيشة علي حسين"
					},
					{
						"id": 2105,
						"region_name": "Jouret Srar - جورة سرار"
					},
					{
						"id": 2106,
						"region_name": "Kafr El Ftouh - كفر الفتوح"
					},
					{
						"id": 2107,
						"region_name": "Kafroun - كفرون"
					},
					{
						"id": 2108,
						"region_name": "Kalkha - كلخا"
					},
					{
						"id": 2109,
						"region_name": "Karm el Aasfour - كرم عصفور"
					},
					{
						"id": 2110,
						"region_name": "Karm Zebdine - كرم زبدين"
					},
					{
						"id": 2111,
						"region_name": "Kawashra - الكواشرة"
					},
					{
						"id": 2112,
						"region_name": "Kfar Harra - كفرحره"
					},
					{
						"id": 2113,
						"region_name": "Kfar Melki - كفرملكي"
					},
					{
						"id": 2114,
						"region_name": "Kfar Noun - كفرنون"
					},
					{
						"id": 2115,
						"region_name": "Kfartoun - كفرتون"
					},
					{
						"id": 2116,
						"region_name": "Khalsa - خالصة"
					},
					{
						"id": 2117,
						"region_name": "Khane Hayat - خان الحيات"
					},
					{
						"id": 2118,
						"region_name": "Khat Petrol - خط البترول"
					},
					{
						"id": 2119,
						"region_name": "Khirbet Char - خربة شار"
					},
					{
						"id": 2120,
						"region_name": "Khirbet Daoud - خربة داوود"
					},
					{
						"id": 2121,
						"region_name": "Khirbet er Roummane - خربة الرمان"
					},
					{
						"id": 2122,
						"region_name": "Khirbit Ej Jord - خربة الجرد"
					},
					{
						"id": 2123,
						"region_name": "Khorab el Haiyat - خرب الحيات"
					},
					{
						"id": 2124,
						"region_name": "Khraibe Akkar - الخريبة"
					},
					{
						"id": 2125,
						"region_name": "Khreibet ej Jindi - خريبة الجندي"
					},
					{
						"id": 2126,
						"region_name": "Knaisse Hnaider - كنيسة هنيدر"
					},
					{
						"id": 2127,
						"region_name": "Knisseh - كنيسة"
					},
					{
						"id": 2128,
						"region_name": "Kouikhat - الكويخات"
					},
					{
						"id": 2129,
						"region_name": "Kousha - كوشا"
					},
					{
						"id": 2130,
						"region_name": "Kroum el Arab - كروم العرب"
					},
					{
						"id": 2131,
						"region_name": "Machha - مشحة"
					},
					{
						"id": 2132,
						"region_name": "Machta Hammoud - مشتى حمود"
					},
					{
						"id": 2133,
						"region_name": "Machta Hassan - مشتى حسن"
					},
					{
						"id": 2134,
						"region_name": "Mahmoudiye - المحمودية"
					},
					{
						"id": 2135,
						"region_name": "Majdla - مجدلا"
					},
					{
						"id": 2136,
						"region_name": "Mar Lia Hdare - مار ليا حدار"
					},
					{
						"id": 2137,
						"region_name": "Mar Sahllita - مار شليطا"
					},
					{
						"id": 2138,
						"region_name": "Mar Touma - مار توما"
					},
					{
						"id": 2139,
						"region_name": "Marlayet Haddara - مارليات حدارا"
					},
					{
						"id": 2140,
						"region_name": "Marlayet Melhem - مار ليا ملحم"
					},
					{
						"id": 2141,
						"region_name": "Martmoura - القبيات مرتموره"
					},
					{
						"id": 2142,
						"region_name": "Mazraat Al Balde - مزرعة بلدة"
					},
					{
						"id": 2143,
						"region_name": "Mechaeilha Hakour - مشيلحة الحاكور"
					},
					{
						"id": 2144,
						"region_name": "Mechmech - مشمش"
					},
					{
						"id": 2145,
						"region_name": "Meghraq - المغراق"
					},
					{
						"id": 2146,
						"region_name": "Meghraqa - مغراقا"
					},
					{
						"id": 2147,
						"region_name": "Memnaa - ممنع"
					},
					{
						"id": 2148,
						"region_name": "Mhamra - المحمرة"
					},
					{
						"id": 2149,
						"region_name": "Mhatat Siket el Hadid - محطة سكة الحديد"
					},
					{
						"id": 2150,
						"region_name": "Minyara - منيارة"
					},
					{
						"id": 2151,
						"region_name": "Mouanse - المونسه"
					},
					{
						"id": 2152,
						"region_name": "Mounjez - منجز"
					},
					{
						"id": 2153,
						"region_name": "Mqaible - المقيبلة"
					},
					{
						"id": 2154,
						"region_name": "Mqaitaa - مقيطع السماكلي"
					},
					{
						"id": 2155,
						"region_name": "Mrah Aakkar - مراه عكار"
					},
					{
						"id": 2156,
						"region_name": "Mrah Al Khawkh - مراح الخوخ"
					},
					{
						"id": 2157,
						"region_name": "Mrah el Aainouni - مراه العينوني"
					},
					{
						"id": 2158,
						"region_name": "Mrah el Bsatine - مراح البساتين"
					},
					{
						"id": 2159,
						"region_name": "Mrah Qamar ed Dine - مراح قمر الدين"
					},
					{
						"id": 2160,
						"region_name": "Msalla - مصلا"
					},
					{
						"id": 2161,
						"region_name": "Mzeihmeh - مزيحمة"
					},
					{
						"id": 2162,
						"region_name": "Nabaa el Ghzaile - نبع الغزيلة"
					},
					{
						"id": 2163,
						"region_name": "Nahr El Bared - نهر البارد"
					},
					{
						"id": 2164,
						"region_name": "Nahriye - النهريه "
					},
					{
						"id": 2165,
						"region_name": "Nassriye - ناصرية"
					},
					{
						"id": 2166,
						"region_name": "Nfaisseh - النفيسة"
					},
					{
						"id": 2167,
						"region_name": "Noura - النوره"
					},
					{
						"id": 2168,
						"region_name": "Noura el Faouqa et el Tahta - نورا الفوقا و التحتى"
					},
					{
						"id": 2169,
						"region_name": "Noura el Tahta - نورا التحتا"
					},
					{
						"id": 2170,
						"region_name": "Ouadi Ej jamous - وادي الجاموس"
					},
					{
						"id": 2171,
						"region_name": "Qaabrine - قعبرين"
					},
					{
						"id": 2172,
						"region_name": "Qabaait - قبعيت"
					},
					{
						"id": 2173,
						"region_name": "Qanbar - القنبر"
					},
					{
						"id": 2174,
						"region_name": "Qarha Akkar - قرحه عكار"
					},
					{
						"id": 2175,
						"region_name": "Qarqaf - القرقف"
					},
					{
						"id": 2176,
						"region_name": "Qbaiyat el Gharbiye - القبيات الغربية"
					},
					{
						"id": 2177,
						"region_name": "Qbaiyat ez Zouq - القبيات الذوق"
					},
					{
						"id": 2178,
						"region_name": "Qboula - قبولا"
					},
					{
						"id": 2179,
						"region_name": "Qbour El Bid - قبور البيد"
					},
					{
						"id": 2180,
						"region_name": "Qinye - قنية"
					},
					{
						"id": 2181,
						"region_name": "Qloud El-Baqieh - قلود الباقية"
					},
					{
						"id": 2182,
						"region_name": "Qochloq - قشلق"
					},
					{
						"id": 2183,
						"region_name": "Qoubaiyat - القبيات"
					},
					{
						"id": 2184,
						"region_name": "Qoubbet Chamra - قبة شمرا"
					},
					{
						"id": 2185,
						"region_name": "Qraiyat - القريات"
					},
					{
						"id": 2186,
						"region_name": "Rahbe - رحبة"
					},
					{
						"id": 2187,
						"region_name": "Rajm Hssein - رجم حسين"
					},
					{
						"id": 2188,
						"region_name": "Rajm Issa - رجم عيسى"
					},
					{
						"id": 2189,
						"region_name": "Rajm Khalaf - رجم خلف"
					},
					{
						"id": 2190,
						"region_name": "Rihaniye - الريحانية"
					},
					{
						"id": 2191,
						"region_name": "Rmah En Nahriyeh - رماح النهريه"
					},
					{
						"id": 2192,
						"region_name": "Rmoul - رمول"
					},
					{
						"id": 2193,
						"region_name": "Saadine - سعدين"
					},
					{
						"id": 2194,
						"region_name": "Sadaqa - صدقة"
					},
					{
						"id": 2195,
						"region_name": "Sahle - السهله"
					},
					{
						"id": 2196,
						"region_name": "Saidnaya - السنديانة"
					},
					{
						"id": 2197,
						"region_name": "Saissouq - سيسوق"
					},
					{
						"id": 2198,
						"region_name": "Sammouniye - السمونيه"
					},
					{
						"id": 2199,
						"region_name": "Saoualha - ساولها"
					},
					{
						"id": 2200,
						"region_name": "Sbagha - صباغا"
					},
					{
						"id": 2201,
						"region_name": "Semmaqiye - السماقية"
					},
					{
						"id": 2202,
						"region_name": "Semmaqli - سمقالي"
					},
					{
						"id": 2203,
						"region_name": "Sfaynet El-Qaitaa - سفينة القيطع"
					},
					{
						"id": 2204,
						"region_name": "Sfinet ed Dreib - سفينة الدريب"
					},
					{
						"id": 2205,
						"region_name": "Shaqdouf Aakkar - شقدوف عكار"
					},
					{
						"id": 2206,
						"region_name": "Sindianet Zeidane - سنديانة زيدان"
					},
					{
						"id": 2207,
						"region_name": "Srar - سرار"
					},
					{
						"id": 2208,
						"region_name": "Takrit - تكريت"
					},
					{
						"id": 2209,
						"region_name": "Tal Meaayan - تلمعيان"
					},
					{
						"id": 2210,
						"region_name": "Tall Aabbas Ech-Charqi - تل عباس الشرقي"
					},
					{
						"id": 2211,
						"region_name": "Tall Aabbas el Gharbi - تلعباس غربي"
					},
					{
						"id": 2212,
						"region_name": "Tall Bire - تلبيرة"
					},
					{
						"id": 2213,
						"region_name": "Tall Hmayra - تلحميرة"
					},
					{
						"id": 2214,
						"region_name": "Tallet el Mjabber - تلة المجابر"
					},
					{
						"id": 2215,
						"region_name": "Tallet ez Zefir - تلة الزفير"
					},
					{
						"id": 2216,
						"region_name": "Tashea - تاشع"
					},
					{
						"id": 2217,
						"region_name": "Tell Bibi - تلبيبة"
					},
					{
						"id": 2218,
						"region_name": "Tell Hayat - تل الحيات"
					},
					{
						"id": 2219,
						"region_name": "Tell Kindi - تل كندي"
					},
					{
						"id": 2220,
						"region_name": "Tell Kiri - تل كيري"
					},
					{
						"id": 2221,
						"region_name": "Tell Sebael - تل سبعل"
					},
					{
						"id": 2222,
						"region_name": "Tleil - التليل"
					},
					{
						"id": 2223,
						"region_name": "Wadi Al Hoor - وادي الحور"
					},
					{
						"id": 2224,
						"region_name": "Wadi Khaled - وادي خالد"
					},
					{
						"id": 2225,
						"region_name": "Zouaitini Akkar - الزويتينة عكار"
					},
					{
						"id": 2226,
						"region_name": "Zouarib - الزواريب"
					},
					{
						"id": 2227,
						"region_name": "Zouk Haddara - ذوق حدارة"
					},
					{
						"id": 2228,
						"region_name": "Zouq el Hassineh - ذوق الحصينة"
					},
					{
						"id": 2229,
						"region_name": "Zouq El Mqachrine - ذوق المقشرين"
					},
					{
						"id": 2230,
						"region_name": "Zouq El-Hbalsa - ذوق الحبالصة"
					}
				]
			}
		]
	},
	{
		"id": 2,
		"GovernorateDescription": "Baalbeck-Hermel - بعلبك - الهرمل",
		"Districts": [
			{
				"id": 2,
				"DistrictDescription": "Baalbek - بعلبك",
				"Cities": [
					{
						"id": 2231,
						"region_name": "Aadous - عدّوس"
					},
					{
						"id": 2232,
						"region_name": "Aallaq - علاق"
					},
					{
						"id": 2233,
						"region_name": "Aamchki - عمشكي"
					},
					{
						"id": 2234,
						"region_name": "Aarsal - عرسال"
					},
					{
						"id": 2235,
						"region_name": "Aayoun Orghoush - عيون ارغش"
					},
					{
						"id": 2236,
						"region_name": "Ain Bourday - عين بورداي"
					},
					{
						"id": 2237,
						"region_name": "Ain Ej Jaouze - عين الجوزة"
					},
					{
						"id": 2238,
						"region_name": "Ain El Bnaiye - عين البنية"
					},
					{
						"id": 2239,
						"region_name": "Ain es Saouda - عين السودا"
					},
					{
						"id": 2240,
						"region_name": "Ainata - عيناتا"
					},
					{
						"id": 2241,
						"region_name": "Al Ansar Baalbek - الأنصار بعلبك"
					},
					{
						"id": 2242,
						"region_name": "Baalbek - بعلبك"
					},
					{
						"id": 2243,
						"region_name": "Baayoun - قاع بعيون"
					},
					{
						"id": 2244,
						"region_name": "Barqa - برقا"
					},
					{
						"id": 2245,
						"region_name": "Bechouat - بشوات"
					},
					{
						"id": 2246,
						"region_name": "Bednayel - بدنايل"
					},
					{
						"id": 2247,
						"region_name": "Beit Chama - بيت شاما"
					},
					{
						"id": 2248,
						"region_name": "Beit es Satiti - بيت الستيته"
					},
					{
						"id": 2249,
						"region_name": "Beit Habchi - بيت حبشي"
					},
					{
						"id": 2250,
						"region_name": "Beit Mchik - مزرعة بيت مشيك"
					},
					{
						"id": 2251,
						"region_name": "Bejjaje - بجاجة"
					},
					{
						"id": 2252,
						"region_name": "Betdaai - بتدعي"
					},
					{
						"id": 2253,
						"region_name": "Blaiqa - بليقة"
					},
					{
						"id": 2254,
						"region_name": "Boudai - بوداي"
					},
					{
						"id": 2255,
						"region_name": "Britel - بريتال"
					},
					{
						"id": 2256,
						"region_name": "Bsayleh al Fawka - بصيلة الفوقا"
					},
					{
						"id": 2257,
						"region_name": "Bsayleh al Tahta - بصيلة التحتا"
					},
					{
						"id": 2258,
						"region_name": "Chaat - شعت"
					},
					{
						"id": 2259,
						"region_name": "Chlifa - شليفا"
					},
					{
						"id": 2260,
						"region_name": "Chmistar - شمسطار"
					},
					{
						"id": 2261,
						"region_name": "Daouret en Naml - دورة النمل"
					},
					{
						"id": 2262,
						"region_name": "Dar el Ouassaa - دار الواسعة"
					},
					{
						"id": 2263,
						"region_name": "Deir El Ahmar - دير الاحمر"
					},
					{
						"id": 2264,
						"region_name": "Deir Mar Maroun Baalbek - دير مار مارون بعلبك"
					},
					{
						"id": 2265,
						"region_name": "Douris - دوريس"
					},
					{
						"id": 2266,
						"region_name": "El Aaiara - عيارة"
					},
					{
						"id": 2267,
						"region_name": "El Ain - العين"
					},
					{
						"id": 2268,
						"region_name": "El Barake - البركة"
					},
					{
						"id": 2269,
						"region_name": "El Faqrat - فقرات"
					},
					{
						"id": 2270,
						"region_name": "El Laouze - اللوزة"
					},
					{
						"id": 2271,
						"region_name": "El Maalqa - معلقة الجديدة"
					},
					{
						"id": 2272,
						"region_name": "El Marmagha - مرماغا"
					},
					{
						"id": 2273,
						"region_name": "El Qaa - القاع"
					},
					{
						"id": 2274,
						"region_name": "El Qerrami - قرامي"
					},
					{
						"id": 2275,
						"region_name": "En Nouqra - نقرة"
					},
					{
						"id": 2276,
						"region_name": "Fakehe - فاكهة"
					},
					{
						"id": 2277,
						"region_name": "Flaoui - فلاوي"
					},
					{
						"id": 2278,
						"region_name": "Freij - فريج"
					},
					{
						"id": 2279,
						"region_name": "Hadet Hermel - حدت الهرمل"
					},
					{
						"id": 2280,
						"region_name": "Hai el Mathane - حي المطحنة"
					},
					{
						"id": 2281,
						"region_name": "Halbata - حلباتا"
					},
					{
						"id": 2282,
						"region_name": "Ham - حام"
					},
					{
						"id": 2283,
						"region_name": "Haouch Barada - حوش بردى"
					},
					{
						"id": 2284,
						"region_name": "Haouch Ed Dahab - حوش الدهب"
					},
					{
						"id": 2285,
						"region_name": "Haouch En Nabi - حوش النبي"
					},
					{
						"id": 2286,
						"region_name": "Haouch Snaid - حوش سنيد"
					},
					{
						"id": 2287,
						"region_name": "Haouch Tall Safiye - حوش تل صفية"
					},
					{
						"id": 2288,
						"region_name": "Haouerte - حوارته"
					},
					{
						"id": 2289,
						"region_name": "Haour Taala - حور تعلا"
					},
					{
						"id": 2290,
						"region_name": "Harabta - حربتا"
					},
					{
						"id": 2291,
						"region_name": "Harfouch - حرفوش"
					},
					{
						"id": 2292,
						"region_name": "Hfayer - حفاير"
					},
					{
						"id": 2293,
						"region_name": "Hizzine - حزين"
					},
					{
						"id": 2294,
						"region_name": "Hosn ech Chadoura - حصن الشادورا "
					},
					{
						"id": 2295,
						"region_name": "Houch Er Rafqa - حوش الرافقة"
					},
					{
						"id": 2296,
						"region_name": "Iaat - ايعات"
					},
					{
						"id": 2297,
						"region_name": "Jabaa - جبعا"
					},
					{
						"id": 2298,
						"region_name": "Jabal Ech Chaaibe - جبل الشعيبة"
					},
					{
						"id": 2299,
						"region_name": "Jabboule - جبولة"
					},
					{
						"id": 2300,
						"region_name": "Janta - جنتا"
					},
					{
						"id": 2301,
						"region_name": "Jdaide Fekehe - جديدة الفاكهة"
					},
					{
						"id": 2302,
						"region_name": "Joubaniyeh - جوبانية"
					},
					{
						"id": 2303,
						"region_name": "Kfar Dabach - كفر دبش"
					},
					{
						"id": 2304,
						"region_name": "Kfar Dane - كفر دان"
					},
					{
						"id": 2305,
						"region_name": "Kharayeb - الخرائب"
					},
					{
						"id": 2306,
						"region_name": "Khermateh - خورماتا"
					},
					{
						"id": 2307,
						"region_name": "Khirbet Daoud Baalbek - خربة داوود بعلبك"
					},
					{
						"id": 2308,
						"region_name": "Khirbet et Tine - خربة التينه"
					},
					{
						"id": 2309,
						"region_name": "Khirbet Haouerte - خربة حوارته"
					},
					{
						"id": 2310,
						"region_name": "Khirbet Younine - خربة يونين"
					},
					{
						"id": 2311,
						"region_name": "Khodor - خضر "
					},
					{
						"id": 2312,
						"region_name": "Khraibe - خريبة "
					},
					{
						"id": 2313,
						"region_name": "Knaisse - كنيسة "
					},
					{
						"id": 2314,
						"region_name": "Laboue - لبوة"
					},
					{
						"id": 2315,
						"region_name": "Maarboun - معربون"
					},
					{
						"id": 2316,
						"region_name": "Mahatta - المحطة"
					},
					{
						"id": 2317,
						"region_name": "Majdaloun - مجدلون"
					},
					{
						"id": 2318,
						"region_name": "Maql el Bouadte - مقل البوادتي"
					},
					{
						"id": 2319,
						"region_name": "Maqne - مقنة"
					},
					{
						"id": 2320,
						"region_name": "Masnaa - المصنع"
					},
					{
						"id": 2321,
						"region_name": "Masnaa es Zohr - مصنع الزهر"
					},
					{
						"id": 2322,
						"region_name": "Mathanet Mousrayeh - مطحنة مصراية"
					},
					{
						"id": 2323,
						"region_name": "Mazraat al Ramassy - مزرعة الرماسا"
					},
					{
						"id": 2324,
						"region_name": "Mazraat Al Souaydane - مزرعة آل سويدان "
					},
					{
						"id": 2325,
						"region_name": "Mazraat al Takch - مزرعة بيت طقش"
					},
					{
						"id": 2326,
						"region_name": "Mazraat Beit el Ghoussain - مزرعة بيت الغصين"
					},
					{
						"id": 2327,
						"region_name": "Mazraat Beit Slaibi - مزرعة بيت صليبي"
					},
					{
						"id": 2328,
						"region_name": "Mazraat Ed Dallil - مزرعة الضليل"
					},
					{
						"id": 2329,
						"region_name": "Mazraat ed Dhour - مزرعة الضهور"
					},
					{
						"id": 2330,
						"region_name": "Mazraat Es Saiyed - مزرعة السيد"
					},
					{
						"id": 2331,
						"region_name": "Mazraat Matar - مزرعة مطر"
					},
					{
						"id": 2332,
						"region_name": "Mazraat Oumm Aali - مزرعة ام علي"
					},
					{
						"id": 2333,
						"region_name": "Mchaitiye - مشيتية"
					},
					{
						"id": 2334,
						"region_name": "Mehairfe - محيرفة"
					},
					{
						"id": 2335,
						"region_name": "Mhattat Ras Baalbeck - المحطة راس بعلبك"
					},
					{
						"id": 2336,
						"region_name": "Mkaybel Al Kala' - مقيال القلعة"
					},
					{
						"id": 2337,
						"region_name": "Moqraq - المقرق"
					},
					{
						"id": 2338,
						"region_name": "Mrah Beit Aassaf - مراح بيت عساف"
					},
					{
						"id": 2339,
						"region_name": "Mrah Beit el Qazah - مراح بيت القزح"
					},
					{
						"id": 2340,
						"region_name": "Mrah Beit Slim - مراح بيت سليم"
					},
					{
						"id": 2341,
						"region_name": "Mrah Bou Brahim - مراح بو براهيم"
					},
					{
						"id": 2342,
						"region_name": "Mrah Bou Chahine - مراح بو شاهين"
					},
					{
						"id": 2343,
						"region_name": "Mrah ech Chmis - مراح الشميس"
					},
					{
						"id": 2344,
						"region_name": "Mrah ech Choaab - مراح الشعاب"
					},
					{
						"id": 2345,
						"region_name": "Mrah ej Jamal - مراح "
					},
					{
						"id": 2346,
						"region_name": "Mrah ej Jeddaoui - مراح الجداوي"
					},
					{
						"id": 2347,
						"region_name": "Mrah El Aabed - مراه العبد"
					},
					{
						"id": 2348,
						"region_name": "Mrah El Aaouja - مراح العوجا "
					},
					{
						"id": 2349,
						"region_name": "Mrah El Aassi - مراح العاصي"
					},
					{
						"id": 2350,
						"region_name": "Mrah el Ahmar - مراح الاحمر"
					},
					{
						"id": 2351,
						"region_name": "Mrah el Blata - مراح البلاطة"
					},
					{
						"id": 2352,
						"region_name": "Mrah EL Harfouch - مراح الحرفوش"
					},
					{
						"id": 2353,
						"region_name": "Mrah es Sirghane - مراح السرغانه"
					},
					{
						"id": 2354,
						"region_name": "Mrah Haissoun - مراح حيصون"
					},
					{
						"id": 2355,
						"region_name": "Mrah Kloude - مراح القلود"
					},
					{
						"id": 2356,
						"region_name": "Mrah Najib - مراح نجيب"
					},
					{
						"id": 2357,
						"region_name": "Mrah Rafi - مراح رافي"
					},
					{
						"id": 2358,
						"region_name": "Mrah Semaan - مراح سمعان"
					},
					{
						"id": 2359,
						"region_name": "Mrah Soukkar - مراح سكر"
					},
					{
						"id": 2360,
						"region_name": "Nabha - نبحا"
					},
					{
						"id": 2361,
						"region_name": "Nabha Al Mohfara - نبحا المحفارة"
					},
					{
						"id": 2362,
						"region_name": "Nabi Chit - نبي شيت"
					},
					{
						"id": 2363,
						"region_name": "Nabi Osmane - نبي عثمان"
					},
					{
						"id": 2364,
						"region_name": "Nabi Rachade - نبي رشاده"
					},
					{
						"id": 2365,
						"region_name": "Nabi Sbat - النبي سباط"
					},
					{
						"id": 2366,
						"region_name": "Nahle - نحله "
					},
					{
						"id": 2367,
						"region_name": "Qaa Jouar Maqiyeh - قاع جوار مكية"
					},
					{
						"id": 2368,
						"region_name": "Qaa Ouadi el Khanzir - قاع وادي الخنزير"
					},
					{
						"id": 2369,
						"region_name": "Qalaat Bakdach - قلعة بكداش"
					},
					{
						"id": 2370,
						"region_name": "Qalb es Sabaa - مزرعة قلد السبع"
					},
					{
						"id": 2371,
						"region_name": "Qarha - قرحا"
					},
					{
						"id": 2372,
						"region_name": "Qiddam - القدام"
					},
					{
						"id": 2373,
						"region_name": "Qlaile - قليلة "
					},
					{
						"id": 2374,
						"region_name": "Qsarnaba - قصرنابا"
					},
					{
						"id": 2375,
						"region_name": "Ram - الرام"
					},
					{
						"id": 2376,
						"region_name": "Ras al Assy - راس العاصي "
					},
					{
						"id": 2377,
						"region_name": "Ras Baalbek - راس بعلبك"
					},
					{
						"id": 2378,
						"region_name": "Ras Baalbek ech Charqi - راس بعلبك الشرقي"
					},
					{
						"id": 2379,
						"region_name": "Ras Baalbek es Sahel - راس بعلبك السهل"
					},
					{
						"id": 2380,
						"region_name": "Rasm El Hadet - رسم الحدث"
					},
					{
						"id": 2381,
						"region_name": "Riha - ريحا"
					},
					{
						"id": 2382,
						"region_name": "Saaide - سعيدة"
					},
					{
						"id": 2383,
						"region_name": "Safra - صفرا"
					},
					{
						"id": 2384,
						"region_name": "Saidet ed Dalil - سيدة الضليل"
					},
					{
						"id": 2385,
						"region_name": "Saraain el Faouqa - سرعين الفوقا"
					},
					{
						"id": 2386,
						"region_name": "Saraain et Tahta - سرعين التحتا"
					},
					{
						"id": 2387,
						"region_name": "Sbouba - صبوبا"
					},
					{
						"id": 2388,
						"region_name": "Sifri - سفري"
					},
					{
						"id": 2389,
						"region_name": "Siret Hanna - سيرة هنا"
					},
					{
						"id": 2390,
						"region_name": "Slouqi - سلوقي"
					},
					{
						"id": 2391,
						"region_name": "Taibe Baalbek - طيبة بعلبك"
					},
					{
						"id": 2392,
						"region_name": "Talia - طليا"
					},
					{
						"id": 2393,
						"region_name": "Tall Sougha - تل سغى"
					},
					{
						"id": 2394,
						"region_name": "Tallet ed Deir - تلة الدير"
					},
					{
						"id": 2395,
						"region_name": "Tammine et Tahta - تمنين التحتا"
					},
					{
						"id": 2396,
						"region_name": "Tamnine El Faouqa - تمنين الفوقا"
					},
					{
						"id": 2397,
						"region_name": "Taraiya - طاريا"
					},
					{
						"id": 2398,
						"region_name": "Tfail - طفيل"
					},
					{
						"id": 2399,
						"region_name": "Toufiqiye - التوفيقية"
					},
					{
						"id": 2400,
						"region_name": "Wadi el Assouad - وادي الاسود"
					},
					{
						"id": 2401,
						"region_name": "Wadi el Oss - وادي القصص"
					},
					{
						"id": 2402,
						"region_name": "Yahfoufa - يحفوفا"
					},
					{
						"id": 2403,
						"region_name": "Yammoune - يمونة"
					},
					{
						"id": 2404,
						"region_name": "Younine - يونين"
					},
					{
						"id": 2405,
						"region_name": "Zabboud - زبود"
					},
					{
						"id": 2406,
						"region_name": "Zarayeb - زرايب"
					},
					{
						"id": 2407,
						"region_name": "Zarayeb Choukr - زرايب شكر"
					},
					{
						"id": 2408,
						"region_name": "Zeribet es Sabha - زريبة الصبحا"
					},
					{
						"id": 2409,
						"region_name": "Zrazir - زرازير"
					}
				]
			},
			{
				"id": 3,
				"DistrictDescription": "El Hermel -  الهرمل",
				"Cities": [
					{
						"id": 2410,
						"region_name": "Aaqabe - عقبة"
					},
					{
						"id": 2411,
						"region_name": "Ain al Jadideh - عين الجديدة الهرمل"
					},
					{
						"id": 2412,
						"region_name": "Ain el bayda - عين البيضاء"
					},
					{
						"id": 2413,
						"region_name": "Bdita - بديتا"
					},
					{
						"id": 2414,
						"region_name": "Beit Aallam - بيت علام"
					},
					{
						"id": 2415,
						"region_name": "Beit Aallaou - بيت علاّو"
					},
					{
						"id": 2416,
						"region_name": "Beit es Semmaqa - بيت السماقة"
					},
					{
						"id": 2417,
						"region_name": "Beit Hira - بيت حيرا"
					},
					{
						"id": 2418,
						"region_name": "Berghoch - برغش"
					},
					{
						"id": 2419,
						"region_name": "Biyout Aawad - بيوت عواد"
					},
					{
						"id": 2420,
						"region_name": "Biyout el Ain - بيوت العين"
					},
					{
						"id": 2421,
						"region_name": "Biyout el Hajj Hassan - بيوت الحاج حسن"
					},
					{
						"id": 2422,
						"region_name": "Biyout er Rouais - بيوت الرويس"
					},
					{
						"id": 2423,
						"region_name": "Biyout es Souh - بيوت السوح"
					},
					{
						"id": 2424,
						"region_name": "Bou Sawaya - بو صوايا"
					},
					{
						"id": 2425,
						"region_name": "Bouaida - بويضة "
					},
					{
						"id": 2426,
						"region_name": "Boustane - بستان "
					},
					{
						"id": 2427,
						"region_name": "Breij - البريج"
					},
					{
						"id": 2428,
						"region_name": "Brissa - بريصا"
					},
					{
						"id": 2429,
						"region_name": "Charbine - شربين"
					},
					{
						"id": 2430,
						"region_name": "Chouaghir - شواغير"
					},
					{
						"id": 2431,
						"region_name": "Ed Daoura el hermel - الدورة الهرمل"
					},
					{
						"id": 2432,
						"region_name": "El Baaoul - البعول"
					},
					{
						"id": 2433,
						"region_name": "El Mdawesh - مداوش"
					},
					{
						"id": 2434,
						"region_name": "El Ouaqf - الوقف"
					},
					{
						"id": 2435,
						"region_name": "El Qraita - قريتي"
					},
					{
						"id": 2436,
						"region_name": "Es Souaidiye - سويدية"
					},
					{
						"id": 2437,
						"region_name": "Faara - فعرا"
					},
					{
						"id": 2438,
						"region_name": "Fissane - فيسان"
					},
					{
						"id": 2439,
						"region_name": "Haouch Es Saiyad Aali - حوش السيد علي"
					},
					{
						"id": 2440,
						"region_name": "Haouchariye - هوشرية"
					},
					{
						"id": 2441,
						"region_name": "Haret El Maasser - حارة المعاصر"
					},
					{
						"id": 2442,
						"region_name": "Hariqa el hermel - حريقة الهرمل"
					},
					{
						"id": 2443,
						"region_name": "Hawch Beit Ismail - حوش بيت اسماعيل"
					},
					{
						"id": 2444,
						"region_name": "Hermel - هرمل"
					},
					{
						"id": 2445,
						"region_name": "Hermel Jbab - هرمل جباب"
					},
					{
						"id": 2446,
						"region_name": "Hmaire - حميرة"
					},
					{
						"id": 2447,
						"region_name": "Jawz - جوز"
					},
					{
						"id": 2448,
						"region_name": "Jisr el Aassi - جسر العاصي"
					},
					{
						"id": 2449,
						"region_name": "Jouar El Hachich - جوار الحشيش"
					},
					{
						"id": 2450,
						"region_name": "Jouret el Mzar - جورة المزار"
					},
					{
						"id": 2451,
						"region_name": "Kouakh - كواخ"
					},
					{
						"id": 2452,
						"region_name": "Maaisr - معيصرة"
					},
					{
						"id": 2453,
						"region_name": "Mansoureh - منصورة"
					},
					{
						"id": 2454,
						"region_name": "Marj Hine - مرجحين"
					},
					{
						"id": 2455,
						"region_name": "Mazraat Beit el Fqih - مزرعة بيت الفقيه"
					},
					{
						"id": 2456,
						"region_name": "Mazraat Beit Et Tachm - مزرعة بيت الطشم"
					},
					{
						"id": 2457,
						"region_name": "Mazraat Et Talle - مراح التلة"
					},
					{
						"id": 2458,
						"region_name": "Mnaira - منيرة"
					},
					{
						"id": 2459,
						"region_name": "Mrah Aabbas - مراح عباس"
					},
					{
						"id": 2460,
						"region_name": "Mrah Beit Aalaoui - مراح بيت علاو"
					},
					{
						"id": 2461,
						"region_name": "Mrah Bou Kamar al Dine - مراح بو قمر الدين"
					},
					{
						"id": 2462,
						"region_name": "Mrah Ech Chaab - مراح الشعاب الهرمل"
					},
					{
						"id": 2463,
						"region_name": "Mrah ech Charqi - مراح الشرقي"
					},
					{
						"id": 2464,
						"region_name": "Mrah ech Chnain - مراح شنين"
					},
					{
						"id": 2465,
						"region_name": "Mrah el Aaqabe - مراح العقبة"
					},
					{
						"id": 2466,
						"region_name": "Mrah El Ain - مراح العين"
					},
					{
						"id": 2467,
						"region_name": "Mrah el Arab - مراح العرب"
					},
					{
						"id": 2468,
						"region_name": "Mrah El Dalil - مراح الدليل"
					},
					{
						"id": 2469,
						"region_name": "Mrah el Gharbi - مراح الغربي"
					},
					{
						"id": 2470,
						"region_name": "Mrah el Mahlise - مراح المحليسة"
					},
					{
						"id": 2471,
						"region_name": "Mrah El Mouchref - مراح المشرف"
					},
					{
						"id": 2472,
						"region_name": "Mrah El Mougher - مراح المغر"
					},
					{
						"id": 2473,
						"region_name": "Mrah el Qorne - مراح القرنة"
					},
					{
						"id": 2474,
						"region_name": "Mrah el Qraita - مراح القريتا"
					},
					{
						"id": 2475,
						"region_name": "Mrah es Siyaid - مراح السياد"
					},
					{
						"id": 2476,
						"region_name": "Mrah esh Shmis - مراح الشميس الهرمل"
					},
					{
						"id": 2477,
						"region_name": "Mrah Ez Zakbe - مراح الزكبة"
					},
					{
						"id": 2478,
						"region_name": "Mrah Houssain Taane - مراح حسين طعان"
					},
					{
						"id": 2479,
						"region_name": "Mrah Naaouas - مراح النواس"
					},
					{
						"id": 2480,
						"region_name": "Mrah Sejoud - مراح سجد"
					},
					{
						"id": 2481,
						"region_name": "Mrah Yassine - مراح ياسين"
					},
					{
						"id": 2482,
						"region_name": "Nasriye - ناصرية الهرمل"
					},
					{
						"id": 2483,
						"region_name": "Ouadi en Nayra - وادي النيرة"
					},
					{
						"id": 2484,
						"region_name": "Ouadi Er Ratle - وادي الرطل"
					},
					{
						"id": 2485,
						"region_name": "Ouadi et Tourkmane - وادي التركمان"
					},
					{
						"id": 2486,
						"region_name": "Qanafez - قنافذ"
					},
					{
						"id": 2487,
						"region_name": "Qasr - القصر"
					},
					{
						"id": 2488,
						"region_name": "Quadi el Karm - وادي الكرم"
					},
					{
						"id": 2489,
						"region_name": "Ras Baalbek el Gharbi - راس بعلبك الغربي"
					},
					{
						"id": 2490,
						"region_name": "Ras Baalbek Ouadi Faara - راس بعلبك وادي فعرا"
					},
					{
						"id": 2491,
						"region_name": "Salhat El Ma - سهلات المي"
					},
					{
						"id": 2492,
						"region_name": "Sharbine el Faouqa - شربين الفوقا"
					},
					{
						"id": 2493,
						"region_name": "Souaisse - السويسة "
					},
					{
						"id": 2494,
						"region_name": "Tall El far - تل الفار"
					},
					{
						"id": 2495,
						"region_name": "Wadi Bnit - وادي بنيت"
					},
					{
						"id": 2496,
						"region_name": "Wadi Faara - وادي فعرا"
					},
					{
						"id": 2497,
						"region_name": "Zighrine - زغرين"
					},
					{
						"id": 2498,
						"region_name": "Zighrine Et Tahta - زغرين التحتى"
					},
					{
						"id": 2499,
						"region_name": "Zouaitini Zighrine - الزويتينه زغرين"
					}
				]
			}
		]
	},
	{
		"id": 3,
		"GovernorateDescription": "Beirut - بيروت",
		"Districts": [
			{
				"id": 4,
				"DistrictDescription": "Beirut - بيروت",
				"Cities": [
					{
						"id": 2500,
						"region_name": "Aadlyeh - العدلية"
					},
					{
						"id": 2501,
						"region_name": "Achrafiye - الاشرفية"
					},
					{
						"id": 2502,
						"region_name": "Ain El Tine - عين التينة"
					},
					{
						"id": 2503,
						"region_name": "Ain Mreisse - عين المريسه"
					},
					{
						"id": 2504,
						"region_name": "Al Hikmat - حكمة"
					},
					{
						"id": 2505,
						"region_name": "Bab idriss - باب إدريس"
					},
					{
						"id": 2506,
						"region_name": "Bachoura - باشورة"
					},
					{
						"id": 2507,
						"region_name": "Basta Faouka - بسطا الفوقا"
					},
					{
						"id": 2508,
						"region_name": "Basta Tahta - بسطا التحتا"
					},
					{
						"id": 2509,
						"region_name": "Beirut - بيروت"
					},
					{
						"id": 2510,
						"region_name": "Beirut Central District - وسط بيروت"
					},
					{
						"id": 2511,
						"region_name": "Bourj Abi Haidar - برج أبو حيدر"
					},
					{
						"id": 2512,
						"region_name": "Champ de courses - ميدان سباق الخيل"
					},
					{
						"id": 2513,
						"region_name": "Corniche El Naher - كورنيش النهر"
					},
					{
						"id": 2514,
						"region_name": "Dar Al Fatwa - دارالفتوى"
					},
					{
						"id": 2515,
						"region_name": "Ej Jeitaoui - جعيتاوي"
					},
					{
						"id": 2516,
						"region_name": "El Aamliye - عاملية"
					},
					{
						"id": 2517,
						"region_name": "El Ghabe - غابي"
					},
					{
						"id": 2518,
						"region_name": "El Hamra - حمرا"
					},
					{
						"id": 2519,
						"region_name": "El Horge - حرش"
					},
					{
						"id": 2520,
						"region_name": "El Majidiye Beirut - مجيدية (بيروت)"
					},
					{
						"id": 2521,
						"region_name": "El Manara - منارة"
					},
					{
						"id": 2522,
						"region_name": "El Wata - الوطى"
					},
					{
						"id": 2523,
						"region_name": "El Zarif - ظريف"
					},
					{
						"id": 2524,
						"region_name": "Fourn el Hayek - فرن الحايك"
					},
					{
						"id": 2525,
						"region_name": "Gemmayze - جميزة"
					},
					{
						"id": 2526,
						"region_name": "Grand Serail - سراي الكبير"
					},
					{
						"id": 2527,
						"region_name": "Hotel Dieu - اوتيل ديو"
					},
					{
						"id": 2528,
						"region_name": "Jamia - الجامعة "
					},
					{
						"id": 2529,
						"region_name": "Jisr - جسر"
					},
					{
						"id": 2530,
						"region_name": "Jounblat - جنبلاط"
					},
					{
						"id": 2531,
						"region_name": "Khodr - الخضر"
					},
					{
						"id": 2532,
						"region_name": "La Sagesse - الحكمة"
					},
					{
						"id": 2533,
						"region_name": "Malaab - ملعب"
					},
					{
						"id": 2534,
						"region_name": "Manara - المنارة"
					},
					{
						"id": 2535,
						"region_name": "Mar Elias - مار الياس"
					},
					{
						"id": 2536,
						"region_name": "Mar Maroun - مار مارون"
					},
					{
						"id": 2537,
						"region_name": "Mar Mitr - مار متر"
					},
					{
						"id": 2538,
						"region_name": "Mar Mkhayel - مار مخايل"
					},
					{
						"id": 2539,
						"region_name": "Mar Nqoula - مار نقولا"
					},
					{
						"id": 2540,
						"region_name": "Marfaa - مرفأ"
					},
					{
						"id": 2541,
						"region_name": "Mazraa - المزرعة"
					},
					{
						"id": 2542,
						"region_name": "Medouar - مدور"
					},
					{
						"id": 2543,
						"region_name": "Minet el Hosn - مينا الحصن"
					},
					{
						"id": 2544,
						"region_name": "Moussaitbe - مصيطبة"
					},
					{
						"id": 2545,
						"region_name": "Moustachfa er Roum - مستشفى الروم"
					},
					{
						"id": 2546,
						"region_name": "Najmeh - النجمة"
					},
					{
						"id": 2547,
						"region_name": "Nasra - الناصرة"
					},
					{
						"id": 2548,
						"region_name": "Palais De Justice - قصر العدل"
					},
					{
						"id": 2549,
						"region_name": "Parc - سباق الخيل"
					},
					{
						"id": 2550,
						"region_name": "Patriarcat - بطركية"
					},
					{
						"id": 2551,
						"region_name": "Place de l'Etoile - ساحة النجمة"
					},
					{
						"id": 2552,
						"region_name": "Qantari - قنطاري"
					},
					{
						"id": 2553,
						"region_name": "Qobaiyat - قبيات"
					},
					{
						"id": 2554,
						"region_name": "Qoraitem - قريطم"
					},
					{
						"id": 2555,
						"region_name": "Ramlet el Bayda - الرملة البيضا"
					},
					{
						"id": 2556,
						"region_name": "Raoucheh - روشه"
					},
					{
						"id": 2557,
						"region_name": "Ras Beirut - راس بيروت"
					},
					{
						"id": 2558,
						"region_name": "Ras El Nabaa - راس النبع"
					},
					{
						"id": 2559,
						"region_name": "Remeil - رميل"
					},
					{
						"id": 2560,
						"region_name": "Saife - صيفي"
					},
					{
						"id": 2561,
						"region_name": "Sanayeh - الصنائع"
					},
					{
						"id": 2562,
						"region_name": "Sioufi - السيوفي"
					},
					{
						"id": 2563,
						"region_name": "Snoubra - صنوبره"
					},
					{
						"id": 2564,
						"region_name": "Tallet Druze - تلة الدروز"
					},
					{
						"id": 2565,
						"region_name": "Tallet el Khayat - تلة الخياط"
					},
					{
						"id": 2566,
						"region_name": "Tariq El Jdide - طريق الجديدة"
					},
					{
						"id": 2567,
						"region_name": "Unesco - الاونسكو"
					},
					{
						"id": 2568,
						"region_name": "Universite Americaine - الجامعة الاميركية"
					},
					{
						"id": 2569,
						"region_name": "Universite St Joseph - جامعة القديس يوسف"
					},
					{
						"id": 2570,
						"region_name": "Zoqaq el Blat - زقاق البلاط"
					}
				]
			},
			{
				"id": 27,
				"DistrictDescription": "Dahie - ضاحية",
				"Cities": [
					{
						"id": 3055,
						"region_name": "Bir el AAbed - بئر العبد"
					},
					{
						"id": 3056,
						"region_name": "Bir Hassan - بئر حسن"
					},
					{
						"id": 3058,
						"region_name": "Borj el Brajne - برج البراجنة"
					},
					{
						"id": 3068,
						"region_name": "Chiayah - شياح"
					},
					{
						"id": 3084,
						"region_name": "El Ouzaai - اوزاعي"
					},
					{
						"id": 3086,
						"region_name": "Er Rouais - الرويس"
					},
					{
						"id": 3088,
						"region_name": "Et Tahouita - التحويطة"
					},
					{
						"id": 3094,
						"region_name": "Ghobeire - غبيري"
					},
					{
						"id": 3103,
						"region_name": "Haret Hraik - حارة حريك"
					},
					{
						"id": 3108,
						"region_name": "Jnah - جناح"
					},
					{
						"id": 3117,
						"region_name": "Lailake - ليلكي"
					},
					{
						"id": 3123,
						"region_name": "Mraije - مريجة"
					},
					{
						"id": 3142,
						"region_name": "Tahouitet el Ghadir - تحويطة الغدير"
					},
					{
						"id": 4676,
						"region_name": "Ghobeiri - الغبيري"
					},
					{
						"id": 4677,
						"region_name": "Chiyah - الشياح"
					},
					{
						"id": 4678,
						"region_name": "Rawdet El Chahidan - روضة الشهيدين"
					},
					{
						"id": 4679,
						"region_name": "Kniset Mar Mkhayel - كنيسة مار مخايل"
					},
					{
						"id": 4680,
						"region_name": "Hay El Amerken - حي الأميركان"
					},
					{
						"id": 4681,
						"region_name": "Sfeir - صفير"
					},
					{
						"id": 4682,
						"region_name": "Jamous - جاموس"
					},
					{
						"id": 4683,
						"region_name": "Saint Therese - سانت تيريز"
					},
					{
						"id": 4684,
						"region_name": "Autostrade Sayid Hadi - أوتوستراد سيد هادي"
					},
					{
						"id": 4685,
						"region_name": "Rweis - الرويس"
					},
					{
						"id": 4686,
						"region_name": "Lebanese University - الجامعة اللبنانية"
					},
					{
						"id": 4687,
						"region_name": "Tarik El Matar - طريق المطار"
					},
					{
						"id": 4688,
						"region_name": "Barakat - بركات"
					},
					{
						"id": 4689,
						"region_name": "Hay El Selom - حي السلم"
					},
					{
						"id": 4690,
						"region_name": "Ajniha El Khamsa - أجنحة الخمسة"
					},
					{
						"id": 4691,
						"region_name": "Bir Hassan - بئر حسن"
					},
					{
						"id": 4692,
						"region_name": "Jnah - الجناح"
					},
					{
						"id": 4693,
						"region_name": "Ouzai - الأوزاعي"
					},
					{
						"id": 4694,
						"region_name": "Tarik EL Matar - طريق المطار"
					}
				]
			}
		]
	},
	{
		"id": 4,
		"GovernorateDescription": "Bekaa - البقاع",
		"Districts": [
			{
				"id": 5,
				"DistrictDescription": "Rachaya - راشيا",
				"Cities": [
					{
						"id": 2571,
						"region_name": "Aaiha - عيحا"
					},
					{
						"id": 2572,
						"region_name": "Aaqbe - عقبة راشيّا"
					},
					{
						"id": 2573,
						"region_name": "Aaz el Aarab - عز العرب"
					},
					{
						"id": 2574,
						"region_name": "Ain Aarab - عين عرب"
					},
					{
						"id": 2575,
						"region_name": "Ain Aata - عين عطا"
					},
					{
						"id": 2576,
						"region_name": "Ain Hircha - عين حرشة"
					},
					{
						"id": 2577,
						"region_name": "Aita el Foukhar - عيتا الفخار"
					},
					{
						"id": 2578,
						"region_name": "Bakka - بكا"
					},
					{
						"id": 2579,
						"region_name": "Bakkifa - بكيفا راشيا"
					},
					{
						"id": 2580,
						"region_name": "Bayader el Aadas - بيادر العدس"
					},
					{
						"id": 2581,
						"region_name": "Beit Lahia - بيت لهيا"
					},
					{
						"id": 2582,
						"region_name": "Bire rachaya - بيرة راشّيا"
					},
					{
						"id": 2583,
						"region_name": "Dahr el Ahmar - ضهر الاحمر"
					},
					{
						"id": 2584,
						"region_name": "Deir el Aachayer - دير العشاير"
					},
					{
						"id": 2585,
						"region_name": "El Faqaa - الفقعة"
					},
					{
						"id": 2586,
						"region_name": "En Nabi Safa - نبي صفا"
					},
					{
						"id": 2587,
						"region_name": "Haloua - حلوة"
					},
					{
						"id": 2588,
						"region_name": "Haouch El Qinnabeh - حوش القنعبة "
					},
					{
						"id": 2589,
						"region_name": "Haret el Kaouasbe - حارة الكواسبة"
					},
					{
						"id": 2590,
						"region_name": "Jebb Farah - جب فرح"
					},
					{
						"id": 2591,
						"region_name": "Kaoukaba - كوكبا "
					},
					{
						"id": 2592,
						"region_name": "Kfar Danis - كفر دنيس"
					},
					{
						"id": 2593,
						"region_name": "Kfar Mechki - كفر مشكي"
					},
					{
						"id": 2594,
						"region_name": "Kfar Qouq - كفرقوق"
					},
					{
						"id": 2595,
						"region_name": "Khirbet Rouha - خربة روحا"
					},
					{
						"id": 2596,
						"region_name": "Majdal Balhis - مجدل بلهيص"
					},
					{
						"id": 2597,
						"region_name": "Marj es Simah - مرج السماح"
					},
					{
						"id": 2598,
						"region_name": "Mazraat Aazzi - مزرعة عزِي"
					},
					{
						"id": 2599,
						"region_name": "Mazraat Ain Qeniye - مزرعة عين قنية"
					},
					{
						"id": 2600,
						"region_name": "Mazraat Deir el Aachaiyer - مزرعة دير العشاير"
					},
					{
						"id": 2601,
						"region_name": "Mazraat el Qalioun - مزرعة القليون"
					},
					{
						"id": 2602,
						"region_name": "Mazraat Jaafar - مزرعة جعفر"
					},
					{
						"id": 2603,
						"region_name": "Mazret Al Chmis - مزرعة الشميسة"
					},
					{
						"id": 2604,
						"region_name": "Mdoukha - مدوخا"
					},
					{
						"id": 2605,
						"region_name": "Mhaidse - محيدثه "
					},
					{
						"id": 2606,
						"region_name": "Qnaabe - قنعبة"
					},
					{
						"id": 2607,
						"region_name": "Rachaiya - راشيّا الوادي "
					},
					{
						"id": 2608,
						"region_name": "Rafid - الرافد راشيا"
					},
					{
						"id": 2609,
						"region_name": "Selsata Mazraat Dier el Aachayer - مزرعة سلساتا"
					},
					{
						"id": 2610,
						"region_name": "Tannoura - تنورة"
					},
					{
						"id": 2611,
						"region_name": "Yanta - ينطا"
					}
				]
			},
			{
				"id": 6,
				"DistrictDescription": "West Bekaa - البقاع الغربي",
				"Cities": [
					{
						"id": 2612,
						"region_name": "Aammiq - عميق"
					},
					{
						"id": 2613,
						"region_name": "Aana - عانا"
					},
					{
						"id": 2614,
						"region_name": "Ain et Tine West Bekaa - عين التينة بقاع الغربي"
					},
					{
						"id": 2615,
						"region_name": "Ain Zebde - عين زبدة"
					},
					{
						"id": 2616,
						"region_name": "Aitanit - عيتنيت"
					},
					{
						"id": 2617,
						"region_name": "Al-Wakf - الوقف بقاع الغربي"
					},
					{
						"id": 2618,
						"region_name": "Baaloul - بعلول"
					},
					{
						"id": 2619,
						"region_name": "Bab Maraa - باب مارع"
					},
					{
						"id": 2620,
						"region_name": "Chebreqiyet Aammiq - شبرقية عميق"
					},
					{
						"id": 2621,
						"region_name": "Dakoue - دكوة"
					},
					{
						"id": 2622,
						"region_name": "Deir Ain ej Jaouze - دير عين الجوزة"
					},
					{
						"id": 2623,
						"region_name": "Deir Tahnich - دير طحنيش"
					},
					{
						"id": 2624,
						"region_name": "El Jezire - الجزيرة"
					},
					{
						"id": 2625,
						"region_name": "El Marj - المرج"
					},
					{
						"id": 2626,
						"region_name": "Ghazze - غزة"
					},
					{
						"id": 2627,
						"region_name": "Hammara - حمارة"
					},
					{
						"id": 2628,
						"region_name": "Harime es Soughra - حريمة الصغرى"
					},
					{
						"id": 2629,
						"region_name": "Houch Aammiq - حوش عميق"
					},
					{
						"id": 2630,
						"region_name": "Houch el Harime - حوش الحريمة"
					},
					{
						"id": 2631,
						"region_name": "Houch es Saalouk - حوش السعلوك"
					},
					{
						"id": 2632,
						"region_name": "Joub Jannine - جب جنين"
					},
					{
						"id": 2633,
						"region_name": "Kafraiya West Bekaa - كفريا بقاع الغربي"
					},
					{
						"id": 2634,
						"region_name": "Kamed el Laouz - كامد اللوز"
					},
					{
						"id": 2635,
						"region_name": "Khiara - الخيارة"
					},
					{
						"id": 2636,
						"region_name": "Khiara el Aatiqa - خيارة العتيقة"
					},
					{
						"id": 2637,
						"region_name": "Khirbet Qanafar - خربة قنافار"
					},
					{
						"id": 2638,
						"region_name": "Lala - لالا"
					},
					{
						"id": 2639,
						"region_name": "Libbaya - لبايا"
					},
					{
						"id": 2640,
						"region_name": "Loussia - لوسيا"
					},
					{
						"id": 2641,
						"region_name": "Machgara - مشغرة"
					},
					{
						"id": 2642,
						"region_name": "Mansoura - المنصورة"
					},
					{
						"id": 2643,
						"region_name": "Meidoun - ميدون"
					},
					{
						"id": 2644,
						"region_name": "Nabaa el Khraizat - نبع الخريزات"
					},
					{
						"id": 2645,
						"region_name": "Qaraoun - القرعون"
					},
					{
						"id": 2646,
						"region_name": "Qillaya - قلايا"
					},
					{
						"id": 2647,
						"region_name": "Raouda Istabl - روضة إسطبل"
					},
					{
						"id": 2648,
						"region_name": "Saghbine - صغبين"
					},
					{
						"id": 2649,
						"region_name": "Sohmor - سحمر"
					},
					{
						"id": 2650,
						"region_name": "Souairi - صويري"
					},
					{
						"id": 2651,
						"region_name": "Soultan Yaaqoub Aradi - سلطان يعقوب أراضي"
					},
					{
						"id": 2652,
						"region_name": "Soultane Yaaqoub el Faouqa - سلطان يعقوب الفوقا"
					},
					{
						"id": 2653,
						"region_name": "Soultane Yaaqoub el Tahta - سلطان يعقوب التحتا"
					},
					{
						"id": 2654,
						"region_name": "Tall ez Zaazeaa - تل الزعزع"
					},
					{
						"id": 2655,
						"region_name": "Tall Znoub - تل زنوب"
					},
					{
						"id": 2656,
						"region_name": "Tall Znoub ej Jdide - تل زنوب جديدة"
					},
					{
						"id": 2657,
						"region_name": "Yohmor West Bekaa - يحمر بقاع الغربي"
					},
					{
						"id": 2658,
						"region_name": "Zellaya - زلايا"
					}
				]
			},
			{
				"id": 7,
				"DistrictDescription": "Zahle - زحلة",
				"Cities": [
					{
						"id": 2659,
						"region_name": "Aali en Nahri - علي النهري"
					},
					{
						"id": 2660,
						"region_name": "Ablah - ابلح"
					},
					{
						"id": 2661,
						"region_name": "Ain el Ghmiqa - عين الغميقة"
					},
					{
						"id": 2662,
						"region_name": "Ain Kfar Zabad - عين كفر زبد"
					},
					{
						"id": 2663,
						"region_name": "Al Faour - الفاعور"
					},
					{
						"id": 2664,
						"region_name": "Anjar - عنجر"
					},
					{
						"id": 2665,
						"region_name": "Bar Elias - بر الياس"
					},
					{
						"id": 2666,
						"region_name": "Bayyadat - البياضة"
					},
					{
						"id": 2667,
						"region_name": "Berbara zahle - بربارة زحلة"
					},
					{
						"id": 2668,
						"region_name": "Bouarej - بوارج"
					},
					{
						"id": 2669,
						"region_name": "Chamssine - شمسين"
					},
					{
						"id": 2670,
						"region_name": "Chebrqiyet Tabet - شبرقية تابت"
					},
					{
						"id": 2671,
						"region_name": "Chtaura - شتورا"
					},
					{
						"id": 2672,
						"region_name": "Dahr al Harf - ضهر الحرف"
					},
					{
						"id": 2673,
						"region_name": "Dahr Blait - ضهر البلايط"
					},
					{
						"id": 2674,
						"region_name": "Dahr es Souane - ضهر الصوان"
					},
					{
						"id": 2675,
						"region_name": "Dalhamiye - دلهميه"
					},
					{
						"id": 2676,
						"region_name": "Deir el Ghazal - دير الغزال"
					},
					{
						"id": 2677,
						"region_name": "Deir Zenoun - دير زنون"
					},
					{
						"id": 2678,
						"region_name": "Er Ramtaniye - الرمطانية"
					},
					{
						"id": 2679,
						"region_name": "Es Sraij - سريج"
					},
					{
						"id": 2680,
						"region_name": "Fourzol - فرزل "
					},
					{
						"id": 2681,
						"region_name": "Hakl Hammana - حقل حمانا"
					},
					{
						"id": 2682,
						"region_name": "Haouch ed Dibs - حوش الدبس"
					},
					{
						"id": 2683,
						"region_name": "Haouch el Mendara - حوش مندره"
					},
					{
						"id": 2684,
						"region_name": "Haouch el Oumara Aradi - زحلة حوش الامراء أراضي"
					},
					{
						"id": 2685,
						"region_name": "Haouch el Sayade - حوش الصيادي"
					},
					{
						"id": 2686,
						"region_name": "Haouch Moussa Anjar - حوش موسى عنجر"
					},
					{
						"id": 2687,
						"region_name": "Haret el Fikani - حارة الفيكاني"
					},
					{
						"id": 2688,
						"region_name": "Hazerta - حزرتا"
					},
					{
						"id": 2689,
						"region_name": "Hechmech - حشمش"
					},
					{
						"id": 2690,
						"region_name": "Houch El-Ghanam - حوش الغنم"
					},
					{
						"id": 2691,
						"region_name": "Houch ez Zaraane - حوش الزراعنة"
					},
					{
						"id": 2692,
						"region_name": "Houch Hala - حوش حالا"
					},
					{
						"id": 2693,
						"region_name": "Jdita - جديتا"
					},
					{
						"id": 2694,
						"region_name": "Jlala - جلالا"
					},
					{
						"id": 2695,
						"region_name": "Karak Nouh - كرك نوح "
					},
					{
						"id": 2696,
						"region_name": "Kfar Zabad - كفر زبد"
					},
					{
						"id": 2697,
						"region_name": "Koussaya - قوسايا"
					},
					{
						"id": 2698,
						"region_name": "Ksara - كسارة"
					},
					{
						"id": 2699,
						"region_name": "MADINAT AL SINA'IYAT - مدينة الصناعية"
					},
					{
						"id": 2700,
						"region_name": "Majdel Anjar - مجدل عنجر"
					},
					{
						"id": 2701,
						"region_name": "Mar Elias Zahle - مار الياس زحلة"
					},
					{
						"id": 2702,
						"region_name": "Masnaa Zahle - المصنع زحلة"
					},
					{
						"id": 2703,
						"region_name": "Massa - مسا"
					},
					{
						"id": 2704,
						"region_name": "Mazraat el Mehqane - مزرعة المحقان"
					},
					{
						"id": 2705,
						"region_name": "Mazraat Zahle - مزرعة زحلة"
					},
					{
						"id": 2706,
						"region_name": "Meksi - مكسة"
					},
					{
						"id": 2707,
						"region_name": "Mreijat - مريجات"
					},
					{
						"id": 2708,
						"region_name": "Naassate - نعصات"
					},
					{
						"id": 2709,
						"region_name": "Nabi Aila - نبي ايلا"
					},
					{
						"id": 2710,
						"region_name": "Nasriyet Rizq - ناصرية رزق"
					},
					{
						"id": 2711,
						"region_name": "Nasriyet Zahle - ناصرية  زحلة"
					},
					{
						"id": 2712,
						"region_name": "Niha zahle - نيحا زحلة"
					},
					{
						"id": 2713,
						"region_name": "Ouadi ed Deloum - وادي الدلم"
					},
					{
						"id": 2714,
						"region_name": "Ouadi el Aarayech - وادي العرايش"
					},
					{
						"id": 2715,
						"region_name": "Qaa er Rim - قاع الريم"
					},
					{
						"id": 2716,
						"region_name": "Qabb Elias - قب الياس"
					},
					{
						"id": 2717,
						"region_name": "Qarqoud - قرقود"
					},
					{
						"id": 2718,
						"region_name": "Qeisser - قيصر"
					},
					{
						"id": 2719,
						"region_name": "Qommol - قمّل"
					},
					{
						"id": 2720,
						"region_name": "Raait - رعيت"
					},
					{
						"id": 2721,
						"region_name": "Ras el Ain Zahle - راس العين زحلة"
					},
					{
						"id": 2722,
						"region_name": "Rassiyeh - الراسية"
					},
					{
						"id": 2723,
						"region_name": "Rayak - رياق"
					},
					{
						"id": 2724,
						"region_name": "Saadnayel - سعدنايل"
					},
					{
						"id": 2725,
						"region_name": "Sahret el Qach - صحرة القش"
					},
					{
						"id": 2726,
						"region_name": "Taalabaya - تعلبايا"
					},
					{
						"id": 2727,
						"region_name": "Taanayel - تعنايل"
					},
					{
						"id": 2728,
						"region_name": "Tall el Akhdar - تل الاخضر"
					},
					{
						"id": 2729,
						"region_name": "Tcheflik Eddeh Haouch - تشفلك اده حوش"
					},
					{
						"id": 2730,
						"region_name": "Tcheflik Qiqano - تشفلك قيقانو"
					},
					{
						"id": 2731,
						"region_name": "Tell Chiha - تل شيحا"
					},
					{
						"id": 2732,
						"region_name": "Tell el Aamara - تل العمارة"
					},
					{
						"id": 2733,
						"region_name": "Terbol - تربل"
					},
					{
						"id": 2734,
						"region_name": "Touaite - تويتي"
					},
					{
						"id": 2735,
						"region_name": "Zahle - زحلة"
					},
					{
						"id": 2736,
						"region_name": "Zahle  Mar Antonios - زحلة مار أنطونيوس"
					},
					{
						"id": 2737,
						"region_name": "Zahle  Mar Gerges - زحلة مار جرجس"
					},
					{
						"id": 2738,
						"region_name": "Zahle  Saydet En Najat - زحلة  سيدة النجاة"
					},
					{
						"id": 2739,
						"region_name": "Zahle Aradi - زحلة أراضي"
					},
					{
						"id": 2740,
						"region_name": "Zahle Maalaqa Aradi - زحلة معلقة أراضي"
					},
					{
						"id": 2741,
						"region_name": "Zebdol - زبدل"
					}
				]
			}
		]
	},
	{
		"id": 5,
		"GovernorateDescription": "El Nabatieh - النبطية",
		"Districts": [
			{
				"id": 8,
				"DistrictDescription": "Bent Jbeil - بنت جبيل",
				"Cities": [
					{
						"id": 2742,
						"region_name": "Aainata - عيناتا بنت جبيل"
					},
					{
						"id": 2743,
						"region_name": "Aaitaroun - عيترون"
					},
					{
						"id": 2744,
						"region_name": "Aayta ej Jabal Zott - عيتا الجبل الزط"
					},
					{
						"id": 2745,
						"region_name": "Ain Ebel - عين ابل"
					},
					{
						"id": 2746,
						"region_name": "Aita Ech Chaab - عيتا الشعب"
					},
					{
						"id": 2747,
						"region_name": "Beit Lif - بيت ليف"
					},
					{
						"id": 2748,
						"region_name": "Beit Yahnoun - بيت ياحون"
					},
					{
						"id": 2749,
						"region_name": "Bent Jubail - بنت جبيل"
					},
					{
						"id": 2750,
						"region_name": "Bir es Sanassel - بير السناسل"
					},
					{
						"id": 2751,
						"region_name": "Borj Qalaouiye - برج قلويه"
					},
					{
						"id": 2752,
						"region_name": "Braachit - برعشيت"
					},
					{
						"id": 2753,
						"region_name": "Chaqra - شقرا "
					},
					{
						"id": 2754,
						"region_name": "Debl Oummiya - دبل امية"
					},
					{
						"id": 2755,
						"region_name": "Deir Ntar - دير نطار"
					},
					{
						"id": 2756,
						"region_name": "Doubiye - دوبية"
					},
					{
						"id": 2757,
						"region_name": "El Birke -  البركه بنت جبيل"
					},
					{
						"id": 2758,
						"region_name": "El Biyad - البياد"
					},
					{
						"id": 2759,
						"region_name": "Froun - فرون"
					},
					{
						"id": 2760,
						"region_name": "Ghandouriye Bent Jbeil - غندورية بنت جبيل"
					},
					{
						"id": 2761,
						"region_name": "Haddatha - حداثا"
					},
					{
						"id": 2762,
						"region_name": "Hanine - حنين"
					},
					{
						"id": 2763,
						"region_name": "Hariss - حاريص"
					},
					{
						"id": 2764,
						"region_name": "Hay el Jameaa - الجامع بنت جبيل "
					},
					{
						"id": 2765,
						"region_name": "Jmaijime - جميجمة"
					},
					{
						"id": 2766,
						"region_name": "Kafra Bent Jbeil - كفرا بنت جبيل"
					},
					{
						"id": 2767,
						"region_name": "Kfar Dounine - كفر دونين"
					},
					{
						"id": 2768,
						"region_name": "Khirbit Silim - خربة سلم"
					},
					{
						"id": 2769,
						"region_name": "Kounine - كونين"
					},
					{
						"id": 2770,
						"region_name": "Maroun er Ras - مارون الراس"
					},
					{
						"id": 2771,
						"region_name": "Mazraat Aazze - مزرعة عزه"
					},
					{
						"id": 2772,
						"region_name": "Qalaouiye - قلويه"
					},
					{
						"id": 2773,
						"region_name": "Qaouzah - قوزح"
					},
					{
						"id": 2774,
						"region_name": "Qatmoun - قطموم"
					},
					{
						"id": 2775,
						"region_name": "Rachaf - رشاف"
					},
					{
						"id": 2776,
						"region_name": "Ramiye - رامية بنت جبيل"
					},
					{
						"id": 2777,
						"region_name": "Rmaich - رميش"
					},
					{
						"id": 2778,
						"region_name": "Safad el Battikh - صفد البطيخ"
					},
					{
						"id": 2779,
						"region_name": "Salhani - الصلحاني"
					},
					{
						"id": 2780,
						"region_name": "Soultaniye - سلطانية "
					},
					{
						"id": 2781,
						"region_name": "Sribbine - صربين"
					},
					{
						"id": 2782,
						"region_name": "Taire - طيري"
					},
					{
						"id": 2783,
						"region_name": "Tebnine - تبنين"
					},
					{
						"id": 2784,
						"region_name": "Yaroun - يارون"
					},
					{
						"id": 2785,
						"region_name": "Yater - ياطر"
					}
				]
			},
			{
				"id": 9,
				"DistrictDescription": "El Nabatieh - النبطية",
				"Cities": [
					{
						"id": 2786,
						"region_name": "Aabba - عبا"
					},
					{
						"id": 2787,
						"region_name": "Aadchit ech Chqif - عدشيت الشقيف"
					},
					{
						"id": 2788,
						"region_name": "Aali et Taher - علي الطاهر"
					},
					{
						"id": 2789,
						"region_name": "Aarnoun - أرنون"
					},
					{
						"id": 2790,
						"region_name": "Aazze - عزه"
					},
					{
						"id": 2791,
						"region_name": "Ain Qana - عين قانا"
					},
					{
						"id": 2792,
						"region_name": "Arab Salim - عرب صاليم"
					},
					{
						"id": 2793,
						"region_name": "Braiqaa - بريقع"
					},
					{
						"id": 2794,
						"region_name": "Charqiye - شرقية"
					},
					{
						"id": 2795,
						"region_name": "Choukine - شوكين"
					},
					{
						"id": 2796,
						"region_name": "Deir ez Zahrani - دير الزهراني"
					},
					{
						"id": 2797,
						"region_name": "Doueir El Nabatieh - الدوير النبطية"
					},
					{
						"id": 2798,
						"region_name": "El Aaqide - عقيدة "
					},
					{
						"id": 2799,
						"region_name": "El Bayad - نبطية البياض"
					},
					{
						"id": 2800,
						"region_name": "El Midane - الميدان"
					},
					{
						"id": 2801,
						"region_name": "Es Serail - السراي"
					},
					{
						"id": 2802,
						"region_name": "Habbouch - حبوش "
					},
					{
						"id": 2803,
						"region_name": "Hamra  En-Nabattiyeh - الحمرا النبطية"
					},
					{
						"id": 2804,
						"region_name": "Harouf - حروف"
					},
					{
						"id": 2805,
						"region_name": "Hima Aarnoun - حمى أرنون"
					},
					{
						"id": 2806,
						"region_name": "Hmaile - حميلة"
					},
					{
						"id": 2807,
						"region_name": "Houmine el Faouqa - حومين الفوقا"
					},
					{
						"id": 2808,
						"region_name": "Houmine et Tahta - حومين التحتا"
					},
					{
						"id": 2809,
						"region_name": "Insar - إنصار"
					},
					{
						"id": 2810,
						"region_name": "Jaouhariye - جوهرية"
					},
					{
						"id": 2811,
						"region_name": "Jarjouaa - جرجوع"
					},
					{
						"id": 2812,
						"region_name": "Jbaa El Nabatieh - جباع النبطية"
					},
					{
						"id": 2813,
						"region_name": "Jibchit - جبشيت"
					},
					{
						"id": 2814,
						"region_name": "Kafra - كفرا"
					},
					{
						"id": 2815,
						"region_name": "Kfar Dajjal - كفردجال"
					},
					{
						"id": 2816,
						"region_name": "Kfar Fila - كفرفيلا"
					},
					{
						"id": 2817,
						"region_name": "Kfar Roummane - كفر رمان"
					},
					{
						"id": 2818,
						"region_name": "Kfar Sir - كفرصير"
					},
					{
						"id": 2819,
						"region_name": "Kfar Tebnit - كفر تبنيت"
					},
					{
						"id": 2820,
						"region_name": "Kfaroue - كفروة أو بفروة"
					},
					{
						"id": 2821,
						"region_name": "Kfour El Nabatieh - الكفور النبطية"
					},
					{
						"id": 2822,
						"region_name": "Maifadoun - ميفدون"
					},
					{
						"id": 2823,
						"region_name": "Manzleh - المنزلة"
					},
					{
						"id": 2824,
						"region_name": "Mazraat Ain Bou Souar - مزرعة عين بوسوار"
					},
					{
						"id": 2825,
						"region_name": "Mazraat Bsafour - مزرعة بصفُور"
					},
					{
						"id": 2826,
						"region_name": "Mazraat Chelbael - مزرعة شلبعل"
					},
					{
						"id": 2827,
						"region_name": "Mazraat Dmoul - مزرعة دمول"
					},
					{
						"id": 2828,
						"region_name": "Mazraat el Baiyad - مزرعة البياض"
					},
					{
						"id": 2829,
						"region_name": "Mazraat el Khraibe - مزرعة الخريبة"
					},
					{
						"id": 2830,
						"region_name": "Mazraat Kfar ej Jouz - مزرعة كفر جوز"
					},
					{
						"id": 2831,
						"region_name": "Mazraat Maqsam Aali et Taher - مزرعة علي الطاهر"
					},
					{
						"id": 2832,
						"region_name": "Mazraat Qalaat Meis - مزرعة قلعة ميس"
					},
					{
						"id": 2833,
						"region_name": "Nabatiye el Faouqa - نبطية الفوقا"
					},
					{
						"id": 2834,
						"region_name": "Nabatiye el Tahta - نبطية التحتا"
					},
					{
						"id": 2835,
						"region_name": "Nmairiye - نميرية"
					},
					{
						"id": 2836,
						"region_name": "Qaaqaait ej Jisr - قعقعية الجسر"
					},
					{
						"id": 2837,
						"region_name": "Qsaibe El Nabatieh - قصيبة النبطية"
					},
					{
						"id": 2838,
						"region_name": "Roumine - رومين"
					},
					{
						"id": 2839,
						"region_name": "Sarba El Nabatieh - صربا النبطية"
					},
					{
						"id": 2840,
						"region_name": "Sir el Gharbiye - صير الغربية"
					},
					{
						"id": 2841,
						"region_name": "Toul - تول"
					},
					{
						"id": 2842,
						"region_name": "Yohmor - يحمر"
					},
					{
						"id": 2843,
						"region_name": "Zaoutar ech Charqiye - زوطر الشرقية"
					},
					{
						"id": 2844,
						"region_name": "Zaoutar el Gharbiye - زوطر الغربية"
					},
					{
						"id": 2845,
						"region_name": "Zebdine El Nabatieh - زبدين النبطية"
					},
					{
						"id": 2846,
						"region_name": "Zefta - زفتا"
					}
				]
			},
			{
				"id": 10,
				"DistrictDescription": "Hasbaya - حاصبيا",
				"Cities": [
					{
						"id": 2847,
						"region_name": "Abou Qamha - أبو قمحة"
					},
					{
						"id": 2848,
						"region_name": "Ain Jerfa - عين جرفا"
					},
					{
						"id": 2849,
						"region_name": "Ain Qenya - عين قنيا"
					},
					{
						"id": 2850,
						"region_name": "Ain Tanta - عين تنتا حاصبيا"
					},
					{
						"id": 2851,
						"region_name": "Ayn et Tine - عين التينة حاصبيا"
					},
					{
						"id": 2852,
						"region_name": "Bathaniye - بيتسنيا"
					},
					{
						"id": 2853,
						"region_name": "Berghoz - برغز"
					},
					{
						"id": 2854,
						"region_name": "Chebaa - شبعا"
					},
					{
						"id": 2855,
						"region_name": "Chouaia - شويّا "
					},
					{
						"id": 2856,
						"region_name": "Dehayrjate - الدحيرجات"
					},
					{
						"id": 2857,
						"region_name": "Dellafi - دلافة"
					},
					{
						"id": 2858,
						"region_name": "El Majidiye - مجيدية حاصبيا"
					},
					{
						"id": 2859,
						"region_name": "Fardis - الفرديس"
					},
					{
						"id": 2860,
						"region_name": "Fashkoul - فشكول"
					},
					{
						"id": 2861,
						"region_name": "Halta Hasbaya - حلتا حاصبيّا"
					},
					{
						"id": 2862,
						"region_name": "Hasbaiya - حاصبيّا"
					},
					{
						"id": 2863,
						"region_name": "Hebbariye - هبّارية"
					},
					{
						"id": 2864,
						"region_name": "Kaoukaba Hasbaya - كوكبا حاصبيّا"
					},
					{
						"id": 2865,
						"region_name": "Kfair - كفير الزيت"
					},
					{
						"id": 2866,
						"region_name": "Kfar Chouba - كفر شوبا"
					},
					{
						"id": 2867,
						"region_name": "Kfar Hamam - كفرحمام"
					},
					{
						"id": 2868,
						"region_name": "Khallet el Ghazale - خلة الغزالة"
					},
					{
						"id": 2869,
						"region_name": "Khalouat el Biyada - خلوات البياضة"
					},
					{
						"id": 2870,
						"region_name": "Khalouat Hasbaya - خلوات حاصبيّا"
					},
					{
						"id": 2871,
						"region_name": "Khirbet ed Dwayr - خربة الدوير"
					},
					{
						"id": 2872,
						"region_name": "Khraibe Hasbaya - خريبة حاصبيّا"
					},
					{
						"id": 2873,
						"region_name": "Mari - ماري"
					},
					{
						"id": 2874,
						"region_name": "Marj Ez Zouhour Dnaibe - مرج الزهور الدنيبة"
					},
					{
						"id": 2875,
						"region_name": "Mazraat Qafoua - مزرعة قفوى"
					},
					{
						"id": 2876,
						"region_name": "Mazraat Ras el Baidar - مزرعة راس البيدر"
					},
					{
						"id": 2877,
						"region_name": "Mimes - ميمس"
					},
					{
						"id": 2878,
						"region_name": "Ouazzani - وزاني"
					},
					{
						"id": 2879,
						"region_name": "Rachaiya el Foukhar - راشيّا الفخار"
					},
					{
						"id": 2880,
						"region_name": "Slaiyeb - صلايب"
					},
					{
						"id": 2881,
						"region_name": "Tallet el Qaaqour - تلة القعقور"
					},
					{
						"id": 2882,
						"region_name": "Zaghla - زغلة"
					}
				]
			},
			{
				"id": 11,
				"DistrictDescription": "Marjaayoun - مرجعيون",
				"Cities": [
					{
						"id": 2883,
						"region_name": " MAZRAAT ZAIYEH  - مزرعة زئية"
					},
					{
						"id": 2884,
						"region_name": "Aadaisse - عديسة مرجعيون"
					},
					{
						"id": 2885,
						"region_name": "Aadchit el Qoussair - عدشيت القصير"
					},
					{
						"id": 2886,
						"region_name": "Aalmane - علمانة"
					},
					{
						"id": 2887,
						"region_name": "Aamra - عمرة"
					},
					{
						"id": 2888,
						"region_name": "Aarab el Louaize - عرب اللويزة"
					},
					{
						"id": 2889,
						"region_name": "Ain Aarab Marjaayoun - عين عرب مرجعيون"
					},
					{
						"id": 2890,
						"region_name": "Baiyouda - بويضة مرجعيون"
					},
					{
						"id": 2891,
						"region_name": "Beni Haiyane - بني حيان"
					},
					{
						"id": 2892,
						"region_name": "Blat Marjaayoun - بلاط مرجعيون"
					},
					{
						"id": 2893,
						"region_name": "Blida - بليدا"
					},
					{
						"id": 2894,
						"region_name": "Borj El Mlouk - برج الملوك"
					},
					{
						"id": 2895,
						"region_name": "Deir Mimas - دير ميماس"
					},
					{
						"id": 2896,
						"region_name": "Deir Siriane - دير سريان"
					},
					{
						"id": 2897,
						"region_name": "Dibbine - دبين"
					},
					{
						"id": 2898,
						"region_name": "Houla - حولا"
					},
					{
						"id": 2899,
						"region_name": "Houra - حوره"
					},
					{
						"id": 2900,
						"region_name": "Ibl es Saqi - إبل السقي"
					},
					{
						"id": 2901,
						"region_name": "Jlali el Ghozlane - جلال الغزلان"
					},
					{
						"id": 2902,
						"region_name": "Kfar Kila - كفركيلا"
					},
					{
						"id": 2903,
						"region_name": "Khiam - الخيام"
					},
					{
						"id": 2904,
						"region_name": "Khirbe - خربة برج الملوك"
					},
					{
						"id": 2905,
						"region_name": "Maissate - الميسات"
					},
					{
						"id": 2906,
						"region_name": "Majdel Silim - مجدل سلم"
					},
					{
						"id": 2907,
						"region_name": "Marjaayoun - مرجعيون"
					},
					{
						"id": 2908,
						"region_name": "Markaba - مركبا"
					},
					{
						"id": 2909,
						"region_name": "Mazraat Doumiat - مزرعة دمياط"
					},
					{
						"id": 2910,
						"region_name": "Mazraat ej Jreine - مزرعة الجرين"
					},
					{
						"id": 2911,
						"region_name": "Mazraat Sahsahiye - مزرعة السهسهية"
					},
					{
						"id": 2912,
						"region_name": "Meiss ej Jabal - ميس الجبل"
					},
					{
						"id": 2913,
						"region_name": "Mhaibib - محيبيب"
					},
					{
						"id": 2914,
						"region_name": "Qabrikha - قبريخا"
					},
					{
						"id": 2915,
						"region_name": "Qalaat Doubai - قلعة دبي"
					},
					{
						"id": 2916,
						"region_name": "Qantara - قنطرة"
					},
					{
						"id": 2917,
						"region_name": "Qlaiaa - قليعة"
					},
					{
						"id": 2918,
						"region_name": "Qsair - قصير"
					},
					{
						"id": 2919,
						"region_name": "Rabb et Talatine - رب التلاتين"
					},
					{
						"id": 2920,
						"region_name": "Sarda - مزرعة  سردة"
					},
					{
						"id": 2921,
						"region_name": "Serail - سراي "
					},
					{
						"id": 2922,
						"region_name": "Souane Marjaayoun - صوانة مرجعيون"
					},
					{
						"id": 2923,
						"region_name": "Taibe Marjaayoun - طيبة مرجعيون"
					},
					{
						"id": 2924,
						"region_name": "Tallouse - طلوسة"
					},
					{
						"id": 2925,
						"region_name": "Touline - تولين"
					}
				]
			}
		]
	},
	{
		"id": 6,
		"GovernorateDescription": "Mount Lebanon - جبل لبنان",
		"Districts": [
			{
				"id": 12,
				"DistrictDescription": "Aley - عاليه",
				"Cities": [
					{
						"id": 2926,
						"region_name": "Aabey - عبيه"
					},
					{
						"id": 2927,
						"region_name": "Aaley ej Jdide - عاليه الجديدة"
					},
					{
						"id": 2928,
						"region_name": "Aamroussieh Choueifat - عمروسية الشويفات"
					},
					{
						"id": 2929,
						"region_name": "Aaramoun - عرمون"
					},
					{
						"id": 2930,
						"region_name": "Aazzouniye - العزونيه"
					},
					{
						"id": 2931,
						"region_name": "Ain Al Saydeh -  عين السيدة "
					},
					{
						"id": 2932,
						"region_name": "Ain Anoub - عين عنوب"
					},
					{
						"id": 2933,
						"region_name": "Ain Dara - عين داره"
					},
					{
						"id": 2934,
						"region_name": "Ain Drafil - عين درافيل"
					},
					{
						"id": 2935,
						"region_name": "Ain ej Jdide - عين الجديدة"
					},
					{
						"id": 2936,
						"region_name": "Ain el Fraidis -  عين الفريديس "
					},
					{
						"id": 2937,
						"region_name": "Ain el Halzoun - عين الحلزون"
					},
					{
						"id": 2938,
						"region_name": "Ain el Jaouze - عين الجوزه"
					},
					{
						"id": 2939,
						"region_name": "Ain el Marj - عين المرج"
					},
					{
						"id": 2940,
						"region_name": "Ain er Remmane - عين الرمانة"
					},
					{
						"id": 2941,
						"region_name": "Ain Hala - عين حالا"
					},
					{
						"id": 2942,
						"region_name": "Ain Ksour - عين كسور"
					},
					{
						"id": 2943,
						"region_name": "Ain Trez - عين تراز"
					},
					{
						"id": 2944,
						"region_name": "Ainab - عيناب"
					},
					{
						"id": 2945,
						"region_name": "Aitat - عيتات"
					},
					{
						"id": 2946,
						"region_name": "Aley - عاليه"
					},
					{
						"id": 2947,
						"region_name": "Baaouerta - بعورته"
					},
					{
						"id": 2948,
						"region_name": "Baissour - بيصور"
					},
					{
						"id": 2949,
						"region_name": "Bchamoun - بشامون"
					},
					{
						"id": 2950,
						"region_name": "Bdedoun - بدادون"
					},
					{
						"id": 2951,
						"region_name": "Bedghane - بدغان"
					},
					{
						"id": 2952,
						"region_name": "Bhamdoun ed Dayaa - بحمدون الضيعة"
					},
					{
						"id": 2953,
						"region_name": "Bhamdoun el Mhatta - بحمدون المحطة"
					},
					{
						"id": 2954,
						"region_name": "Bhouara - بحوارا"
					},
					{
						"id": 2955,
						"region_name": "Bihat - بهات"
					},
					{
						"id": 2956,
						"region_name": "Bkhechtay - بخشتيه"
					},
					{
						"id": 2957,
						"region_name": "Blaibel - بليبل"
					},
					{
						"id": 2958,
						"region_name": "Bmahray - بمهريه"
					},
					{
						"id": 2959,
						"region_name": "Bmekkine - بمكين"
					},
					{
						"id": 2960,
						"region_name": "Bnaiye - البينه"
					},
					{
						"id": 2961,
						"region_name": "Botros - بطرس"
					},
					{
						"id": 2962,
						"region_name": "Bou Zraid - بو زريدة"
					},
					{
						"id": 2963,
						"region_name": "Bou Zraide - بو زريده"
					},
					{
						"id": 2964,
						"region_name": "Bourdine - بردين"
					},
					{
						"id": 2965,
						"region_name": "Bsatine - البساتين"
					},
					{
						"id": 2966,
						"region_name": "Bserrine - بسرين"
					},
					{
						"id": 2967,
						"region_name": "Bsous - بسوس"
					},
					{
						"id": 2968,
						"region_name": "Btalloun - بطلون"
					},
					{
						"id": 2969,
						"region_name": "Btater - بتاتر"
					},
					{
						"id": 2970,
						"region_name": "Chamlikh - شاملخ"
					},
					{
						"id": 2971,
						"region_name": "Chanay - شانيه"
					},
					{
						"id": 2972,
						"region_name": "Charoun - شارون"
					},
					{
						"id": 2973,
						"region_name": "Chartoun - شرتون"
					},
					{
						"id": 2974,
						"region_name": "Chemlane - شملان"
					},
					{
						"id": 2975,
						"region_name": "Chqif Btalloun - شقيف بطلون"
					},
					{
						"id": 2976,
						"region_name": "Dahr El Ouahch - ضهر الوحش"
					},
					{
						"id": 2977,
						"region_name": "Daqqoun - دقون"
					},
					{
						"id": 2978,
						"region_name": "Deir Qoubil - دير قوبل"
					},
					{
						"id": 2979,
						"region_name": "Dfoun - دفون"
					},
					{
						"id": 2980,
						"region_name": "Doueir er Remmane - دوير الرمان"
					},
					{
						"id": 2981,
						"region_name": "El Blat -  بلاط  سلفايا"
					},
					{
						"id": 2982,
						"region_name": "El Ftaihat - الفتيحات"
					},
					{
						"id": 2983,
						"region_name": "El Ouata - الوطا"
					},
					{
						"id": 2984,
						"region_name": "En Njassa - النجاصة"
					},
					{
						"id": 2985,
						"region_name": "Es Shwayfate - الشويفات"
					},
					{
						"id": 2986,
						"region_name": "Fsaqine - فساقين"
					},
					{
						"id": 2987,
						"region_name": "Ghaboun - الغابون"
					},
					{
						"id": 2988,
						"region_name": "Ghadir - غادير"
					},
					{
						"id": 2989,
						"region_name": "Habramoun - حبرمون"
					},
					{
						"id": 2990,
						"region_name": "Haret Chbib - حارة شبيب"
					},
					{
						"id": 2991,
						"region_name": "Haret el Mir - حارة المير"
					},
					{
						"id": 2992,
						"region_name": "Haret Salem - حارة سالم"
					},
					{
						"id": 2993,
						"region_name": "Hay el Aarab - حي العرب"
					},
					{
						"id": 2994,
						"region_name": "Hay es Sellom - حي السلم"
					},
					{
						"id": 2995,
						"region_name": "Hay es Sindiane - حي السنديانة"
					},
					{
						"id": 2996,
						"region_name": "Homs Oua Hama -  حمص وحمى"
					},
					{
						"id": 2997,
						"region_name": "Houmal - حومال"
					},
					{
						"id": 2998,
						"region_name": "Ighmid - اغميد"
					},
					{
						"id": 2999,
						"region_name": "Jabal El Aarid - جبل العريض"
					},
					{
						"id": 3000,
						"region_name": "Jisr el Qadi - جسر القاضي"
					},
					{
						"id": 3001,
						"region_name": "Kahale - الكحاله"
					},
					{
						"id": 3002,
						"region_name": "Kaifoun - كيفون"
					},
					{
						"id": 3003,
						"region_name": "Kfar Aamaiy - كفرعميه"
					},
					{
						"id": 3004,
						"region_name": "Kfar Matta - كفرمتى"
					},
					{
						"id": 3005,
						"region_name": "Khalde - خلدة"
					},
					{
						"id": 3006,
						"region_name": "Kliliye - كليلة"
					},
					{
						"id": 3007,
						"region_name": "Maaroufiye - معروفية"
					},
					{
						"id": 3008,
						"region_name": "Maasraiti -  معصريتي  "
					},
					{
						"id": 3009,
						"region_name": "Majdalaya - مجدليا"
					},
					{
						"id": 3010,
						"region_name": "Mansouriye bhamdoun -  منصورية بحمدون "
					},
					{
						"id": 3011,
						"region_name": "Mantra - المنطرة"
					},
					{
						"id": 3012,
						"region_name": "Marj Chartoun - مرج شرتون"
					},
					{
						"id": 3013,
						"region_name": "Mazraat el Boueit -  مزرعة البويت "
					},
					{
						"id": 3014,
						"region_name": "Mazraat en Nahr -  مزرعة النهر "
					},
					{
						"id": 3015,
						"region_name": "Mechakhti - مشاقتي"
					},
					{
						"id": 3016,
						"region_name": "Mechrefe -  مشرفه "
					},
					{
						"id": 3017,
						"region_name": "Mejdel Baana -  مجدل بعنا "
					},
					{
						"id": 3018,
						"region_name": "Mounsa Aaley - المونسه عاليه"
					},
					{
						"id": 3019,
						"region_name": "Mreijat Aley -  مريجات عاليه"
					},
					{
						"id": 3020,
						"region_name": "Oumara Choueifat - الشويفات الامراء"
					},
					{
						"id": 3021,
						"region_name": "Qabr Chamoun -  قبر شمون "
					},
					{
						"id": 3022,
						"region_name": "Qmatiye -  قماطية "
					},
					{
						"id": 3023,
						"region_name": "Qoubbe Choueifat - قبة الشويفات"
					},
					{
						"id": 3024,
						"region_name": "Ram Bchamoun - رام بشامون"
					},
					{
						"id": 3025,
						"region_name": "Ramliye -  رملية "
					},
					{
						"id": 3026,
						"region_name": "Rechmaiya -  رشميا "
					},
					{
						"id": 3027,
						"region_name": "Rejme -  رجمة "
					},
					{
						"id": 3028,
						"region_name": "Remhala - رمحالا"
					},
					{
						"id": 3029,
						"region_name": "Rjoum - رجوم"
					},
					{
						"id": 3030,
						"region_name": "Rouissat Sofar -  رويسات صوفر "
					},
					{
						"id": 3031,
						"region_name": "Rouisset en Naamane -  رويسة النعمان "
					},
					{
						"id": 3032,
						"region_name": "Sarahmoul -  سرحمول "
					},
					{
						"id": 3033,
						"region_name": "Sibaal - سبعل"
					},
					{
						"id": 3034,
						"region_name": "Sil - سيل"
					},
					{
						"id": 3035,
						"region_name": "Silfaya - سلفايا"
					},
					{
						"id": 3036,
						"region_name": "Sofar -  صوفر "
					},
					{
						"id": 3037,
						"region_name": "Souq el Gharb - سوق الغرب"
					},
					{
						"id": 3038,
						"region_name": "Taazniye -  تعزانية "
					},
					{
						"id": 3039,
						"region_name": "Touayte Ain Dara - تويته عين داره"
					},
					{
						"id": 3040,
						"region_name": "Watiye - واطية"
					},
					{
						"id": 3041,
						"region_name": "Yinnar - ينار"
					}
				]
			},
			{
				"id": 13,
				"DistrictDescription": "Baabda - بعبدا",
				"Cities": [
					{
						"id": 3042,
						"region_name": "Aabadiye - عبادية"
					},
					{
						"id": 3043,
						"region_name": "Aarbaniye - عربانية"
					},
					{
						"id": 3044,
						"region_name": "Abbdiyeh al Jadideh - عبادية الجديدة"
					},
					{
						"id": 3045,
						"region_name": "Ain er Roummane - عين الرمانة بعبدا"
					},
					{
						"id": 3046,
						"region_name": "Ain es Sihha - عين الصحة"
					},
					{
						"id": 3047,
						"region_name": "Ain Mouaffaq - عين موفق"
					},
					{
						"id": 3048,
						"region_name": "Arayia - عاريا"
					},
					{
						"id": 3049,
						"region_name": "Arsoun - ارصون"
					},
					{
						"id": 3050,
						"region_name": "Baabda - بعبدا"
					},
					{
						"id": 3051,
						"region_name": "Baajour - بعجور"
					},
					{
						"id": 3052,
						"region_name": "Baalchmay - بعلشميه"
					},
					{
						"id": 3053,
						"region_name": "Baalchmay ej Jdide - بعلشميه الجديدة"
					},
					{
						"id": 3054,
						"region_name": "Bhala - بحالا"
					},
					{
						"id": 3057,
						"region_name": "Bmariam - بمريم"
					},
					{
						"id": 3059,
						"region_name": "Boutchai - بطشيه"
					},
					{
						"id": 3060,
						"region_name": "Brazilia - برازيليا"
					},
					{
						"id": 3061,
						"region_name": "Bsaba & Ouadi Dlab - بسابا وادي الدلب"
					},
					{
						"id": 3062,
						"region_name": "Btaaline - بتعلين"
					},
					{
						"id": 3063,
						"region_name": "Btebyat - بتبيات"
					},
					{
						"id": 3064,
						"region_name": "Btekhnay - بتخناي"
					},
					{
						"id": 3065,
						"region_name": "Bzebdine - بزبدين"
					},
					{
						"id": 3066,
						"region_name": "Chatila - شاتيلا"
					},
					{
						"id": 3067,
						"region_name": "Chbaniye - شبانية"
					},
					{
						"id": 3069,
						"region_name": "Chmaissa - شميسة"
					},
					{
						"id": 3070,
						"region_name": "Chouit - شويت"
					},
					{
						"id": 3071,
						"region_name": "Cite Sportive - المدينة الرياضية"
					},
					{
						"id": 3072,
						"region_name": "Dahr el Baidar - ضهر البيدر"
					},
					{
						"id": 3073,
						"region_name": "Deir el Harf - دير الحرف"
					},
					{
						"id": 3074,
						"region_name": "Deir Khouna - دير خونة"
					},
					{
						"id": 3075,
						"region_name": "Deir Mar Elias - دير مار الياس"
					},
					{
						"id": 3076,
						"region_name": "Deir Mar Youhanna - دير مار يوحنا"
					},
					{
						"id": 3077,
						"region_name": "Deir Saiya - دير سيا"
					},
					{
						"id": 3078,
						"region_name": "Dhour Al Aabaydiyeh - ضهور العبادية"
					},
					{
						"id": 3079,
						"region_name": "Dlaibe - دليبه"
					},
					{
						"id": 3080,
						"region_name": "El Baqle - بقلة"
					},
					{
						"id": 3081,
						"region_name": "El Maadan - المعادن"
					},
					{
						"id": 3082,
						"region_name": "El Marmah - مرمح"
					},
					{
						"id": 3083,
						"region_name": "El Mdaoura - مدورا"
					},
					{
						"id": 3085,
						"region_name": "Er Rihaniye - ريحانية"
					},
					{
						"id": 3087,
						"region_name": "Es Sheime - الشيمة"
					},
					{
						"id": 3089,
						"region_name": "Ez Zire - زيره"
					},
					{
						"id": 3090,
						"region_name": "Faiyadiye - فياضية"
					},
					{
						"id": 3091,
						"region_name": "Falougha - فالوغا"
					},
					{
						"id": 3092,
						"region_name": "Fsakin - فساقين ترشيش"
					},
					{
						"id": 3093,
						"region_name": "Furn ech Chebak - فرن الشباك"
					},
					{
						"id": 3095,
						"region_name": "Hadet - حدث"
					},
					{
						"id": 3096,
						"region_name": "Hammana - حمانا"
					},
					{
						"id": 3097,
						"region_name": "Haql Hassan - حقل حسن"
					},
					{
						"id": 3098,
						"region_name": "Haret El Botm - حارة البطم"
					},
					{
						"id": 3099,
						"region_name": "Haret el Mjadle - حارة المجادلة"
					},
					{
						"id": 3100,
						"region_name": "Haret er Roum - حارة الروم"
					},
					{
						"id": 3101,
						"region_name": "Haret es Set - حارة الست"
					},
					{
						"id": 3102,
						"region_name": "Haret Hamze - حارة حمزة"
					},
					{
						"id": 3104,
						"region_name": "Hasbaiya el Metn - حاصبيّا المتن"
					},
					{
						"id": 3105,
						"region_name": "Hazmiye - حازمية "
					},
					{
						"id": 3106,
						"region_name": "Hlaliye - هلالية"
					},
					{
						"id": 3107,
						"region_name": "Jamhour - جمهور"
					},
					{
						"id": 3109,
						"region_name": "Jouar el Haouz - جوار الحوز"
					},
					{
						"id": 3110,
						"region_name": "Jouret Arsoun - جورة ارصون"
					},
					{
						"id": 3111,
						"region_name": "Kahlouniye - كحلونية"
					},
					{
						"id": 3112,
						"region_name": "Kfar Selouane - كفرسلوان"
					},
					{
						"id": 3113,
						"region_name": "Kfarchima - كفرشيما"
					},
					{
						"id": 3114,
						"region_name": "Khalouat - خلوات"
					},
					{
						"id": 3115,
						"region_name": "Khraibe Baabda - خريبة بعبدا"
					},
					{
						"id": 3116,
						"region_name": "Knisse - كنيسة بعبدا"
					},
					{
						"id": 3118,
						"region_name": "Louaize - لويزة"
					},
					{
						"id": 3119,
						"region_name": "Mar Taqla - مار تقلا"
					},
					{
						"id": 3120,
						"region_name": "Mazzraat Maaissra - مزرعة المعيصرة"
					},
					{
						"id": 3121,
						"region_name": "Mdeyrej - مديرج"
					},
					{
						"id": 3122,
						"region_name": "Merdache - مرداشة"
					},
					{
						"id": 3124,
						"region_name": "Mzayraa - مزيرعة بعبدا"
					},
					{
						"id": 3125,
						"region_name": "Ouadi Chahrour el Olia - وادي شحرور العليا"
					},
					{
						"id": 3126,
						"region_name": "Ouadi Chahrour el Soufla - وادي شحرور السفلى"
					},
					{
						"id": 3127,
						"region_name": "Qalaa - القلعة"
					},
					{
						"id": 3128,
						"region_name": "Qirtada - قرطادة"
					},
					{
						"id": 3129,
						"region_name": "Qornayel - قرنايل"
					},
					{
						"id": 3130,
						"region_name": "Qoubbeiaa - قبيع"
					},
					{
						"id": 3131,
						"region_name": "Qraiye - قرية"
					},
					{
						"id": 3132,
						"region_name": "Qsaibe - قصيبة"
					},
					{
						"id": 3133,
						"region_name": "Qtale - قتالة"
					},
					{
						"id": 3134,
						"region_name": "Ras el Harf - راس الحرف"
					},
					{
						"id": 3135,
						"region_name": "Ras el Metn - راس المتن"
					},
					{
						"id": 3136,
						"region_name": "Rouaisset Salima - رويسات صليما"
					},
					{
						"id": 3137,
						"region_name": "Rouisset el Ballout - رويسة البلوط"
					},
					{
						"id": 3138,
						"region_name": "Sabra  - صبرا"
					},
					{
						"id": 3139,
						"region_name": "Salima Baabda - صليما بعبدا"
					},
					{
						"id": 3140,
						"region_name": "Saqi Ain el Hadath - سقي عين الحدث "
					},
					{
						"id": 3141,
						"region_name": "Sibnay - سبنيه"
					},
					{
						"id": 3143,
						"region_name": "Tarchich - ترشيش"
					},
					{
						"id": 3144,
						"region_name": "Warware - الوروار"
					},
					{
						"id": 3145,
						"region_name": "Yarzeh - اليرزة"
					},
					{
						"id": 3146,
						"region_name": "Zandouqa - زندوقة"
					},
					{
						"id": 3147,
						"region_name": "Zouaitini - زويتينة "
					}
				]
			},
			{
				"id": 14,
				"DistrictDescription": "Chouf -  الشوف",
				"Cities": [
					{
						"id": 3148,
						"region_name": "Aalmane Ech Chouf - علمان الشوف"
					},
					{
						"id": 3149,
						"region_name": "Aammatour - عمّاطور"
					},
					{
						"id": 3150,
						"region_name": "Aammiq chouf - عميق الشوف"
					},
					{
						"id": 3151,
						"region_name": "Aanout - عانوت"
					},
					{
						"id": 3152,
						"region_name": "Aaqline - عقلين"
					},
					{
						"id": 3153,
						"region_name": "Aatrine - عترين"
					},
					{
						"id": 3154,
						"region_name": "Aazibett tahta - عزبيات التحتا"
					},
					{
						"id": 3155,
						"region_name": "Ain Aazime - عين عزيمة"
					},
					{
						"id": 3156,
						"region_name": "Ain Bal - عينبال"
					},
					{
						"id": 3157,
						"region_name": "Ain El Assad - عين الاسد"
					},
					{
						"id": 3158,
						"region_name": "Ain el Haour - عين الحور"
					},
					{
						"id": 3159,
						"region_name": "Ain Ouzain - عين وزين"
					},
					{
						"id": 3160,
						"region_name": "Ain Qeni - عين قني"
					},
					{
						"id": 3161,
						"region_name": "Ain Zhalta - عين زحلتا"
					},
					{
						"id": 3162,
						"region_name": "Almane ed Daiaa - علمان الضيعة "
					},
					{
						"id": 3163,
						"region_name": "Baadarane - بعدران"
					},
					{
						"id": 3164,
						"region_name": "Baal en Naame - بعل الناعمه"
					},
					{
						"id": 3165,
						"region_name": "Baaqline - بعقلين"
					},
					{
						"id": 3166,
						"region_name": "Baasir - بعاصير"
					},
					{
						"id": 3167,
						"region_name": "Bachqiye - باشقية"
					},
					{
						"id": 3168,
						"region_name": "Baiqoun - بيقون"
					},
					{
						"id": 3169,
						"region_name": "Baqaoun - بقعون "
					},
					{
						"id": 3170,
						"region_name": "Baqse - بقسه"
					},
					{
						"id": 3171,
						"region_name": "Barja - برجا"
					},
					{
						"id": 3172,
						"region_name": "Barouk - الباروك"
					},
					{
						"id": 3173,
						"region_name": "Bater - باتر"
					},
					{
						"id": 3174,
						"region_name": "Batloun - بتلون"
					},
					{
						"id": 3175,
						"region_name": "Bchatfine - بشتفين"
					},
					{
						"id": 3176,
						"region_name": "Bchella Ech Chouf - بشله الشوف"
					},
					{
						"id": 3177,
						"region_name": "Beit ed Dine - بيت الدين"
					},
					{
						"id": 3178,
						"region_name": "Benoeti - بنواتي"
					},
					{
						"id": 3179,
						"region_name": "Bire - بيرة "
					},
					{
						"id": 3180,
						"region_name": "Bkechtine - بكشتين"
					},
					{
						"id": 3181,
						"region_name": "Bkifa - بكيفا"
					},
					{
						"id": 3182,
						"region_name": "Boqaata - بقعاتا"
					},
					{
						"id": 3183,
						"region_name": "Borjein - البرجين"
					},
					{
						"id": 3184,
						"region_name": "Boudine - بودين"
					},
					{
						"id": 3185,
						"region_name": "Boutme - بطمة"
					},
					{
						"id": 3186,
						"region_name": "Bqaiaa - بقيعة"
					},
					{
						"id": 3187,
						"region_name": "Brih  - بريح"
					},
					{
						"id": 3188,
						"region_name": "Bsaba - بسابا"
					},
					{
						"id": 3189,
						"region_name": "Bsennay - بصّني"
					},
					{
						"id": 3190,
						"region_name": "Bzina - بزينا"
					},
					{
						"id": 3191,
						"region_name": "Chhim - شحيم"
					},
					{
						"id": 3192,
						"region_name": "Chmaarine - شمعرين"
					},
					{
						"id": 3193,
						"region_name": "Chmis chouf - شميس "
					},
					{
						"id": 3194,
						"region_name": "Choualiq Deir El Qamar - شواليق دير القمر"
					},
					{
						"id": 3195,
						"region_name": "Chourit - شوريت "
					},
					{
						"id": 3196,
						"region_name": "Dabche - دبشه"
					},
					{
						"id": 3197,
						"region_name": "Dahr ech Chqif - ضهر الشقيف"
					},
					{
						"id": 3198,
						"region_name": "Dahr el Aaqline - ضهر عقلين"
					},
					{
						"id": 3199,
						"region_name": "Dahr el Mghara - ضهر المغارة"
					},
					{
						"id": 3200,
						"region_name": "Dalhoun - دلهون"
					},
					{
						"id": 3201,
						"region_name": "Damour - الدامور"
					},
					{
						"id": 3202,
						"region_name": "Daraiya - داريا الشوف"
					},
					{
						"id": 3203,
						"region_name": "Dawha - الدوحة"
					},
					{
						"id": 3204,
						"region_name": "Deir Baba - دير بابا"
					},
					{
						"id": 3205,
						"region_name": "Deir Dourite - دير دوريت"
					},
					{
						"id": 3206,
						"region_name": "Deir El MKhales - دير المخلص الشوف"
					},
					{
						"id": 3207,
						"region_name": "Deir el Qamar - دير القمر"
					},
					{
						"id": 3208,
						"region_name": "Deir er Rahbat - دير الراهبات"
					},
					{
						"id": 3209,
						"region_name": "Deir es Saide - دير السيدة"
					},
					{
						"id": 3210,
						"region_name": "Deir Qouche - دير كوشه"
					},
					{
						"id": 3211,
						"region_name": "Delghani - دلغاني"
					},
					{
						"id": 3212,
						"region_name": "Dhour Ain Al Haour - ضهور عين الحور"
					},
					{
						"id": 3213,
						"region_name": "Dibbiye - دبية"
					},
					{
						"id": 3214,
						"region_name": "Dmit - دميت"
					},
					{
						"id": 3215,
						"region_name": "Douair Ed Dibbiye - دوير الدبية"
					},
					{
						"id": 3216,
						"region_name": "Douair El Hara - دوير الحارة"
					},
					{
						"id": 3217,
						"region_name": "Ech Charbine - شربين "
					},
					{
						"id": 3218,
						"region_name": "Ed Dalhamiye - دلهميه الدبيه"
					},
					{
						"id": 3219,
						"region_name": "Ej Jreid - جريد"
					},
					{
						"id": 3220,
						"region_name": "El Aaqbe joun - عقبة جون"
					},
					{
						"id": 3221,
						"region_name": "El Battal - بطال"
					},
					{
						"id": 3222,
						"region_name": "El Kherbe - الخربة الشوف"
					},
					{
						"id": 3223,
						"region_name": "El Khraibe - خريبة بيرة الشوف"
					},
					{
						"id": 3224,
						"region_name": "El Meghraiqa - مغريقا"
					},
					{
						"id": 3225,
						"region_name": "El Msayed - المصايد"
					},
					{
						"id": 3226,
						"region_name": "El Qateaa - قتيعا"
					},
					{
						"id": 3227,
						"region_name": "Eskandarouna - إسكندرونة "
					},
					{
						"id": 3228,
						"region_name": "Et Taamir - تعمير"
					},
					{
						"id": 3229,
						"region_name": "Faouarat Jaafar - فوارة جعفر"
					},
					{
						"id": 3230,
						"region_name": "Fraidiss - فريديس الشوف"
					},
					{
						"id": 3231,
						"region_name": "Ghabet Jaafar - غابة جعفر"
					},
					{
						"id": 3232,
						"region_name": "Ghandouriye - غندورية"
					},
					{
						"id": 3233,
						"region_name": "Gharife - غريفة"
					},
					{
						"id": 3234,
						"region_name": "Haffet el Hajal - حافة الحجل"
					},
					{
						"id": 3235,
						"region_name": "Halioune el Faouqa - حليونة الفوقا"
					},
					{
						"id": 3236,
						"region_name": "Halioune et Tahta - حليونة التحتا"
					},
					{
						"id": 3237,
						"region_name": "Hamra Ed Damour - حمرا الدامور"
					},
					{
						"id": 3238,
						"region_name": "Hardouch - حردوش"
					},
					{
						"id": 3239,
						"region_name": "Haret Aalmane - حارة علمان"
					},
					{
						"id": 3240,
						"region_name": "Haret Baasir - حارة بعاصير"
					},
					{
						"id": 3241,
						"region_name": "Haret Beit Madi - حارة بيت ماضي"
					},
					{
						"id": 3242,
						"region_name": "Haret el Ouasta - حارة الواسطة"
					},
					{
						"id": 3243,
						"region_name": "Haret en Naame - حارة الناعمه"
					},
					{
						"id": 3244,
						"region_name": "Haret Jandal - حارة جندل"
					},
					{
						"id": 3245,
						"region_name": "Hasrout - حصروت"
					},
					{
						"id": 3246,
						"region_name": "Hjaijiye - حجاجية"
					},
					{
						"id": 3247,
						"region_name": "Jadra - جدرا"
					},
					{
						"id": 3248,
						"region_name": "Jahliye - جاهلية"
					},
					{
						"id": 3249,
						"region_name": "Jall Bou Haidar - جل بو حيدر"
					},
					{
						"id": 3250,
						"region_name": "Jamailiye - جميلية"
					},
					{
						"id": 3251,
						"region_name": "Jbaa - جباع"
					},
					{
						"id": 3252,
						"region_name": "Jdaide chouf - جديدة الشوف"
					},
					{
						"id": 3253,
						"region_name": "Je'ayel - جعايل"
					},
					{
						"id": 3254,
						"region_name": "Jiblaye - جبلايا"
					},
					{
						"id": 3255,
						"region_name": "Jiye - جية"
					},
					{
						"id": 3256,
						"region_name": "Jlailiye - جليلية"
					},
					{
						"id": 3257,
						"region_name": "Joun - جون"
					},
					{
						"id": 3258,
						"region_name": "Kahlouniye chouf - كحلونية الشوف"
					},
					{
						"id": 3259,
						"region_name": "Ketermaya - كترمايا"
					},
					{
						"id": 3260,
						"region_name": "Kfar Faqoud - كفرفاقود"
					},
					{
						"id": 3261,
						"region_name": "Kfar Hai - كفر حي"
					},
					{
						"id": 3262,
						"region_name": "Kfar Hamal - كفر حمل"
					},
					{
						"id": 3263,
						"region_name": "Kfar Him - كفرحيم"
					},
					{
						"id": 3264,
						"region_name": "Kfar Nabrakh - كفرنبرخ"
					},
					{
						"id": 3265,
						"region_name": "Kfar Niss - كفرنيس"
					},
					{
						"id": 3266,
						"region_name": "Kfar Qatra - كفرقطرة"
					},
					{
						"id": 3267,
						"region_name": "Khalouat Jernaya - خلوات جرنايا"
					},
					{
						"id": 3268,
						"region_name": "Khiam Ed Damour - خيام الدامور"
					},
					{
						"id": 3269,
						"region_name": "Khirbet Dabach - خربة دبش"
					},
					{
						"id": 3270,
						"region_name": "Khirbit Bisri - خربة بسري"
					},
					{
						"id": 3271,
						"region_name": "Khraibe chouf - خريبة الشوف"
					},
					{
						"id": 3272,
						"region_name": "Knisse chouf - كنيسة الشوف"
					},
					{
						"id": 3273,
						"region_name": "Lahbiye - لهبية"
					},
					{
						"id": 3274,
						"region_name": "Maaniye - معنيه"
					},
					{
						"id": 3275,
						"region_name": "Maaser Beit ed Dine - معاصر بيت الدين"
					},
					{
						"id": 3276,
						"region_name": "Maasser ech Chouf - معاصر الشوف"
					},
					{
						"id": 3277,
						"region_name": "Majdalouna - مجدلونا"
					},
					{
						"id": 3278,
						"region_name": "Majdel el Meouch - مجدل المعوش"
					},
					{
						"id": 3279,
						"region_name": "Maqsabe - مقصبة"
					},
					{
						"id": 3280,
						"region_name": "Mar Mikheyel Ed Damour - مار مخايل الدامور"
					},
					{
						"id": 3281,
						"region_name": "Mar Taqla En Naame - مار تقلا الناعمه"
					},
					{
						"id": 3282,
						"region_name": "Marj Aali - مرج علي"
					},
					{
						"id": 3283,
						"region_name": "Marj Barja - مرج برجا"
					},
					{
						"id": 3284,
						"region_name": "Marj Ketermaya - مرج كترمايا"
					},
					{
						"id": 3285,
						"region_name": "Marjiat - مرجيات"
					},
					{
						"id": 3286,
						"region_name": "Marjiat Borjein - برجين "
					},
					{
						"id": 3287,
						"region_name": "Mazboud - مزبود"
					},
					{
						"id": 3288,
						"region_name": "Mazmoura - مزمورة"
					},
					{
						"id": 3289,
						"region_name": "Mazraat ech Chouf - مزرعة"
					},
					{
						"id": 3290,
						"region_name": "Mazraat ed Dahr - مزرعة الضهر"
					},
					{
						"id": 3291,
						"region_name": "Mazraat ed Doueir - مزرعة الدوير"
					},
					{
						"id": 3292,
						"region_name": "Mazraat El Barghoutiye - مزرعة البرغوتية"
					},
					{
						"id": 3293,
						"region_name": "Mazraat er Rzaniye - مزرعة الرزَانية"
					},
					{
						"id": 3294,
						"region_name": "Mazraat ez Zaitounniyeh - مزرعة الزيتونه"
					},
					{
						"id": 3295,
						"region_name": "Mazraat Khafiche - مزرعة الخفيش"
					},
					{
						"id": 3296,
						"region_name": "Mechref - مشرف"
					},
					{
						"id": 3297,
						"region_name": "Mermata - مرماتا"
					},
					{
						"id": 3298,
						"region_name": "Mghaire - مغيره "
					},
					{
						"id": 3299,
						"region_name": "Mghairiye Chouf - مغيرية  الشوف"
					},
					{
						"id": 3300,
						"region_name": "Mouhtaqara - محتقره"
					},
					{
						"id": 3301,
						"region_name": "Moukhtara - مختارة"
					},
					{
						"id": 3302,
						"region_name": "Mristi - مرستى"
					},
					{
						"id": 3303,
						"region_name": "Mtaile - مطيلة"
					},
					{
						"id": 3304,
						"region_name": "Mtairiyat - مطيرية"
					},
					{
						"id": 3305,
						"region_name": "Mtoulle  - مطله "
					},
					{
						"id": 3306,
						"region_name": "Naame - ناعمه"
					},
					{
						"id": 3307,
						"region_name": "Nabaa es Safa - نبع الصفا"
					},
					{
						"id": 3308,
						"region_name": "Nabi Younos - نبي يونس"
					},
					{
						"id": 3309,
						"region_name": "Niha - نيحا"
					},
					{
						"id": 3310,
						"region_name": "Ouadi Abou Youssef - وادي ابو يوسف"
					},
					{
						"id": 3311,
						"region_name": "Ouadi Bnahle - وادي بنحليه"
					},
					{
						"id": 3312,
						"region_name": "Ouadi Deir Dourit - وادي دير دوريت"
					},
					{
						"id": 3313,
						"region_name": "Ouadi ed Deir - وادي الدير"
					},
					{
						"id": 3314,
						"region_name": "Ouadi es Sitt - وادي الست"
					},
					{
						"id": 3315,
						"region_name": "Ouadi Ez Zeyni - وادي الزينة"
					},
					{
						"id": 3316,
						"region_name": "Ouarhaniye - ورهانية"
					},
					{
						"id": 3317,
						"region_name": "Qalaat el Hosn - قلعة الحصن"
					},
					{
						"id": 3318,
						"region_name": "Qalaat En Nimiri - قلعة النمري"
					},
					{
						"id": 3319,
						"region_name": "Qassoube - قصوبة"
					},
					{
						"id": 3320,
						"region_name": "Qraiaa - قريعة"
					},
					{
						"id": 3321,
						"region_name": "Quardaniye - وردانيه"
					},
					{
						"id": 3322,
						"region_name": "Ras Aalous - راس علوس"
					},
					{
						"id": 3323,
						"region_name": "Rmaile - رميلة "
					},
					{
						"id": 3324,
						"region_name": "Saadiyat - السعديات"
					},
					{
						"id": 3325,
						"region_name": "Sabouniye - صابونية"
					},
					{
						"id": 3326,
						"region_name": "Sahl Ej Jiye - سهل الجية"
					},
					{
						"id": 3327,
						"region_name": "Samqaniye - سمقانية"
					},
					{
						"id": 3328,
						"region_name": "Saraouniye - سرعونية"
					},
					{
						"id": 3329,
						"region_name": "Sibline - سبلين"
					},
					{
						"id": 3330,
						"region_name": "Sirjbal - سرجبال"
					},
					{
						"id": 3331,
						"region_name": "Souane Bsaba - صوانة بسابا"
					},
					{
						"id": 3332,
						"region_name": "Traile - تريله"
					},
					{
						"id": 3333,
						"region_name": "Zaarour - زعرور"
					},
					{
						"id": 3334,
						"region_name": "Zaarouriye - زعرورية"
					}
				]
			},
			{
				"id": 15,
				"DistrictDescription": "El Meten - المتن",
				"Cities": [
					{
						"id": 3335,
						"region_name": "Aabdine - عبدين نقاش"
					},
					{
						"id": 3336,
						"region_name": "Aafs - عفص"
					},
					{
						"id": 3337,
						"region_name": "Aairoun - عيرون"
					},
					{
						"id": 3338,
						"region_name": "Aammariye - عمّارية"
					},
					{
						"id": 3339,
						"region_name": "Aaqbe Zalqa - عقبة الزلقا"
					},
					{
						"id": 3340,
						"region_name": "Aaraar - عرعار"
					},
					{
						"id": 3341,
						"region_name": "Aatchane - عطشانة"
					},
					{
						"id": 3342,
						"region_name": "Abou Mizane - أبو ميزان"
					},
					{
						"id": 3343,
						"region_name": "Ain Aalaq - عين علق"
					},
					{
						"id": 3344,
						"region_name": "Ain Aar - عين عار"
					},
					{
						"id": 3345,
						"region_name": "Ain El Kharroube - عين الخروبة"
					},
					{
						"id": 3346,
						"region_name": "Ain El Qabou - عين القبو"
					},
					{
						"id": 3347,
						"region_name": "Ain el Qassis - عين القسيس"
					},
					{
						"id": 3348,
						"region_name": "Ain el-Kach - عين القش"
					},
					{
						"id": 3349,
						"region_name": "Ain Er Rihane el meten - عين الريحان المتن"
					},
					{
						"id": 3350,
						"region_name": "Ain Es Safsaf el meten - عين الصفصاف المتن"
					},
					{
						"id": 3351,
						"region_name": "Ain Es Sindiane - عين السنديانة"
					},
					{
						"id": 3352,
						"region_name": "Ain et Toufaha - عين التفاحة"
					},
					{
						"id": 3353,
						"region_name": "Ain Najm - عين نجم"
					},
					{
						"id": 3354,
						"region_name": "Ain Saade - عين سعادة"
					},
					{
						"id": 3355,
						"region_name": "Ain Zaitoune - عين الزيتونة"
					},
					{
						"id": 3356,
						"region_name": "Aintoura el meten - عينطورة المتن"
					},
					{
						"id": 3357,
						"region_name": "Aiyoun - عيون المتن"
					},
					{
						"id": 3358,
						"region_name": "Antelias - انطلياس"
					},
					{
						"id": 3359,
						"region_name": "Baabdat - بعبدات"
					},
					{
						"id": 3360,
						"region_name": "Baaqrif - بعقريف"
					},
					{
						"id": 3361,
						"region_name": "Balouaa - بالوع"
					},
					{
						"id": 3362,
						"region_name": "Baouchriye - بوشرية"
					},
					{
						"id": 3363,
						"region_name": "Baskinta - بسكنتا"
					},
					{
						"id": 3364,
						"region_name": "Bchellama - بشلامة"
					},
					{
						"id": 3365,
						"region_name": "Beit Chebab - بيت شباب"
					},
					{
						"id": 3366,
						"region_name": "Beit El Koukko - بيت الككو"
					},
					{
						"id": 3367,
						"region_name": "Beit Meri - بيت مري"
					},
					{
						"id": 3368,
						"region_name": "Belle Vue - بلّفو"
					},
					{
						"id": 3369,
						"region_name": "Beqaata En Nahr - بقعاتا النهر"
					},
					{
						"id": 3370,
						"region_name": "Bhannes - بحنس"
					},
					{
						"id": 3371,
						"region_name": "Bhersaf - بحرصاف"
					},
					{
						"id": 3372,
						"region_name": "Biaqout - بياقوت"
					},
					{
						"id": 3373,
						"region_name": "Bikfayia - بكفيا"
					},
					{
						"id": 3374,
						"region_name": "Bnabil - بنابيل"
					},
					{
						"id": 3375,
						"region_name": "Bolonia - بولونيا"
					},
					{
						"id": 3376,
						"region_name": "Borj Hammoud - برج حمّود"
					},
					{
						"id": 3377,
						"region_name": "Bqennaya - بقنايا"
					},
					{
						"id": 3378,
						"region_name": "Broumana - برمانا المتن"
					},
					{
						"id": 3379,
						"region_name": "Bsalim - بصاليم"
					},
					{
						"id": 3380,
						"region_name": "Bsatine Ain Saade - بساتين عين سعادة"
					},
					{
						"id": 3381,
						"region_name": "Bsifrine - بسفرين"
					},
					{
						"id": 3382,
						"region_name": "Bteghrine - بتغرين"
					},
					{
						"id": 3383,
						"region_name": "Chaouiye - شاوية"
					},
					{
						"id": 3384,
						"region_name": "Chbouq - شبوق"
					},
					{
						"id": 3385,
						"region_name": "Cherchar - شرشار"
					},
					{
						"id": 3386,
						"region_name": "Cherin - شرين"
					},
					{
						"id": 3387,
						"region_name": "Chmis Antelias - شميس أنطلياس"
					},
					{
						"id": 3388,
						"region_name": "Chouaiya - شويا"
					},
					{
						"id": 3389,
						"region_name": "Choueir - شوير"
					},
					{
						"id": 3390,
						"region_name": "Dahr Broummana - ضهر برمانا"
					},
					{
						"id": 3391,
						"region_name": "Dahr el Bacheq - ضهر الباشق"
					},
					{
						"id": 3392,
						"region_name": "Dahr el Hossein - ضهر الحسين المتن"
					},
					{
						"id": 3393,
						"region_name": "Dahr Es Souane Meten - ضهر الصوان المتن"
					},
					{
						"id": 3394,
						"region_name": "Daoura - دورة "
					},
					{
						"id": 3395,
						"region_name": "Daychouniye - ديشونية"
					},
					{
						"id": 3396,
						"region_name": "Dbaiye - ضبية"
					},
					{
						"id": 3397,
						"region_name": "Deir Chamra - دير شمرا"
					},
					{
						"id": 3398,
						"region_name": "Deir el Qalaa - دير القلعة"
					},
					{
						"id": 3399,
						"region_name": "Deir es Salib - دير الصليب"
					},
					{
						"id": 3400,
						"region_name": "Deir Ma Yohanna El Khenchara - ديرمار يوحنا الخنشارة"
					},
					{
						"id": 3401,
						"region_name": "Deir Mar Chaaya - دير مار شعيا"
					},
					{
						"id": 3402,
						"region_name": "Deir Mar Jergos - دير مار جرجس"
					},
					{
						"id": 3403,
						"region_name": "Deir Mar Simaane - دير مار سمعان"
					},
					{
						"id": 3404,
						"region_name": "Deir Tamich - دير طاميش"
					},
					{
						"id": 3405,
						"region_name": "Deir-er-Raai es Saleh - دير الراعي الصالح"
					},
					{
						"id": 3406,
						"region_name": "Dekouane - دكوانة"
					},
					{
						"id": 3407,
						"region_name": "Delb - دلب"
					},
					{
						"id": 3408,
						"region_name": "Dhour Ech Choueir - ضهور الشوير"
					},
					{
						"id": 3409,
						"region_name": "Dik El Mehdi - ديك المحدي"
					},
					{
						"id": 3410,
						"region_name": "Douar - دوار"
					},
					{
						"id": 3411,
						"region_name": "Ej Jouaniye - الجوانية"
					},
					{
						"id": 3412,
						"region_name": "El Borj El Khenchara - برج الخنشارة"
					},
					{
						"id": 3413,
						"region_name": "El Braij Qornet Chahouane - بريج قرنة شهوان"
					},
					{
						"id": 3414,
						"region_name": "El Firdaous - فردوس"
					},
					{
						"id": 3415,
						"region_name": "El Habach - حباش"
					},
					{
						"id": 3416,
						"region_name": "El Loueize - لويزة نقاش"
					},
					{
						"id": 3417,
						"region_name": "El Machrah - مشرح "
					},
					{
						"id": 3418,
						"region_name": "El Manazil - منازل"
					},
					{
						"id": 3419,
						"region_name": "El Qalaa - قلعة"
					},
					{
						"id": 3420,
						"region_name": "El Qalaa Sin el fil - قلعة سن الفيل"
					},
					{
						"id": 3421,
						"region_name": "En Naas - نعص"
					},
					{
						"id": 3422,
						"region_name": "En Nabaa - النبعه"
					},
					{
						"id": 3423,
						"region_name": "Er Rabie - رابيه"
					},
					{
						"id": 3424,
						"region_name": "Er Raouda - روضة"
					},
					{
						"id": 3425,
						"region_name": "Er Rouaisse - رويسة برمانا"
					},
					{
						"id": 3426,
						"region_name": "Et Tabche - طبشة"
					},
					{
						"id": 3427,
						"region_name": "Fanar - الفنار"
					},
					{
						"id": 3428,
						"region_name": "Fanar ej Jdid - فنار الجديدة"
					},
					{
						"id": 3429,
						"region_name": "Faouar - فوار"
					},
					{
						"id": 3430,
						"region_name": "Faouar el meten - فوار المتن"
					},
					{
						"id": 3431,
						"region_name": "Fraike - فريكه"
					},
					{
						"id": 3432,
						"region_name": "Ghabe - غابة المسقى"
					},
					{
						"id": 3433,
						"region_name": "Ghabet Bolonia - غابة بولونيا"
					},
					{
						"id": 3434,
						"region_name": "Gharouch - غاروش"
					},
					{
						"id": 3435,
						"region_name": "Hamlaya - حملايا"
					},
					{
						"id": 3436,
						"region_name": "Haret el Bellane - حارة البلان"
					},
					{
						"id": 3437,
						"region_name": "Haret el Cheikh - حارة الشيخ"
					},
					{
						"id": 3438,
						"region_name": "Haret El Ghouarni - حارة الغوارني"
					},
					{
						"id": 3439,
						"region_name": "Hbous - حبوس"
					},
					{
						"id": 3440,
						"region_name": "Horch Tabet - حرش تابت"
					},
					{
						"id": 3441,
						"region_name": "Jall Ed Dib - جل الديب"
					},
					{
						"id": 3442,
						"region_name": "Jdaide el Meten - جديدة المتن"
					},
					{
						"id": 3443,
						"region_name": "Jisr El Bacha - جسر الباشا"
					},
					{
						"id": 3444,
						"region_name": "Jouar - جوار المتن"
					},
					{
						"id": 3445,
						"region_name": "Jouret El Ballout - جورة البلوط"
					},
					{
						"id": 3446,
						"region_name": "Kafra El Meten - كفرا المتن"
					},
					{
						"id": 3447,
						"region_name": "Kfar Aaqab - كفرعقاب"
					},
					{
						"id": 3448,
						"region_name": "Kfartay - كفرتيه "
					},
					{
						"id": 3449,
						"region_name": "Khalle - خلة المتين"
					},
					{
						"id": 3450,
						"region_name": "Khenchara - خنشارة"
					},
					{
						"id": 3451,
						"region_name": "Khirbit el Aadass - خربة العدس"
					},
					{
						"id": 3452,
						"region_name": "Ksayfiyeh - قصيفية"
					},
					{
						"id": 3453,
						"region_name": "Machraa - مشرع"
					},
					{
						"id": 3454,
						"region_name": "Majdel Tarchich - مجدل ترشيش"
					},
					{
						"id": 3455,
						"region_name": "Majzoub - مجذوب"
					},
					{
						"id": 3456,
						"region_name": "Makhadet Nahr el Kalb - مخاضة نهر الكلب"
					},
					{
						"id": 3457,
						"region_name": "Mamboukh - الممبوخ"
					},
					{
						"id": 3458,
						"region_name": "Mansouriye - منصورية"
					},
					{
						"id": 3459,
						"region_name": "Mar Aabda el Mshammar - دير مار عبدا المشمر"
					},
					{
						"id": 3460,
						"region_name": "Mar Boutrous Karm El- Tine - مار بطرس كرم التين"
					},
					{
						"id": 3461,
						"region_name": "Mar Mkhayel Bnabil - مار مخايل بنابيل"
					},
					{
						"id": 3462,
						"region_name": "Mar Mousa Ed Daouar - مار موسى الدوار"
					},
					{
						"id": 3463,
						"region_name": "Mar Roukoz -  مار روكز"
					},
					{
						"id": 3464,
						"region_name": "Marj Baskinta - مرج بسكنتا"
					},
					{
						"id": 3465,
						"region_name": "Marjaba - مرجبا"
					},
					{
						"id": 3466,
						"region_name": "Masqa - المسقى"
					},
					{
						"id": 3467,
						"region_name": "Mayasse - مياسة"
					},
					{
						"id": 3468,
						"region_name": "Mazraat Beit Ech Chaar - مزرعة بيت الشعّار"
					},
					{
						"id": 3469,
						"region_name": "Mazraat Deir Aaoukar - مزرعة دير عوكر"
					},
					{
						"id": 3470,
						"region_name": "Mazraat El Hdaira - مزرعة الحضيرة"
					},
					{
						"id": 3471,
						"region_name": "Mazraat Yachoua - مزرعة يشوع"
					},
					{
						"id": 3472,
						"region_name": "Mchaymcheh - مشيمشة"
					},
					{
						"id": 3473,
						"region_name": "Mcheikha - مشيخا"
					},
					{
						"id": 3474,
						"region_name": "Mezher - مزهر"
					},
					{
						"id": 3475,
						"region_name": "Mhaydse - محيدثة المتن"
					},
					{
						"id": 3476,
						"region_name": "Mkalless - مكلس"
					},
					{
						"id": 3477,
						"region_name": "Montiverdi - منتفردي"
					},
					{
						"id": 3478,
						"region_name": "Mountazah - منتزه"
					},
					{
						"id": 3479,
						"region_name": "Mrah Ghanem - مراح غانم"
					},
					{
						"id": 3480,
						"region_name": "Mrouj - المروج"
					},
					{
						"id": 3481,
						"region_name": "Mtaileb - مطيلب"
					},
					{
						"id": 3482,
						"region_name": "Mtein - المتين"
					},
					{
						"id": 3483,
						"region_name": "Mzakke - مزكة"
					},
					{
						"id": 3484,
						"region_name": "Nabay - نابيه"
					},
					{
						"id": 3485,
						"region_name": "Naqqach - نقاش"
					},
					{
						"id": 3486,
						"region_name": "Ouadi Chahine - وادي شاهين"
					},
					{
						"id": 3487,
						"region_name": "Ouadi ed Delb - وادي الدلب"
					},
					{
						"id": 3488,
						"region_name": "Ouadi El Amine - وادي الامين"
					},
					{
						"id": 3489,
						"region_name": "Ouadi El Karm - وادي الكرم المتن"
					},
					{
						"id": 3490,
						"region_name": "Ouata El Mrouj - وطى المروج"
					},
					{
						"id": 3491,
						"region_name": "Qaaqour - القعقور"
					},
					{
						"id": 3492,
						"region_name": "Qanat Bakich - قناة باكيش"
					},
					{
						"id": 3493,
						"region_name": "Qennabet Broummana - قنابة برمانا"
					},
					{
						"id": 3494,
						"region_name": "Qennabet Salima - قنابة صليما"
					},
					{
						"id": 3495,
						"region_name": "Qnaitra El Meten - قنيطرة المتن"
					},
					{
						"id": 3496,
						"region_name": "Qornet Chahouane - قرنة شهوان"
					},
					{
						"id": 3497,
						"region_name": "Qornet El Hamra - قرنة الحمرا"
					},
					{
						"id": 3498,
						"region_name": "Qottara Aintouret - قطارة عينطورة المتن"
					},
					{
						"id": 3499,
						"region_name": "Raboue - ربوة"
					},
					{
						"id": 3500,
						"region_name": "Ramie - رامية "
					},
					{
						"id": 3501,
						"region_name": "Raqayeq - رقايق"
					},
					{
						"id": 3502,
						"region_name": "Ras el Jdaide - راس الجديدة"
					},
					{
						"id": 3503,
						"region_name": "Roumie - روميه المتن"
					},
					{
						"id": 3504,
						"region_name": "Sabtiye - سبتيه"
					},
					{
						"id": 3505,
						"region_name": "Sad el Baouchriye - سد البوشرية"
					},
					{
						"id": 3506,
						"region_name": "Sannine - صنين"
					},
					{
						"id": 3507,
						"region_name": "Saqeit El Misk - ساقية المسك"
					},
					{
						"id": 3508,
						"region_name": "Sfaile - سفيلة"
					},
					{
						"id": 3509,
						"region_name": "sfaileh Bikfaya - سفيلة بكفيا"
					},
					{
						"id": 3510,
						"region_name": "Sijn ej Jdid - سجن الجديد"
					},
					{
						"id": 3511,
						"region_name": "Sinn el Fil - سن الفيل"
					},
					{
						"id": 3512,
						"region_name": "Tall ez Zaatar - تل الزعتر"
					},
					{
						"id": 3513,
						"region_name": "Wata Amaret Chalhoub - الوطى عمارة شلهوب"
					},
					{
						"id": 3514,
						"region_name": "Zaaitriye - زعيتريه"
					},
					{
						"id": 3515,
						"region_name": "Zabbougha - زبوغا"
					},
					{
						"id": 3516,
						"region_name": "Zaghrine El Meten - زغرين المتن"
					},
					{
						"id": 3517,
						"region_name": "Zahriye El Meten - زاهرية المتن"
					},
					{
						"id": 3518,
						"region_name": "Zalqa - الزلقا"
					},
					{
						"id": 3519,
						"region_name": "Zaraaoun - زرعون"
					},
					{
						"id": 3520,
						"region_name": "Zikrit - زكريت"
					},
					{
						"id": 3521,
						"region_name": "Zouk el Kharab - ذوق الخراب"
					}
				]
			},
			{
				"id": 16,
				"DistrictDescription": "Jbeil - جبيل",
				"Cities": [
					{
						"id": 3522,
						"region_name": "Aabeidat - عبيدات"
					},
					{
						"id": 3523,
						"region_name": "Aaboud - عبود"
					},
					{
						"id": 3524,
						"region_name": "Aafs jbeil - عفص "
					},
					{
						"id": 3525,
						"region_name": "Aainat - عينات"
					},
					{
						"id": 3526,
						"region_name": "Aalita - عاليتا"
					},
					{
						"id": 3527,
						"region_name": "Aamchit - عمشيت"
					},
					{
						"id": 3528,
						"region_name": "Aannaya - عنَايا"
					},
					{
						"id": 3529,
						"region_name": "Aaouaini - عويني"
					},
					{
						"id": 3530,
						"region_name": "Aaqoura - عاقورة"
					},
					{
						"id": 3531,
						"region_name": "Aarab el Lahib - عرب اللهيب"
					},
					{
						"id": 3532,
						"region_name": "Aarasta - عرستا"
					},
					{
						"id": 3533,
						"region_name": "Aayoun el Aalaq - عيون العلاق"
					},
					{
						"id": 3534,
						"region_name": "Adonis - ادونيس"
					},
					{
						"id": 3535,
						"region_name": "Afqa - افقا"
					},
					{
						"id": 3536,
						"region_name": "Ain Bqechqoch - عين بقشقش"
					},
					{
						"id": 3537,
						"region_name": "Ain ed Deir - عين الدير"
					},
					{
						"id": 3538,
						"region_name": "Ain Ed Delbe Jbeil - عين الدلبة جبيل"
					},
					{
						"id": 3539,
						"region_name": "Ain el Ghouaibe - عين الغويبة"
					},
					{
						"id": 3540,
						"region_name": "Ain el Qadah - عين القدح"
					},
					{
						"id": 3541,
						"region_name": "Ain es Salib - عين الصليب"
					},
					{
						"id": 3542,
						"region_name": "Ain Ghalboun - عين غلبون"
					},
					{
						"id": 3543,
						"region_name": "Ain Jrain - عين جرين"
					},
					{
						"id": 3544,
						"region_name": "Ain Kfaa - عين كفاع"
					},
					{
						"id": 3545,
						"region_name": "Almat ech Chemaliye - علمات الشمالية"
					},
					{
						"id": 3546,
						"region_name": "Almat Ej Jnoubiye - علمات الجنوبية"
					},
					{
						"id": 3547,
						"region_name": "Aydamoun - عيدامون"
					},
					{
						"id": 3548,
						"region_name": "Baachta - بعشتا"
					},
					{
						"id": 3549,
						"region_name": "Balhoss - بلحص"
					},
					{
						"id": 3550,
						"region_name": "Barbara - بربارة"
					},
					{
						"id": 3551,
						"region_name": "Barij - بريج"
					},
					{
						"id": 3552,
						"region_name": "Bchelli - بشلّلي"
					},
					{
						"id": 3553,
						"region_name": "Bechtelida - بشتليدا"
					},
					{
						"id": 3554,
						"region_name": "Behdaidat - بحديدات"
					},
					{
						"id": 3555,
						"region_name": "Beit El Boume - بيت البومة"
					},
					{
						"id": 3556,
						"region_name": "Beit Hbaq - بيت حبّاق"
					},
					{
						"id": 3557,
						"region_name": "Bejje - بجه"
					},
					{
						"id": 3558,
						"region_name": "Bekhaaz - بخعاز"
					},
					{
						"id": 3559,
						"region_name": "Bekouane - بكونا"
					},
					{
						"id": 3560,
						"region_name": "Bentaael - بنتاعل"
					},
					{
						"id": 3561,
						"region_name": "Berket Hejoula - بركة حجولا"
					},
					{
						"id": 3562,
						"region_name": "Beziyoun - بزيون"
					},
					{
						"id": 3563,
						"region_name": "Bhassis - بحسيس"
					},
					{
						"id": 3564,
						"region_name": "Bir El Hait - بير الهيت"
					},
					{
						"id": 3565,
						"region_name": "Bkourkaz - بكركز"
					},
					{
						"id": 3566,
						"region_name": "Blat - بلاط"
					},
					{
						"id": 3567,
						"region_name": "Bmehrine - بمهرين"
					},
					{
						"id": 3568,
						"region_name": "Boaatara - بعتارا"
					},
					{
						"id": 3569,
						"region_name": "Bqarta - بقرتا"
					},
					{
						"id": 3570,
						"region_name": "Broqta - برقتا"
					},
					{
						"id": 3571,
						"region_name": "Chaabiyat el Fawqa - شعبيات الفوقا"
					},
					{
						"id": 3572,
						"region_name": "Chaloumas - شلوماس"
					},
					{
						"id": 3573,
						"region_name": "Chamat - شامات"
					},
					{
						"id": 3574,
						"region_name": "Charbine Mazraat Es Siyad - شربين مزرعة السياد"
					},
					{
						"id": 3575,
						"region_name": "Chekhnaya - شخنايا"
					},
					{
						"id": 3576,
						"region_name": "Chikhane - شيخان"
					},
					{
						"id": 3577,
						"region_name": "Chmout - شموت"
					},
					{
						"id": 3578,
						"region_name": "Chouata - شواتا"
					},
					{
						"id": 3579,
						"region_name": "Daouret Edde Jbayl - دورة إده جبيل"
					},
					{
						"id": 3580,
						"region_name": "Deir el Qattara - دير القطارة"
					},
					{
						"id": 3581,
						"region_name": "Deir Mar Maroun - دير مار مارون"
					},
					{
						"id": 3582,
						"region_name": "Deir Mar Sarkis - دير مار سركيس"
					},
					{
						"id": 3583,
						"region_name": "Dmalsa - دملصا"
					},
					{
						"id": 3584,
						"region_name": "Douar Bou chahine - دوار بو شاهين"
					},
					{
						"id": 3585,
						"region_name": "Doueir - الدوير"
					},
					{
						"id": 3586,
						"region_name": "Ed Darje - درجه"
					},
					{
						"id": 3587,
						"region_name": "Edde - إده"
					},
					{
						"id": 3588,
						"region_name": "Ehmej - اهمج"
					},
					{
						"id": 3589,
						"region_name": "El Aambra - العمبرا"
					},
					{
						"id": 3590,
						"region_name": "El Aarich - عريش"
					},
					{
						"id": 3591,
						"region_name": "El Barraniye - برانية"
					},
					{
						"id": 3592,
						"region_name": "El Bhayri - بحيري"
					},
					{
						"id": 3593,
						"region_name": "El Biyade - البياضة جبيل"
					},
					{
						"id": 3594,
						"region_name": "El Borj - برج"
					},
					{
						"id": 3595,
						"region_name": "El Boustane - بستان يانوح"
					},
					{
						"id": 3596,
						"region_name": "El Houssoun - حصون"
					},
					{
						"id": 3597,
						"region_name": "El Hraifat - الحريفات"
					},
					{
						"id": 3598,
						"region_name": "El Marj Lassa - مرج لاسا"
					},
					{
						"id": 3599,
						"region_name": "El Mtolle - مطله جبيل"
					},
					{
						"id": 3600,
						"region_name": "El Owaynate - عوينات "
					},
					{
						"id": 3601,
						"region_name": "El-Harf - حرف قرطبون"
					},
					{
						"id": 3602,
						"region_name": "Ernaya - ارنايا"
					},
					{
						"id": 3603,
						"region_name": "Es Saqi - سقي "
					},
					{
						"id": 3604,
						"region_name": "Es Sare - سار"
					},
					{
						"id": 3605,
						"region_name": "Fatre - فتري"
					},
					{
						"id": 3606,
						"region_name": "Ferhet - فرحت"
					},
					{
						"id": 3607,
						"region_name": "Fghal - فغال"
					},
					{
						"id": 3608,
						"region_name": "Fidar - الفيدار"
					},
					{
						"id": 3609,
						"region_name": "Fidar el Faouqa - فدار الفوقا"
					},
					{
						"id": 3610,
						"region_name": "Fidar Et Tahta - فدار التحتا"
					},
					{
						"id": 3611,
						"region_name": "Frat - فرات"
					},
					{
						"id": 3612,
						"region_name": "Ghabate - غابات"
					},
					{
						"id": 3613,
						"region_name": "Ghabline - غبالين"
					},
					{
						"id": 3614,
						"region_name": "Ghalboun - غلبون"
					},
					{
						"id": 3615,
						"region_name": "Gharfine - غرفين"
					},
					{
						"id": 3616,
						"region_name": "Gharzouz - غرزوز"
					},
					{
						"id": 3617,
						"region_name": "Habboub - حبوب"
					},
					{
						"id": 3618,
						"region_name": "Habil - هابيل"
					},
					{
						"id": 3619,
						"region_name": "Halat - حالات"
					},
					{
						"id": 3620,
						"region_name": "Haqel - حقل"
					},
					{
						"id": 3621,
						"region_name": "Haqlet et Tine - حقلة التينة"
					},
					{
						"id": 3622,
						"region_name": "Hara Jbeil - حارة"
					},
					{
						"id": 3623,
						"region_name": "Harsha - هرشا"
					},
					{
						"id": 3624,
						"region_name": "Hay El Aarbe - حي العربي"
					},
					{
						"id": 3625,
						"region_name": "Hay El Baalbakiye - حي البعلبكية"
					},
					{
						"id": 3626,
						"region_name": "Hbalin - حبالين"
					},
					{
						"id": 3627,
						"region_name": "Hedayne - هدينة"
					},
					{
						"id": 3628,
						"region_name": "Heloue - حلوة "
					},
					{
						"id": 3629,
						"region_name": "Hima Er Rehbane - حمى الرهبان"
					},
					{
						"id": 3630,
						"region_name": "Hima Mar Maroun Aannaya - حمى مار مارون عنَايا"
					},
					{
						"id": 3631,
						"region_name": "Hjoula - حجولا"
					},
					{
						"id": 3632,
						"region_name": "Hosn Aar - حصن العر"
					},
					{
						"id": 3633,
						"region_name": "Hosna - حصنا"
					},
					{
						"id": 3634,
						"region_name": "Hosrayel - حصرايل"
					},
					{
						"id": 3635,
						"region_name": "Hourata - حوراتا"
					},
					{
						"id": 3636,
						"region_name": "Hrazmin - حرازمين"
					},
					{
						"id": 3637,
						"region_name": "Hsarat - حصارات"
					},
					{
						"id": 3638,
						"region_name": "Jaj - جاج"
					},
					{
						"id": 3639,
						"region_name": "Janne - جنة"
					},
					{
						"id": 3640,
						"region_name": "Jbail - جبيل"
					},
					{
						"id": 3641,
						"region_name": "Jdayel - جدايل"
					},
					{
						"id": 3642,
						"region_name": "Jengel - جونجل"
					},
					{
						"id": 3643,
						"region_name": "Jlaisse - جليسه"
					},
					{
						"id": 3644,
						"region_name": "Jouret El Qattine - جورة القطين"
					},
					{
						"id": 3645,
						"region_name": "Jrebta Jbeil - جربتا جبيل"
					},
					{
						"id": 3646,
						"region_name": "Kafr - كفر"
					},
					{
						"id": 3647,
						"region_name": "Kelesh - كلش"
					},
					{
						"id": 3648,
						"region_name": "Kfar Chakha - كفر شخي"
					},
					{
						"id": 3649,
						"region_name": "Kfar Chilli - كفر شلي"
					},
					{
						"id": 3650,
						"region_name": "Kfar Hitta - كفر حتى"
					},
					{
						"id": 3651,
						"region_name": "Kfar Kahli - كفر كخلة"
					},
					{
						"id": 3652,
						"region_name": "Kfar Kidda - كفر كده"
					},
					{
						"id": 3653,
						"region_name": "Kfar Mashoun - كفر مسحون"
					},
					{
						"id": 3654,
						"region_name": "Kfar Qaouass - كفر قواص"
					},
					{
						"id": 3655,
						"region_name": "Kfar Sal - كفر سالا"
					},
					{
						"id": 3656,
						"region_name": "Kfar Shabbouh - كفر شبوح"
					},
					{
						"id": 3657,
						"region_name": "Kfar Zbouna - كفرزبونا"
					},
					{
						"id": 3658,
						"region_name": "Kfarbaal   - كفر بعال"
					},
					{
						"id": 3659,
						"region_name": "Kfoun - كفون"
					},
					{
						"id": 3660,
						"region_name": "Khaab - خعب"
					},
					{
						"id": 3661,
						"region_name": "Khaabiya - خاعبية"
					},
					{
						"id": 3662,
						"region_name": "Kharbe - خاربة جبيل"
					},
					{
						"id": 3663,
						"region_name": "Kour El Hooua - كور الهوا"
					},
					{
						"id": 3664,
						"region_name": "Laqlouq - اللقلوق"
					},
					{
						"id": 3665,
						"region_name": "Lassa - لاسا"
					},
					{
						"id": 3666,
						"region_name": "Lehfed - لحفد"
					},
					{
						"id": 3667,
						"region_name": "Maad - معاد"
					},
					{
						"id": 3668,
						"region_name": "Maaytiq - معيتيق"
					},
					{
						"id": 3669,
						"region_name": "Mahmarat Bejje - محمرة بجّه"
					},
					{
						"id": 3670,
						"region_name": "Maifouq - ميفوق"
					},
					{
						"id": 3671,
						"region_name": "Majdel - المجدل"
					},
					{
						"id": 3672,
						"region_name": "Malhoun - ملحون"
					},
					{
						"id": 3673,
						"region_name": "Mar Meqna - مار مقانة"
					},
					{
						"id": 3674,
						"region_name": "Marbaa - مربعه"
					},
					{
						"id": 3675,
						"region_name": "Marj Mokhtara - مرج مختارة"
					},
					{
						"id": 3676,
						"region_name": "Mazraa Lassa - مزرعة لاسا"
					},
					{
						"id": 3677,
						"region_name": "Mazraat ej Jmaiyel - مزرعة الجميل"
					},
					{
						"id": 3678,
						"region_name": "Mazraat el Hajj Khalil - مزرعة الحاج خليل"
					},
					{
						"id": 3679,
						"region_name": "Mazraat El Maadene - مزرعة المعادن"
					},
					{
						"id": 3680,
						"region_name": "Mazraat Es Siyad - مزرعة السياد"
					},
					{
						"id": 3681,
						"region_name": "Mdamit - مداميت"
					},
					{
						"id": 3682,
						"region_name": "Mechane - مشان"
					},
					{
						"id": 3683,
						"region_name": "Mechhellene - مشحلان"
					},
					{
						"id": 3684,
						"region_name": "MechMech Jbeil - مشمش جبيل"
					},
					{
						"id": 3685,
						"region_name": "Meftah es Sellom - مفتاح السلم"
					},
					{
						"id": 3686,
						"region_name": "Mestita - مستيتا"
					},
					{
						"id": 3687,
						"region_name": "Mghaira - مغيره"
					},
					{
						"id": 3688,
						"region_name": "Mnaitra - منيطرة"
					},
					{
						"id": 3689,
						"region_name": "Monsef - المنصف"
					},
					{
						"id": 3690,
						"region_name": "Mouftah el Mir - مفتاح المير"
					},
					{
						"id": 3691,
						"region_name": "Moukhada - مخاضة"
					},
					{
						"id": 3692,
						"region_name": "Mzarib - المزاريب"
					},
					{
						"id": 3693,
						"region_name": "Nabaa Tourzaiya - نبع طورزيا"
					},
					{
						"id": 3694,
						"region_name": "Nahrh Ibrahim - نهر ابراهيم"
					},
					{
						"id": 3695,
						"region_name": "Naqour - نقور"
					},
					{
						"id": 3696,
						"region_name": "Ouata El Bane - وطى البان"
					},
					{
						"id": 3697,
						"region_name": "Ouata el bourj - وطى البرج"
					},
					{
						"id": 3698,
						"region_name": "Ouata el Khirbe - وطى الخربة"
					},
					{
						"id": 3699,
						"region_name": "Qartaba - قرطبا"
					},
					{
						"id": 3700,
						"region_name": "Qartaboun - قرطبون"
					},
					{
						"id": 3701,
						"region_name": "Qass - القص"
					},
					{
						"id": 3702,
						"region_name": "Qateaa - القاطعة"
					},
					{
						"id": 3703,
						"region_name": "Qattara - قطارة"
					},
					{
						"id": 3704,
						"region_name": "Qehmez - قهمز"
					},
					{
						"id": 3705,
						"region_name": "Qerqraiya - قرقريا"
					},
					{
						"id": 3706,
						"region_name": "Qeryaqous - قرياقس"
					},
					{
						"id": 3707,
						"region_name": "Ramiet el Hsoun - رامية الحصون"
					},
					{
						"id": 3708,
						"region_name": "Ramout - الراموط"
					},
					{
						"id": 3709,
						"region_name": "Ras Osta - راس اسطا"
					},
					{
						"id": 3710,
						"region_name": "Rihani - ريحانة"
					},
					{
						"id": 3711,
						"region_name": "Rouaiss - رويس"
					},
					{
						"id": 3712,
						"region_name": "Saqi Lassa - سقي لاسا"
					},
					{
						"id": 3713,
						"region_name": "Saqi Rechmaiya - سقي رشميا"
					},
					{
						"id": 3714,
						"region_name": "Saqiet ed Delb - ساقية الدلب"
					},
					{
						"id": 3715,
						"region_name": "Saqiet el Khait - ساقية الخيط"
					},
					{
						"id": 3716,
						"region_name": "Sariita - سرعيتا"
					},
					{
						"id": 3717,
						"region_name": "Sbail - سبايل"
					},
					{
						"id": 3718,
						"region_name": "Sebrine - سربين"
					},
					{
						"id": 3719,
						"region_name": "Serghol - سرغل"
					},
					{
						"id": 3720,
						"region_name": "Sinnawr - سنور"
					},
					{
						"id": 3721,
						"region_name": "Sirine - سيران"
					},
					{
						"id": 3722,
						"region_name": "Slaiyeb Ghalboun - صلايب غلبون"
					},
					{
						"id": 3723,
						"region_name": "Souane - صوانة "
					},
					{
						"id": 3724,
						"region_name": "Souq el Firi - سوق الفارة"
					},
					{
						"id": 3725,
						"region_name": "Taht el Qalaa - تحت القلعة"
					},
					{
						"id": 3726,
						"region_name": "Talbita - تلبيتا"
					},
					{
						"id": 3727,
						"region_name": "Tartij - ترتج"
					},
					{
						"id": 3728,
						"region_name": "Tedmor - تدمر"
					},
					{
						"id": 3729,
						"region_name": "Terouil - ترويل"
					},
					{
						"id": 3730,
						"region_name": "Tourzaiya - طورزيا"
					},
					{
						"id": 3731,
						"region_name": "Wadi el Kalb - وادي الكلب"
					},
					{
						"id": 3732,
						"region_name": "Yanouh - يانوح"
					},
					{
						"id": 3733,
						"region_name": "Zaayber - زعيبر"
					},
					{
						"id": 3734,
						"region_name": "Zebdine - زبدين"
					},
					{
						"id": 3735,
						"region_name": "Zelhmaya - زلحمايا"
					},
					{
						"id": 3736,
						"region_name": "Zmar - زمار"
					}
				]
			},
			{
				"id": 17,
				"DistrictDescription": "Kesrwane -  كسروان",
				"Cities": [
					{
						"id": 3737,
						"region_name": "Aabri - عبري"
					},
					{
						"id": 3738,
						"region_name": "Aachqout - عشقوت"
					},
					{
						"id": 3739,
						"region_name": "Aafs kesrwane - عفص كسروان"
					},
					{
						"id": 3740,
						"region_name": "Aajaltoun - عجلتون"
					},
					{
						"id": 3741,
						"region_name": "Aarabe Ghosta - عربة غوسطا"
					},
					{
						"id": 3742,
						"region_name": "Aaramoun Kesrwane - عرمون كسروان"
					},
					{
						"id": 3743,
						"region_name": "Aayoun es Simane - عيون السيمان"
					},
					{
						"id": 3744,
						"region_name": "Aazrane - عزرانة"
					},
					{
						"id": 3745,
						"region_name": "Adonis Kesserwan - أدونيس كسروان"
					},
					{
						"id": 3746,
						"region_name": "Aghbe - إغبة"
					},
					{
						"id": 3747,
						"region_name": "Ain Abaal - عين ابعل"
					},
					{
						"id": 3748,
						"region_name": "Ain Ed Delbe - عين الدلبة"
					},
					{
						"id": 3749,
						"region_name": "Ain Ej Jorn - عين الجرن"
					},
					{
						"id": 3750,
						"region_name": "Ain Er Rihane - عين الريحان"
					},
					{
						"id": 3751,
						"region_name": "Ain Et Tannour - عين التنور"
					},
					{
						"id": 3752,
						"region_name": "Ain Jouaiya - عين جويا"
					},
					{
						"id": 3753,
						"region_name": "Aintoura - عينطورة"
					},
					{
						"id": 3754,
						"region_name": "Ayn Es Safra - عين الصفرا"
					},
					{
						"id": 3755,
						"region_name": "Bahhara - بحارة"
					},
					{
						"id": 3756,
						"region_name": "Balloune - بلونة"
					},
					{
						"id": 3757,
						"region_name": "Batha - بطحا"
					},
					{
						"id": 3758,
						"region_name": "Bayader Fatqa - بيادر فتقا"
					},
					{
						"id": 3759,
						"region_name": "Beit Eid - بيت عيد"
					},
					{
						"id": 3760,
						"region_name": "Beit El Kreidi - بيت كريدي"
					},
					{
						"id": 3761,
						"region_name": "Beit Khachbou - بيت خشبو"
					},
					{
						"id": 3762,
						"region_name": "Beqaatet Aachqout - بقعاتة عشقوت"
					},
					{
						"id": 3763,
						"region_name": "Biqaatet Kannane - بقعاتة كنعان"
					},
					{
						"id": 3764,
						"region_name": "Bizhel - بزحل"
					},
					{
						"id": 3765,
						"region_name": "Bkirke - بكركي"
					},
					{
						"id": 3766,
						"region_name": "Blat Mazraat Kfar Dibiane - بلاط كفر ذبيان"
					},
					{
						"id": 3767,
						"region_name": "Bouar - بوار"
					},
					{
						"id": 3768,
						"region_name": "Bqaatouta - بقعتوتا"
					},
					{
						"id": 3769,
						"region_name": "Bqaq Ed Dine - بقاق الدين"
					},
					{
						"id": 3770,
						"region_name": "Broummana Kfar Dibian - برمانا كفر ذبيان"
					},
					{
						"id": 3771,
						"region_name": "Bzoummar - بزمار"
					},
					{
						"id": 3772,
						"region_name": "Chaab - شعب"
					},
					{
						"id": 3773,
						"region_name": "Chahtoul - شحتول"
					},
					{
						"id": 3774,
						"region_name": "Chehhara - شحارة"
					},
					{
						"id": 3775,
						"region_name": "Chnanaair - شننعير"
					},
					{
						"id": 3776,
						"region_name": "Chouaya - شويا يحشوش"
					},
					{
						"id": 3777,
						"region_name": "Chouene - شوان"
					},
					{
						"id": 3778,
						"region_name": "Christ Roi - يسوع الملك"
					},
					{
						"id": 3779,
						"region_name": "Daasse - دعسه"
					},
					{
						"id": 3780,
						"region_name": "Dahr Badris - ضهر بدريس"
					},
					{
						"id": 3781,
						"region_name": "Dahr el Qattine - ضهر القطين"
					},
					{
						"id": 3782,
						"region_name": "Daraaoun - درعون"
					},
					{
						"id": 3783,
						"region_name": "Darayia Kesrwane - داريا كسروان"
					},
					{
						"id": 3784,
						"region_name": "Dayr Saydet el Haqle - دير سيدة الحقلة"
					},
					{
						"id": 3785,
						"region_name": "Deir Baklouche - دير بقلوش"
					},
					{
						"id": 3786,
						"region_name": "Deir Hrach - دير حراش"
					},
					{
						"id": 3787,
						"region_name": "Deir Nisbey - دير نسبيه"
					},
					{
						"id": 3788,
						"region_name": "Deir Ram Bou Daqen - دير رام بو دقن"
					},
					{
						"id": 3789,
						"region_name": "Deir saydet Louaize - دير سيدة اللويزة"
					},
					{
						"id": 3790,
						"region_name": "Deir Ziraaya - دير زرعيا"
					},
					{
						"id": 3791,
						"region_name": "Delbta - دلبتا"
					},
					{
						"id": 3792,
						"region_name": "Deraali - درعليه"
					},
					{
						"id": 3793,
						"region_name": "Dqarine - دقارين"
					},
					{
						"id": 3794,
						"region_name": "Ech Chqif Bqaatouta - شقيف بقعتوتا"
					},
					{
						"id": 3795,
						"region_name": "Ed Dahr - الضهر"
					},
					{
						"id": 3796,
						"region_name": "Ed Dahr Ghbale - ضهر غبالة"
					},
					{
						"id": 3797,
						"region_name": "Ed Daoura Shaile - دورة سهيلة"
					},
					{
						"id": 3798,
						"region_name": "Edma et Dafne - أدما والدفنه"
					},
					{
						"id": 3799,
						"region_name": "Ej Jaayel - جعايل غبالة"
					},
					{
						"id": 3800,
						"region_name": "El Aaqaybe Kesrouane - عقيبة"
					},
					{
						"id": 3801,
						"region_name": "El Aazra et el Aazr - عذره والعذر"
					},
					{
						"id": 3802,
						"region_name": "El Ghouabi - غوابي"
					},
					{
						"id": 3803,
						"region_name": "El Hara Mairouba - حارة ميروبا"
					},
					{
						"id": 3804,
						"region_name": "El Kharayeb - خرايب"
					},
					{
						"id": 3805,
						"region_name": "El Kharayeb Dlebta - الخرايب دلبتا"
					},
					{
						"id": 3806,
						"region_name": "El Maaden - معادن"
					},
					{
						"id": 3807,
						"region_name": "El Marji - مرج"
					},
					{
						"id": 3808,
						"region_name": "El Massiaf - مصيف"
					},
					{
						"id": 3809,
						"region_name": "El Qacha - قشا"
					},
					{
						"id": 3810,
						"region_name": "El Qarqouf - قرقوف"
					},
					{
						"id": 3811,
						"region_name": "Es Salhiye Bezhel - صالحية بزحل"
					},
					{
						"id": 3812,
						"region_name": "Es Shoum Kfar Debiane - شوم كفر ذبيان"
					},
					{
						"id": 3813,
						"region_name": "Es Slayekh - السلايخ"
					},
					{
						"id": 3814,
						"region_name": "Es Souhoum - سوحوم الغينة"
					},
					{
						"id": 3815,
						"region_name": "Esh Shahoute - الشاحوط"
					},
					{
						"id": 3816,
						"region_name": "Et Tarouaa - التروع"
					},
					{
						"id": 3817,
						"region_name": "Ez Zaaytriye - زعيترية"
					},
					{
						"id": 3818,
						"region_name": "Faitroun - فيطرون"
					},
					{
						"id": 3819,
						"region_name": "Fanyoun - فنيوان"
					},
					{
						"id": 3820,
						"region_name": "Faqra - فقرا"
					},
					{
						"id": 3821,
						"region_name": "Faraiya - فاريا"
					},
					{
						"id": 3822,
						"region_name": "Fatqa - فتقا"
					},
					{
						"id": 3823,
						"region_name": "Ftah Ech Chouha - فتاح الشوحا"
					},
					{
						"id": 3824,
						"region_name": "Ftahate - فتاحات جورة الترمس"
					},
					{
						"id": 3825,
						"region_name": "Ghadir jounieh - غادير جونيه"
					},
					{
						"id": 3826,
						"region_name": "Ghadras - غدراس"
					},
					{
						"id": 3827,
						"region_name": "Ghazir - غزير"
					},
					{
						"id": 3828,
						"region_name": "Ghbale - غبالة "
					},
					{
						"id": 3829,
						"region_name": "Ghine - غينة"
					},
					{
						"id": 3830,
						"region_name": "Ghoshraiya - غيشرايه"
					},
					{
						"id": 3831,
						"region_name": "Ghosta - غوسطا"
					},
					{
						"id": 3832,
						"region_name": "Hakl Er Raiyess - حقل الريس"
					},
					{
						"id": 3833,
						"region_name": "Hamassiyat - حماسيات"
					},
					{
						"id": 3834,
						"region_name": "Haret El Mir Zouk Mkayel - حارة المير ذوق مكايل"
					},
					{
						"id": 3835,
						"region_name": "Haret Halane - حارة حلان"
					},
					{
						"id": 3836,
						"region_name": "Haret Sakher - حارة صخر"
					},
					{
						"id": 3837,
						"region_name": "Harharaya - هرهريا"
					},
					{
						"id": 3838,
						"region_name": "Hariqa - حريقة"
					},
					{
						"id": 3839,
						"region_name": "Hariqa Chahtoul - حريق شحتول"
					},
					{
						"id": 3840,
						"region_name": "Harissa - حريصا"
					},
					{
						"id": 3841,
						"region_name": "Hayata - حياطة"
					},
					{
						"id": 3842,
						"region_name": "Hilane - حلان"
					},
					{
						"id": 3843,
						"region_name": "Hrajel - حراجل"
					},
					{
						"id": 3844,
						"region_name": "Hsayn - حصين"
					},
					{
						"id": 3845,
						"region_name": "Jdaidet Ghazir - جديدة غزير"
					},
					{
						"id": 3846,
						"region_name": "Jitta - جعيتا"
					},
					{
						"id": 3847,
						"region_name": "Jouar El Baouechiq - جوار البواشق"
					},
					{
						"id": 3848,
						"region_name": "Jouar El Hachich chahtoul - جوار الحشيش شحتول"
					},
					{
						"id": 3849,
						"region_name": "Jounieh - جونيه"
					},
					{
						"id": 3850,
						"region_name": "Jounieh Kaslik - جونيه كسليك"
					},
					{
						"id": 3851,
						"region_name": "Jouret Bedrane - جورة بدران"
					},
					{
						"id": 3852,
						"region_name": "Jouret Ed Dardour - جورة الدردور"
					},
					{
						"id": 3853,
						"region_name": "Jouret Et Tormoss - جورة الترمس"
					},
					{
						"id": 3854,
						"region_name": "Jouret Mghad - جورة مهاد"
					},
					{
						"id": 3855,
						"region_name": "Kfar Hbab - كفرحباب"
					},
					{
						"id": 3856,
						"region_name": "Kfar Jrif - كفر جريف"
					},
					{
						"id": 3857,
						"region_name": "Kfardibiane - كفر دبيان"
					},
					{
						"id": 3858,
						"region_name": "Kfarshihham - كفر شيحام"
					},
					{
						"id": 3859,
						"region_name": "Kfaryassine - كفر ياسين"
					},
					{
						"id": 3860,
						"region_name": "Kfour - الكفور"
					},
					{
						"id": 3861,
						"region_name": "Khalal - خلال"
					},
					{
						"id": 3862,
						"region_name": "Khdayra - خضيره"
					},
					{
						"id": 3863,
						"region_name": "Ksayer - كساير"
					},
					{
						"id": 3864,
						"region_name": "Maamelteine - معاملتين"
					},
					{
						"id": 3865,
						"region_name": "Maarab - معراب"
					},
					{
						"id": 3866,
						"region_name": "Maaysra - المعيصرة"
					},
					{
						"id": 3867,
						"region_name": "Mairouba - ميروبا"
					},
					{
						"id": 3868,
						"region_name": "Mar Nouhra - مار نهرا"
					},
					{
						"id": 3869,
						"region_name": "Mashhat - مشحات"
					},
					{
						"id": 3870,
						"region_name": "Mazzarat Er Ras - مزرعة الراس"
					},
					{
						"id": 3871,
						"region_name": "Mchaa El Ftouh - مشاع الفتوح"
					},
					{
						"id": 3872,
						"region_name": "Mchaa Faraya - مشاع فاريا"
					},
					{
						"id": 3873,
						"region_name": "Mchaa Kfar Dibian - مشاع كفر ذبيان"
					},
					{
						"id": 3874,
						"region_name": "Mchati - مشاته"
					},
					{
						"id": 3875,
						"region_name": "Mehqane el Mazloum - محقان المظلوم"
					},
					{
						"id": 3876,
						"region_name": "Mghayer - المغاير"
					},
					{
						"id": 3877,
						"region_name": "Mradiye - مرادية"
					},
					{
						"id": 3878,
						"region_name": "Mrah El Mir - مراح المير"
					},
					{
						"id": 3879,
						"region_name": "Mreijet Kesserwan - مريجة كسروان"
					},
					{
						"id": 3880,
						"region_name": "Nabaa el Mghara - نبع المغارة"
					},
					{
						"id": 3881,
						"region_name": "Nahr Ed Dahab - نهر الدهب"
					},
					{
						"id": 3882,
						"region_name": "Nahr El Hussein - نهر الحصين"
					},
					{
						"id": 3883,
						"region_name": "Nahr el Kalb - نهر الكلب"
					},
					{
						"id": 3884,
						"region_name": "Nammoura - النموره"
					},
					{
						"id": 3885,
						"region_name": "Nmoura - نمورة"
					},
					{
						"id": 3886,
						"region_name": "Ouata Ej Jaouz - وطى الجوز"
					},
					{
						"id": 3887,
						"region_name": "Ouata el Laouz - وطى اللوز"
					},
					{
						"id": 3888,
						"region_name": "Ouata Nahr El Kelb - وطى نهر الكلب"
					},
					{
						"id": 3889,
						"region_name": "Ouata Slam - وطى سلام"
					},
					{
						"id": 3890,
						"region_name": "Qalaa Kfardibiane - قلعة كفر ذبيان"
					},
					{
						"id": 3891,
						"region_name": "Qalaat Maarab - قلعة معراب"
					},
					{
						"id": 3892,
						"region_name": "Qarqara - قرقرا"
					},
					{
						"id": 3893,
						"region_name": "Qarqouf - القرقوف"
					},
					{
						"id": 3894,
						"region_name": "Qarsa - قرصة"
					},
					{
						"id": 3895,
						"region_name": "Qattine - القطين"
					},
					{
						"id": 3896,
						"region_name": "Qlaiaat - قليعات كسروان"
					},
					{
						"id": 3897,
						"region_name": "Qmayrze - قميزرة"
					},
					{
						"id": 3898,
						"region_name": "Qouwale - قواله"
					},
					{
						"id": 3899,
						"region_name": "Raashine - رعشين"
					},
					{
						"id": 3900,
						"region_name": "Raifoun - ريفون"
					},
					{
						"id": 3901,
						"region_name": "Ramiet Kfaryasin - رامية كفر ياسين"
					},
					{
						"id": 3902,
						"region_name": "Rihane Kesrwane - ريحان كسروان"
					},
					{
						"id": 3903,
						"region_name": "Safra kesrwane - صفرا كسروان"
					},
					{
						"id": 3904,
						"region_name": "Sahel Aalma - ساحل علما"
					},
					{
						"id": 3905,
						"region_name": "Saidet Bzoummar - دير سيدة بزمار"
					},
					{
						"id": 3906,
						"region_name": "Sarba - صربا"
					},
					{
						"id": 3907,
						"region_name": "Shaile - سهيلة"
					},
					{
						"id": 3908,
						"region_name": "Sirje - سيرجه"
					},
					{
						"id": 3909,
						"region_name": "Snoubar - صنوبر يحشوش"
					},
					{
						"id": 3910,
						"region_name": "Tabarja - طبرجا"
					},
					{
						"id": 3911,
						"region_name": "Talle - تلة"
					},
					{
						"id": 3912,
						"region_name": "Wata Tabriye - وطى طبرية"
					},
					{
						"id": 3913,
						"region_name": "Yahchouch - يحشوش"
					},
					{
						"id": 3914,
						"region_name": "Zaaitra - زعيتره"
					},
					{
						"id": 3915,
						"region_name": "Zayer - زاير"
					},
					{
						"id": 3916,
						"region_name": "Zeitoun - زيتون"
					},
					{
						"id": 3917,
						"region_name": "Zleikat - زليكات"
					},
					{
						"id": 3918,
						"region_name": "Zouk Mosbeh - ذوق مصبح"
					},
					{
						"id": 3919,
						"region_name": "Zouq Mkayel - ذوق مكايل"
					}
				]
			}
		]
	},
	{
		"id": 7,
		"GovernorateDescription": "North - الشمال",
		"Districts": [
			{
				"id": 18,
				"DistrictDescription": "Bcharre - بشري",
				"Cities": [
					{
						"id": 3920,
						"region_name": "Aabdine Bcharre - عبدين بشري"
					},
					{
						"id": 3921,
						"region_name": "Ariz - أرز"
					},
					{
						"id": 3922,
						"region_name": "Bane - بان"
					},
					{
						"id": 3923,
						"region_name": "Barhalioun - برحليون"
					},
					{
						"id": 3924,
						"region_name": "Bazaoun - بزعون"
					},
					{
						"id": 3925,
						"region_name": "Bcharre - بشري"
					},
					{
						"id": 3926,
						"region_name": "Beit Menzer - بيت منذر"
					},
					{
						"id": 3927,
						"region_name": "Billa - بلا"
					},
					{
						"id": 3928,
						"region_name": "Blaouza - بلوزا"
					},
					{
						"id": 3929,
						"region_name": "Bqaa Kafra - بقاع كفرا"
					},
					{
						"id": 3930,
						"region_name": "Bqerqacha - بقرقاشا"
					},
					{
						"id": 3931,
						"region_name": "Braissat - بريسات"
					},
					{
						"id": 3932,
						"region_name": "Chira - شيرا"
					},
					{
						"id": 3933,
						"region_name": "Dimane - ديمان"
					},
					{
						"id": 3934,
						"region_name": "Hadchit - حدشيت"
					},
					{
						"id": 3935,
						"region_name": "Hadet Ej jebbe - حدث الجبه"
					},
					{
						"id": 3936,
						"region_name": "Hasroun - حصرون"
					},
					{
						"id": 3937,
						"region_name": "Kfar Saroun dimane - كفر صارون الديمان"
					},
					{
						"id": 3938,
						"region_name": "Mazraat Assaf - مزرعة عساف"
					},
					{
						"id": 3939,
						"region_name": "Mazraat Beni Saab - مزرعة بني صعب"
					},
					{
						"id": 3940,
						"region_name": "Mchaa ej Joubbeh - مشاع الجبه"
					},
					{
						"id": 3941,
						"region_name": "Moghr El Ahoual - مغر الاحول"
					},
					{
						"id": 3942,
						"region_name": "Qnaiouer - قنيور"
					},
					{
						"id": 3943,
						"region_name": "Qnat - قناة"
					},
					{
						"id": 3944,
						"region_name": "Tourza - طورزا"
					},
					{
						"id": 3945,
						"region_name": "Wadi Qannoubine - وادي قنوبين"
					}
				]
			},
			{
				"id": 19,
				"DistrictDescription": "El Batroun - البترون",
				"Cities": [
					{
						"id": 3946,
						"region_name": "Aabdelle - عبدللي"
					},
					{
						"id": 3947,
						"region_name": "Aabrine - عبرين"
					},
					{
						"id": 3948,
						"region_name": "Aalali - علالي"
					},
					{
						"id": 3949,
						"region_name": "Aaoura - عورا البترون"
					},
					{
						"id": 3950,
						"region_name": "Aartez - عرطز"
					},
					{
						"id": 3951,
						"region_name": "Ain el Batie - عين البطيه"
					},
					{
						"id": 3952,
						"region_name": "Ain el Blat - عين البلاط"
					},
					{
						"id": 3953,
						"region_name": "Ain er Raha - عين الراحا"
					},
					{
						"id": 3954,
						"region_name": "Assia - اسيا"
					},
					{
						"id": 3955,
						"region_name": "Balaa - بلعة"
					},
					{
						"id": 3956,
						"region_name": "Barzakine - برزقين"
					},
					{
						"id": 3957,
						"region_name": "Basbina - بسبينا"
					},
					{
						"id": 3958,
						"region_name": "Batroun - بترون"
					},
					{
						"id": 3959,
						"region_name": "Bcheale - بشعلة"
					},
					{
						"id": 3960,
						"region_name": "Bechtoudar-Aoura - بشتودار"
					},
					{
						"id": 3961,
						"region_name": "Beit Chlala - بيت شلالا"
					},
					{
						"id": 3962,
						"region_name": "Beit Kassab - بيت كسّاب"
					},
					{
						"id": 3963,
						"region_name": "Bijdarfel - بجدرفل"
					},
					{
						"id": 3964,
						"region_name": "Borj - البرج كفر عبيدا"
					},
					{
						"id": 3965,
						"region_name": "Boustane El Aassi - بساتين العصي"
					},
					{
						"id": 3966,
						"region_name": "Bqaiaa et Dahr Abi Yaghi - بقيعة البترون"
					},
					{
						"id": 3967,
						"region_name": "Bqosmaiya - بقسميا"
					},
					{
						"id": 3968,
						"region_name": "Chabtine - شبطين "
					},
					{
						"id": 3969,
						"region_name": "Chatine - شاتين"
					},
					{
						"id": 3970,
						"region_name": "Chawi - شاوي"
					},
					{
						"id": 3971,
						"region_name": "Chekka - شكَا"
					},
					{
						"id": 3972,
						"region_name": "Chnata - شناتا"
					},
					{
						"id": 3973,
						"region_name": "Daael - داعل"
					},
					{
						"id": 3974,
						"region_name": "Dahr Abi Yaghi - ضهر ابي ياغي"
					},
					{
						"id": 3975,
						"region_name": "Dahr el Ghbare - ضهر الغبار"
					},
					{
						"id": 3976,
						"region_name": "Dahr El Qotlob - ضهر القطلب"
					},
					{
						"id": 3977,
						"region_name": "Dahr Mar Risha - ضهر مار ريشا"
					},
					{
						"id": 3978,
						"region_name": "Dawrat - دورة كفر عبيدا"
					},
					{
						"id": 3979,
						"region_name": "Dayr Shouwah - دير شواح"
					},
					{
						"id": 3980,
						"region_name": "Deir Billa - ديربلا"
					},
					{
						"id": 3981,
						"region_name": "Deir Kfifane - دير كفيفان"
					},
					{
						"id": 3982,
						"region_name": "Deir Mar Youhanna Douma - دير مار يوحنا دوما"
					},
					{
						"id": 3983,
						"region_name": "Deir Mar Youssef Jrabta - دير مار يوسف جربتا "
					},
					{
						"id": 3984,
						"region_name": "Derya - دريا "
					},
					{
						"id": 3985,
						"region_name": "Douma - دوما"
					},
					{
						"id": 3986,
						"region_name": "Douq - دوق"
					},
					{
						"id": 3987,
						"region_name": "Edde el Batroun - إده البترون"
					},
					{
						"id": 3988,
						"region_name": "El Aazeqa - عزيقه "
					},
					{
						"id": 3989,
						"region_name": "El Hamra JRANE - حمرا جران"
					},
					{
						"id": 3990,
						"region_name": "El Harf - حرف"
					},
					{
						"id": 3991,
						"region_name": "El Heri - هري"
					},
					{
						"id": 3992,
						"region_name": "El Khraize - خريزه"
					},
					{
						"id": 3993,
						"region_name": "El Midane Kfifane - ميدان كفيفان"
					},
					{
						"id": 3994,
						"region_name": "El Qabaah - قبع كفيفان"
					},
					{
						"id": 3995,
						"region_name": "En Nahriye - نهريه"
					},
					{
						"id": 3996,
						"region_name": "Fadaous - فدعوس"
					},
					{
						"id": 3997,
						"region_name": "Fehta - فحتا"
					},
					{
						"id": 3998,
						"region_name": "Ftahat - فتاحات"
					},
					{
						"id": 3999,
						"region_name": "Ghouma - غوما"
					},
					{
						"id": 4000,
						"region_name": "Hadtoun - حدتون"
					},
					{
						"id": 4001,
						"region_name": "Hai el Birke - حي البركة"
					},
					{
						"id": 4002,
						"region_name": "Halta - حلتا "
					},
					{
						"id": 4003,
						"region_name": "Hamat - حامات"
					},
					{
						"id": 4004,
						"region_name": "Hannouch - حنٌوش"
					},
					{
						"id": 4005,
						"region_name": "Harbouna - حربونا"
					},
					{
						"id": 4006,
						"region_name": "Hardine - حردين"
					},
					{
						"id": 4007,
						"region_name": "Harissa el batroun - حريصا البترون"
					},
					{
						"id": 4008,
						"region_name": "Hazrita - حزريتا"
					},
					{
						"id": 4009,
						"region_name": "Hourata el batroun - حوراتا البترون"
					},
					{
						"id": 4010,
						"region_name": "Hrayek - حرايق"
					},
					{
						"id": 4011,
						"region_name": "Ijdabra - اجدبرا"
					},
					{
						"id": 4012,
						"region_name": "Jibla - جبلا"
					},
					{
						"id": 4013,
						"region_name": "Joundi - جندي"
					},
					{
						"id": 4014,
						"region_name": "Jrane - جران "
					},
					{
						"id": 4015,
						"region_name": "Jrebta - جربتا"
					},
					{
						"id": 4016,
						"region_name": "Kandoula - قندولا"
					},
					{
						"id": 4017,
						"region_name": "Kfar Aabida - كفر عبيدا"
					},
					{
						"id": 4018,
						"region_name": "Kfar Chlaimane - كفر شليمان"
					},
					{
						"id": 4019,
						"region_name": "Kfar Hatna - كفرحتنا"
					},
					{
						"id": 4020,
						"region_name": "Kfar Hay - كفرحي"
					},
					{
						"id": 4021,
						"region_name": "Kfar Helda - كفر حلدا"
					},
					{
						"id": 4022,
						"region_name": "Kfarhollos - كفر خلص"
					},
					{
						"id": 4023,
						"region_name": "Kfifane - كفيفان"
					},
					{
						"id": 4024,
						"region_name": "Kfour El Aarbi - كفور العربي"
					},
					{
						"id": 4025,
						"region_name": "Koubba - كوبا"
					},
					{
						"id": 4026,
						"region_name": "Kour - كور"
					},
					{
						"id": 4027,
						"region_name": "Madfoun - مدفون"
					},
					{
						"id": 4028,
						"region_name": "Mahmerch - محمرش"
					},
					{
						"id": 4029,
						"region_name": "Mar Mama - مار ماما"
					},
					{
						"id": 4030,
						"region_name": "Mar Youhanna Maroun - مار يوحنا مارون"
					},
					{
						"id": 4031,
						"region_name": "Mar Youssef - مار يوسف"
					},
					{
						"id": 4032,
						"region_name": "Masrah - مسرح"
					},
					{
						"id": 4033,
						"region_name": "Mazraat Biyade - مزرعة بياض"
					},
					{
						"id": 4034,
						"region_name": "Meghrak - مغراق"
					},
					{
						"id": 4035,
						"region_name": "Mrah Chdid - مراح شديد"
					},
					{
						"id": 4036,
						"region_name": "Mrah El Haj - مراح الحاج"
					},
					{
						"id": 4037,
						"region_name": "Mrah Ez Zaiat - مراح الزيات"
					},
					{
						"id": 4038,
						"region_name": "Myle - ميل"
					},
					{
						"id": 4039,
						"region_name": "Nabaa ej Jdid - نبع الجديد"
					},
					{
						"id": 4040,
						"region_name": "Nahle batroun - نحلا  البترون"
					},
					{
						"id": 4041,
						"region_name": "Najmet es Sobh - نجمة الصبح"
					},
					{
						"id": 4042,
						"region_name": "Niha El Batroun - نيحا البترون"
					},
					{
						"id": 4043,
						"region_name": "Ouadi ej Jord - وادي الجرد"
					},
					{
						"id": 4044,
						"region_name": "Ouadi Tanourine Et Tahta - وادي تنورين التحتا"
					},
					{
						"id": 4045,
						"region_name": "Ouata Haub - وطى حوب"
					},
					{
						"id": 4046,
						"region_name": "Qarnaoun - قرنعون"
					},
					{
						"id": 4047,
						"region_name": "Qornet el Marj - قرنة المرج"
					},
					{
						"id": 4048,
						"region_name": "Quajh El - Hajar - وجه الحجر"
					},
					{
						"id": 4049,
						"region_name": "Racha - راشا"
					},
					{
						"id": 4050,
						"region_name": "Rachana - راشانا"
					},
					{
						"id": 4051,
						"region_name": "Rachkida - راشكيدا"
					},
					{
						"id": 4052,
						"region_name": "Rachkidde - راشكِدّه"
					},
					{
						"id": 4053,
						"region_name": "Ram el batroun - الرام البترون"
					},
					{
						"id": 4054,
						"region_name": "Ramate - رمات"
					},
					{
						"id": 4055,
						"region_name": "Ras Bnaiya - راس بنيه"
					},
					{
						"id": 4056,
						"region_name": "Ras Ed Daouali - راس الدوالي"
					},
					{
						"id": 4057,
						"region_name": "Ras Nahhach - راس نحاش"
					},
					{
						"id": 4058,
						"region_name": "Selaata - سلعاتا"
					},
					{
						"id": 4059,
						"region_name": "Sghar - صغار"
					},
					{
						"id": 4060,
						"region_name": "Smar Jbail - سمار جبيل"
					},
					{
						"id": 4061,
						"region_name": "Sourat - صورات"
					},
					{
						"id": 4062,
						"region_name": "Ssel'aal - سلع "
					},
					{
						"id": 4063,
						"region_name": "Tannourine el Faouqa - تنورين الفوقا"
					},
					{
						"id": 4064,
						"region_name": "Tanourine Et Tahta - تنورين التحتا"
					},
					{
						"id": 4065,
						"region_name": "Thoum - تحوم"
					},
					{
						"id": 4066,
						"region_name": "Toula - تولا"
					},
					{
						"id": 4067,
						"region_name": "Yarita - ياريتا"
					},
					{
						"id": 4068,
						"region_name": "Zane - زان"
					}
				]
			},
			{
				"id": 20,
				"DistrictDescription": "El Koura - الكورة",
				"Cities": [
					{
						"id": 4069,
						"region_name": " DEIR EL BALAMAND  - دير البلمند"
					},
					{
						"id": 4070,
						"region_name": " KELBATA  - كلباتا"
					},
					{
						"id": 4071,
						"region_name": " MJEYDIL KFAR HAZIR - مجيدل كفر حزير"
					},
					{
						"id": 4072,
						"region_name": "Aaba - عبا الكورة"
					},
					{
						"id": 4073,
						"region_name": "Aafsdiq - عفصديق"
					},
					{
						"id": 4074,
						"region_name": "Ain Aakrine - عين عكرين"
					},
					{
						"id": 4075,
						"region_name": "Al-Hraiche - حريشه"
					},
					{
						"id": 4076,
						"region_name": "Amioun - اميون"
					},
					{
						"id": 4077,
						"region_name": "Bahbouch - بحبوش "
					},
					{
						"id": 4078,
						"region_name": "Barghoun - برغون"
					},
					{
						"id": 4079,
						"region_name": "Barsa - برصا"
					},
					{
						"id": 4080,
						"region_name": "Batroumine - بترومين"
					},
					{
						"id": 4081,
						"region_name": "Bdeihoun - بدبهون"
					},
					{
						"id": 4082,
						"region_name": "Bdibba - بدبا"
					},
					{
						"id": 4083,
						"region_name": "Bechmizzine - بشمزين"
					},
					{
						"id": 4084,
						"region_name": "Bednayel el koura - بدنايل الكورة"
					},
					{
						"id": 4085,
						"region_name": "Bkeftine - بكفتين"
					},
					{
						"id": 4086,
						"region_name": "Bnehrane - بنهران"
					},
					{
						"id": 4087,
						"region_name": "Bsarma - بصرما"
					},
					{
						"id": 4088,
						"region_name": "Btaaboura - بتعبورة"
					},
					{
						"id": 4089,
						"region_name": "Btouratij - بتوراتيج"
					},
					{
						"id": 4090,
						"region_name": "Btourram - بطرام"
					},
					{
						"id": 4091,
						"region_name": "Bziza - بزيزا"
					},
					{
						"id": 4092,
						"region_name": "Dahr AlAin - ضهر العين"
					},
					{
						"id": 4093,
						"region_name": "Dar Baachtar - دار بعشتار"
					},
					{
						"id": 4094,
						"region_name": "Dar Chmizzine - دار شمزين"
					},
					{
						"id": 4095,
						"region_name": "Dedde - دده"
					},
					{
						"id": 4096,
						"region_name": "El Aaqbe - عقبة "
					},
					{
						"id": 4097,
						"region_name": "El Bahsas - بحصاص"
					},
					{
						"id": 4098,
						"region_name": "El Bqaiaa - البقيعة"
					},
					{
						"id": 4099,
						"region_name": "En Nakhle - نخلة"
					},
					{
						"id": 4100,
						"region_name": "Enfe - أنفه"
					},
					{
						"id": 4101,
						"region_name": "Fiaa - فيع"
					},
					{
						"id": 4102,
						"region_name": "Haret El KHASSEH  - حارة الخسة"
					},
					{
						"id": 4103,
						"region_name": "Ijdabrine - اجدعبرين"
					},
					{
						"id": 4104,
						"region_name": "Jdeideh Berkacha - جديدة برقاشا"
					},
					{
						"id": 4105,
						"region_name": "Kaftoun - كفتون"
					},
					{
						"id": 4106,
						"region_name": "Kefraiya - كفريا الكورة"
					},
					{
						"id": 4107,
						"region_name": "Kfar Aaqqa - كفر عقا"
					},
					{
						"id": 4108,
						"region_name": "Kfar Hata - كفرحاتا"
					},
					{
						"id": 4109,
						"region_name": "Kfar Hazir - كفرحزير"
					},
					{
						"id": 4110,
						"region_name": "Kfar Qahel - كفرقاهل"
					},
					{
						"id": 4111,
						"region_name": "Kfar Saroun - كفرصارون"
					},
					{
						"id": 4112,
						"region_name": "Kousba - كوسبا"
					},
					{
						"id": 4113,
						"region_name": "Majdel el Koura - مجدل الكورة"
					},
					{
						"id": 4114,
						"region_name": "Metrit - متريت"
					},
					{
						"id": 4115,
						"region_name": "Oueta Fares - وطى فارس"
					},
					{
						"id": 4116,
						"region_name": "Qalhat - قلحات"
					},
					{
						"id": 4117,
						"region_name": "Ras Maska - راس مسقا"
					},
					{
						"id": 4118,
						"region_name": "Ras Masqa Ech Chmaliye - راس مسقا الشمالية"
					},
					{
						"id": 4119,
						"region_name": "Ras Masqa Ej Jnoubiye - راس مسقا الجنوبية"
					},
					{
						"id": 4120,
						"region_name": "Rechdibbine - رشدبين"
					},
					{
						"id": 4121,
						"region_name": "Zakroun - زكرون"
					},
					{
						"id": 4122,
						"region_name": "Zakzouk - زكزوك"
					},
					{
						"id": 4123,
						"region_name": "Zgharta el Mtaouleh - زغرتا المتاولة"
					}
				]
			},
			{
				"id": 21,
				"DistrictDescription": "El Minieh-Dennie - المنية الضنية",
				"Cities": [
					{
						"id": 4124,
						"region_name": " MAZRAAT KETRANE  - مزرعة كتران"
					},
					{
						"id": 4125,
						"region_name": "Aadoui - عدوة"
					},
					{
						"id": 4126,
						"region_name": "Aassaimout - عصيموت"
					},
					{
						"id": 4127,
						"region_name": "Aassoun - عاصون"
					},
					{
						"id": 4128,
						"region_name": "Abou Hamad - ابو حمد"
					},
					{
						"id": 4129,
						"region_name": "Afqa El Minieh - افقا المنيه"
					},
					{
						"id": 4130,
						"region_name": "Ain es Safsaf - عين الصفصاف"
					},
					{
						"id": 4131,
						"region_name": "Ain Et Tine - عين التينه المنيه"
					},
					{
						"id": 4132,
						"region_name": "Amar - عيمر "
					},
					{
						"id": 4133,
						"region_name": "Azqey - عزقي "
					},
					{
						"id": 4134,
						"region_name": "Baazqoun - بعزقون"
					},
					{
						"id": 4135,
						"region_name": "Bahsa - البحصة"
					},
					{
						"id": 4136,
						"region_name": "Bakhaoun - بخعون"
					},
					{
						"id": 4137,
						"region_name": "Bchennata - بشناتا"
					},
					{
						"id": 4138,
						"region_name": "Bechhara - بشحارة"
					},
					{
						"id": 4139,
						"region_name": "Bechtayel - بشتايل"
					},
					{
						"id": 4140,
						"region_name": "Behouaita - بحويتا"
					},
					{
						"id": 4141,
						"region_name": "Beit Bakkour - بيت بكور"
					},
					{
						"id": 4142,
						"region_name": "Beit Daoud Izal - بيت داوود عزال"
					},
					{
						"id": 4143,
						"region_name": "Beit El Faqs - بيت الفقس"
					},
					{
						"id": 4144,
						"region_name": "Beit Haouik - بيت حاويك"
					},
					{
						"id": 4145,
						"region_name": "Beit Hasna - بيت حسنة"
					},
					{
						"id": 4146,
						"region_name": "Beit jida - بيت جدا"
					},
					{
						"id": 4147,
						"region_name": "Beit Kanj - بيت كنج"
					},
					{
						"id": 4148,
						"region_name": "Beit Moumne - بيت مومنة"
					},
					{
						"id": 4149,
						"region_name": "Beit Radouane - بيت رضوان"
					},
					{
						"id": 4150,
						"region_name": "Beit Zoud - بيت زود"
					},
					{
						"id": 4151,
						"region_name": "Bhannine El Minieh - بحنين المنيه"
					},
					{
						"id": 4152,
						"region_name": "Borj El Yahoudiye - برج اليهودية"
					},
					{
						"id": 4153,
						"region_name": "Bqaa Safrin - بقاع صفرين"
					},
					{
						"id": 4154,
						"region_name": "Bqarsouna - بقرصونه"
					},
					{
						"id": 4155,
						"region_name": "Btahline - بتحلين"
					},
					{
						"id": 4156,
						"region_name": "Btellaiye - بتلايه"
					},
					{
						"id": 4157,
						"region_name": "Btermaz - بطرماز"
					},
					{
						"id": 4158,
						"region_name": "Chmis - الشميس"
					},
					{
						"id": 4159,
						"region_name": "Dairaiya - داريا المنيه"
					},
					{
						"id": 4160,
						"region_name": "Debaal El Minieh - دبعل المنيه"
					},
					{
						"id": 4161,
						"region_name": "Deir Amar - دير عمار"
					},
					{
						"id": 4162,
						"region_name": "Deir Nbouh - دير نبوح"
					},
					{
						"id": 4163,
						"region_name": "Ech Chalout - شالوط"
					},
					{
						"id": 4164,
						"region_name": "El Beddaoui - بداوي"
					},
					{
						"id": 4165,
						"region_name": "El Hazmiye - حازمية المنيه"
					},
					{
						"id": 4166,
						"region_name": "El Kharnoub - خرنوب المنيه"
					},
					{
						"id": 4167,
						"region_name": "El Medine ej jdide - مدينة الجديدة"
					},
					{
						"id": 4168,
						"region_name": "El Minie - المنيه"
					},
					{
						"id": 4169,
						"region_name": "En Nabi Kzaiber - النبي كزيبر"
					},
					{
						"id": 4170,
						"region_name": "En Nabi Youchaa - النبي يوشع"
					},
					{
						"id": 4171,
						"region_name": "Er Rihaniye El Minieh - ريحانيه  المنيه"
					},
					{
						"id": 4172,
						"region_name": "Es Snoubar - صنوبر"
					},
					{
						"id": 4173,
						"region_name": "Haoura - حوارة "
					},
					{
						"id": 4174,
						"region_name": "Haql El Aazime - حقل العزيمة"
					},
					{
						"id": 4175,
						"region_name": "Haqlit - حقلة"
					},
					{
						"id": 4176,
						"region_name": "Harf es siyad - حرف السياد"
					},
					{
						"id": 4177,
						"region_name": "Izal - إيزال"
					},
					{
						"id": 4178,
						"region_name": "Jairoun - جيرون"
					},
					{
						"id": 4179,
						"region_name": "Jarjour - جرجور"
					},
					{
						"id": 4180,
						"region_name": "Kahf El - Malloul - كفَ الملول"
					},
					{
						"id": 4181,
						"region_name": "Karm El - Mohr - كرم المهر"
					},
					{
						"id": 4182,
						"region_name": "Karm El Akhras - كرم الاخرس"
					},
					{
						"id": 4183,
						"region_name": "Kfar Bebnine - كفر ببنين"
					},
					{
						"id": 4184,
						"region_name": "Kfar Chillane - كفر شلان"
					},
					{
						"id": 4185,
						"region_name": "Kfar Habou - كفر حبو"
					},
					{
						"id": 4186,
						"region_name": "Kharnoub - خرنوب"
					},
					{
						"id": 4187,
						"region_name": "Maassarati - معصراتي"
					},
					{
						"id": 4188,
						"region_name": "Markabta - مركبتا"
					},
					{
						"id": 4189,
						"region_name": "Mazraat Aartoussi - مزرعة عرطوسي"
					},
					{
						"id": 4190,
						"region_name": "MAZRAAT EL KREYM  - مزرعة الكريم"
					},
					{
						"id": 4191,
						"region_name": "Mgharet ech Cheikh - مغارة الشيخ"
					},
					{
						"id": 4192,
						"region_name": "Moulid - موليد"
					},
					{
						"id": 4193,
						"region_name": "Mrah Es Sfire - مراح السفيرة"
					},
					{
						"id": 4194,
						"region_name": "Mrah Es Sreij - مراح السريج"
					},
					{
						"id": 4195,
						"region_name": "Mrebbine - مربين"
					},
					{
						"id": 4196,
						"region_name": "Nemrine - نمرين"
					},
					{
						"id": 4197,
						"region_name": "Ouadi En Nahle - وادي النحلة"
					},
					{
						"id": 4198,
						"region_name": "Ouatiye - واطيه"
					},
					{
						"id": 4199,
						"region_name": "Qarhaiya - قرحيا"
					},
					{
						"id": 4200,
						"region_name": "Qarn - القرن"
					},
					{
						"id": 4201,
						"region_name": "Qarsita - قرصيتا"
					},
					{
						"id": 4202,
						"region_name": "Qattine El Minieh - القطين المنيه"
					},
					{
						"id": 4203,
						"region_name": "Qemmamine - قمامين"
					},
					{
						"id": 4204,
						"region_name": "Qraine - قرين"
					},
					{
						"id": 4205,
						"region_name": "Raouda - روضه المنيه"
					},
					{
						"id": 4206,
						"region_name": "Sertouka - سرتوكا"
					},
					{
						"id": 4207,
						"region_name": "Sfire - سفيرة"
					},
					{
						"id": 4208,
						"region_name": "Sir Ed Danniye - سير الضنية"
					},
					{
						"id": 4209,
						"region_name": "Souaqi - السواقي"
					},
					{
						"id": 4210,
						"region_name": "Tarane - طاران"
					},
					{
						"id": 4211,
						"region_name": "Terbol El Minieh - تربل المنيه"
					},
					{
						"id": 4212,
						"region_name": "Wadi en Njass - وادي النجاص"
					},
					{
						"id": 4213,
						"region_name": "Zghartighrine - زغرتغرين"
					},
					{
						"id": 4214,
						"region_name": "Zouq Bhannine - ذوق بحنين"
					}
				]
			},
			{
				"id": 22,
				"DistrictDescription": "Tripoli - طرابلس",
				"Cities": [
					{
						"id": 4215,
						"region_name": "Abou Samra - ابو سمرا"
					},
					{
						"id": 4216,
						"region_name": "Bab Al Ramel - باب الرمل "
					},
					{
						"id": 4217,
						"region_name": "Bahsas - البحصاص"
					},
					{
						"id": 4218,
						"region_name": "Dam Wal Farz - الضم و الفرز"
					},
					{
						"id": 4219,
						"region_name": "Ed Debbagha - الدباغة"
					},
					{
						"id": 4220,
						"region_name": "El Maloula - الملولة"
					},
					{
						"id": 4221,
						"region_name": "El Mina - المينا"
					},
					{
						"id": 4222,
						"region_name": "Hadid - الحديد"
					},
					{
						"id": 4223,
						"region_name": "Hdadine - الحدادين"
					},
					{
						"id": 4224,
						"region_name": "Jessrine - جسرين"
					},
					{
						"id": 4225,
						"region_name": "Mankoubin - منكوبين"
					},
					{
						"id": 4226,
						"region_name": "Mhatreh - المهاترة"
					},
					{
						"id": 4227,
						"region_name": "MINA 1 - مينا طرابلس 1"
					},
					{
						"id": 4228,
						"region_name": "MINA 2 - مينا طرابلس 2"
					},
					{
						"id": 4229,
						"region_name": "Mina Jardin - بساتين المينا"
					},
					{
						"id": 4230,
						"region_name": "Noury - النوري"
					},
					{
						"id": 4231,
						"region_name": "Qalamoun - القلمون"
					},
					{
						"id": 4232,
						"region_name": "Qoubbe - القبة"
					},
					{
						"id": 4233,
						"region_name": "Rammanet - الرمانة"
					},
					{
						"id": 4234,
						"region_name": "Shok - شق"
					},
					{
						"id": 4235,
						"region_name": "Souayqa  - السويقة"
					},
					{
						"id": 4236,
						"region_name": "Tabbaneh - التبانة"
					},
					{
						"id": 4237,
						"region_name": "Tal - التل"
					},
					{
						"id": 4238,
						"region_name": "Trablous Jardins - بساتين طرابلس"
					},
					{
						"id": 4239,
						"region_name": "Trablous Zeitoun - طرابلس الزيتون"
					},
					{
						"id": 4240,
						"region_name": "Tripoli - طرابلس"
					},
					{
						"id": 4241,
						"region_name": "Zahrieh - الزاهرية"
					}
				]
			},
			{
				"id": 23,
				"DistrictDescription": "Zgharta - زغرتا",
				"Cities": [
					{
						"id": 4242,
						"region_name": " IAAL  - إيعال"
					},
					{
						"id": 4243,
						"region_name": "Aachach - عشاش"
					},
					{
						"id": 4244,
						"region_name": "Aalma - علما"
					},
					{
						"id": 4245,
						"region_name": "Aaqbet Sibaal - عقبة سبعل"
					},
					{
						"id": 4246,
						"region_name": "Aarbet Qozhaiya - عربة قزحيا"
					},
					{
						"id": 4247,
						"region_name": "Aardat - عردات"
					},
					{
						"id": 4248,
						"region_name": "Aarjess - عرجس"
					},
					{
						"id": 4249,
						"region_name": "Ain Tourine - عينطورين"
					},
					{
						"id": 4250,
						"region_name": "Aitou - أيطو"
					},
					{
						"id": 4251,
						"region_name": "Al Aaqbe - عقبة علما"
					},
					{
						"id": 4252,
						"region_name": "Arde - أرده"
					},
					{
						"id": 4253,
						"region_name": "Aslout - اسلوت"
					},
					{
						"id": 4254,
						"region_name": "Asnoun - اصنون"
					},
					{
						"id": 4255,
						"region_name": "Baslouqit - بسلوقيت"
					},
					{
						"id": 4256,
						"region_name": "Bayader Rachaaine - بيادر رشعين"
					},
					{
						"id": 4257,
						"region_name": "Bchama - بشاما"
					},
					{
						"id": 4258,
						"region_name": "Bchannine - بشنين"
					},
					{
						"id": 4259,
						"region_name": "Beit Aabeid - بيت عبيد"
					},
					{
						"id": 4260,
						"region_name": "Beit Aaoukar - بيت عوكر"
					},
					{
						"id": 4261,
						"region_name": "Beit Barakat - بيت بركات"
					},
					{
						"id": 4262,
						"region_name": "Beit el Hraqsa - بيت الحرقصى"
					},
					{
						"id": 4263,
						"region_name": "Beit Knaty - بيت قناطي"
					},
					{
						"id": 4264,
						"region_name": "Bnechaai - بنشعي"
					},
					{
						"id": 4265,
						"region_name": "Bouhairet Toula - بحيرة تولا"
					},
					{
						"id": 4266,
						"region_name": "Bousit - بوسيط"
					},
					{
						"id": 4267,
						"region_name": "Bsebaal - بسبعل"
					},
					{
						"id": 4268,
						"region_name": "Danha - دنها"
					},
					{
						"id": 4269,
						"region_name": "Darayia - داريا "
					},
					{
						"id": 4270,
						"region_name": "Ehden - اهدن"
					},
					{
						"id": 4271,
						"region_name": "Ejbeaa - اجبع"
					},
					{
						"id": 4272,
						"region_name": "El Hariq - حريق"
					},
					{
						"id": 4273,
						"region_name": "El Houakir - حواكير"
					},
					{
						"id": 4274,
						"region_name": "El Khaldiye - خالدية"
					},
					{
						"id": 4275,
						"region_name": "El Qadriye - قادريه"
					},
					{
						"id": 4276,
						"region_name": "Er Rmaile - رميله أرده"
					},
					{
						"id": 4277,
						"region_name": "Et Talle - تلة زغرتا"
					},
					{
						"id": 4278,
						"region_name": "Fouwar zgharta - فوار زغرتا"
					},
					{
						"id": 4279,
						"region_name": "Fradis - الفراديس"
					},
					{
						"id": 4280,
						"region_name": "Haouqa Et En Naher - حوقا النهر"
					},
					{
						"id": 4281,
						"region_name": "Haret al Fouwar - حارة الفوار"
					},
					{
						"id": 4282,
						"region_name": "Haret Ej Jdide Zgharta - حارة الجديدة زغرتا"
					},
					{
						"id": 4283,
						"region_name": "Haret el Baklik - حارة البكليك"
					},
					{
						"id": 4284,
						"region_name": "Harfe Arde - حرف أرده"
					},
					{
						"id": 4285,
						"region_name": "Hariq Zgharta - حريق زغرتا"
					},
					{
						"id": 4286,
						"region_name": "Harsoun Ej Jdide - حصرون الجديدة"
					},
					{
						"id": 4287,
						"region_name": "Hilane zgharta - حيلان زغرتا"
					},
					{
						"id": 4288,
						"region_name": "Hmais zgharta - حميص "
					},
					{
						"id": 4289,
						"region_name": "Hraykess - حريقص"
					},
					{
						"id": 4290,
						"region_name": "Jdeide zgharta - الجديدة زغرتا"
					},
					{
						"id": 4291,
						"region_name": "Kaabouch - كبوش"
					},
					{
						"id": 4292,
						"region_name": "Kafar Zeina - كفر زينا"
					},
					{
						"id": 4293,
						"region_name": "Kafraiya zgharta - كفريا زغرتا"
					},
					{
						"id": 4294,
						"region_name": "Karbribe - كربريب"
					},
					{
						"id": 4295,
						"region_name": "Karm Sadde - كرم سده"
					},
					{
						"id": 4296,
						"region_name": "Kfar Chakhna - كفر شخنا"
					},
					{
						"id": 4297,
						"region_name": "Kfar Dlaqous - كفردلاقوس"
					},
					{
						"id": 4298,
						"region_name": "Kfar Fou - كفر فو"
					},
					{
						"id": 4299,
						"region_name": "Kfar Haoura - كفر حورا"
					},
					{
						"id": 4300,
						"region_name": "Kfar Hata - كفر حاتا"
					},
					{
						"id": 4301,
						"region_name": "Kfar Sghab - كفر صغاب"
					},
					{
						"id": 4302,
						"region_name": "Kfar Sghab - كفرصغاب"
					},
					{
						"id": 4303,
						"region_name": "Kfar Yachit - كفر ياشيت"
					},
					{
						"id": 4304,
						"region_name": "Majdalaya zgharta - مجدليا زغرتا"
					},
					{
						"id": 4305,
						"region_name": "Mar Semaane - مار سمعان"
					},
					{
						"id": 4306,
						"region_name": "Mazraat Ejbeh - مزرعة إجبع"
					},
					{
						"id": 4307,
						"region_name": "Mazraat Et Toufah - مزرعة التفاح"
					},
					{
						"id": 4308,
						"region_name": "Mazraat Hraqs - مزرعة حريقص"
					},
					{
						"id": 4309,
						"region_name": "Mazraat Jnaid - مزرعة جنيد"
					},
					{
						"id": 4310,
						"region_name": "Mazret al Nahr - مزرعة النهر "
					},
					{
						"id": 4311,
						"region_name": "Miryata - مرياطا"
					},
					{
						"id": 4312,
						"region_name": "Miziara - مزيارة"
					},
					{
						"id": 4313,
						"region_name": "Qarah Bach - قره باش"
					},
					{
						"id": 4314,
						"region_name": "Qareit Sakhra - قرعية صخرا"
					},
					{
						"id": 4315,
						"region_name": "Rachaaine - رشعين"
					},
					{
						"id": 4316,
						"region_name": "Raskifa - راس كيفا"
					},
					{
						"id": 4317,
						"region_name": "Sahl Danha - سهل دنها"
					},
					{
						"id": 4318,
						"region_name": "Sakhra - صخرة"
					},
					{
						"id": 4319,
						"region_name": "Sanaallah - صنع الله"
					},
					{
						"id": 4320,
						"region_name": "Sebaal - سبعل زغرتا"
					},
					{
						"id": 4321,
						"region_name": "Seraal - سرعيل"
					},
					{
						"id": 4322,
						"region_name": "Toula zgharta - تولا زغرتا"
					},
					{
						"id": 4323,
						"region_name": "Zgharta - زغرتا"
					}
				]
			}
		]
	},
	{
		"id": 8,
		"GovernorateDescription": "South - الجنوب",
		"Districts": [
			{
				"id": 24,
				"DistrictDescription": "Jezzine - جزين",
				"Cities": [
					{
						"id": 4324,
						"region_name": "Aadour - عاضور"
					},
					{
						"id": 4325,
						"region_name": "Aaichiye - عيشية"
					},
					{
						"id": 4326,
						"region_name": "Aaqmata - عقماتا "
					},
					{
						"id": 4327,
						"region_name": "Aaramta - عرمتى"
					},
					{
						"id": 4328,
						"region_name": "Aariye - عاريه"
					},
					{
						"id": 4329,
						"region_name": "Aarqoub - العرقوب"
					},
					{
						"id": 4330,
						"region_name": "Aazour - عازور"
					},
					{
						"id": 4331,
						"region_name": "Ain el Mir el Estabel - عين المير إسطبل"
					},
					{
						"id": 4332,
						"region_name": "Ain et Taghra - عين التغرى"
					},
					{
						"id": 4333,
						"region_name": "Ain Majdalain - عين مجدلين"
					},
					{
						"id": 4334,
						"region_name": "Anane - أنان"
					},
					{
						"id": 4335,
						"region_name": "Azibeh - عزيبة"
					},
					{
						"id": 4336,
						"region_name": "Baanoub - بعانوب"
					},
					{
						"id": 4337,
						"region_name": "Baba - بيبه"
					},
					{
						"id": 4338,
						"region_name": "Baissour Jezzine - بيصور جزين"
					},
					{
						"id": 4339,
						"region_name": "Benouati - بنواتي جزين"
					},
					{
						"id": 4340,
						"region_name": "Bhannine - بحنين"
					},
					{
						"id": 4341,
						"region_name": "Bisri - بسري"
					},
					{
						"id": 4342,
						"region_name": "Biyad - البياض"
					},
					{
						"id": 4343,
						"region_name": "Bkassine - بكاسين"
					},
					{
						"id": 4344,
						"region_name": "Bouslaya - بوصلايا"
					},
					{
						"id": 4345,
						"region_name": "Bteddine el Loqch - بتدين اللقش"
					},
					{
						"id": 4346,
						"region_name": "Chamkha - شامخة"
					},
					{
						"id": 4347,
						"region_name": "Chbeil - شبيل"
					},
					{
						"id": 4348,
						"region_name": "Choualiq - شواليق جزين"
					},
					{
						"id": 4349,
						"region_name": "Dahr ed Deir - ضهر الدير"
					},
					{
						"id": 4350,
						"region_name": "Dahr er Ramle - ضهر الرملة"
					},
					{
						"id": 4351,
						"region_name": "Darayia Jezzine - داريا جزين"
					},
					{
						"id": 4352,
						"region_name": "Deir Chkedif - شقاديف"
					},
					{
						"id": 4353,
						"region_name": "Deir El Qattine - دير قطِين "
					},
					{
						"id": 4354,
						"region_name": "Deir Machmouche - دير مشموشه"
					},
					{
						"id": 4355,
						"region_name": "Deir Moukhalles - دير  المخلص"
					},
					{
						"id": 4356,
						"region_name": "Dimechqiye - دمشقية"
					},
					{
						"id": 4357,
						"region_name": "El Harf Jezzine - الحرف جزين"
					},
					{
						"id": 4358,
						"region_name": "El Houraniye - حورانية"
					},
					{
						"id": 4359,
						"region_name": "El Msous - مصوص"
					},
					{
						"id": 4360,
						"region_name": "El Qabaa - قبع"
					},
					{
						"id": 4361,
						"region_name": "Ghbatiye - غبَاطية"
					},
					{
						"id": 4362,
						"region_name": "Haidab - حيداب"
					},
					{
						"id": 4363,
						"region_name": "Haitoule - حيتوله"
					},
					{
						"id": 4364,
						"region_name": "Haitoura - حيطورة"
					},
					{
						"id": 4365,
						"region_name": "Haret el Bayder - حارة البيادر"
					},
					{
						"id": 4366,
						"region_name": "Harf - الحرف"
					},
					{
						"id": 4367,
						"region_name": "Hassaniye - حسانية"
					},
					{
						"id": 4368,
						"region_name": "Homsiye - حمصية"
					},
					{
						"id": 4369,
						"region_name": "Jabal Toura - جبل طوره"
					},
					{
						"id": 4370,
						"region_name": "Jall Nachi - جل ناشي"
					},
					{
						"id": 4371,
						"region_name": "Jarmaq - الجرمق"
					},
					{
						"id": 4372,
						"region_name": "Jdaidet el Ouadi - الجديدة وادي جزين"
					},
					{
						"id": 4373,
						"region_name": "Jensnaya - جنسنايا"
					},
					{
						"id": 4374,
						"region_name": "Jernaya - جرنايا"
					},
					{
						"id": 4375,
						"region_name": "Jezzine - جزين"
					},
					{
						"id": 4376,
						"region_name": "Karkha - كرخا"
					},
					{
						"id": 4377,
						"region_name": "Kfar Falous - كفرفالوس"
					},
					{
						"id": 4378,
						"region_name": "Kfar Houne - كفرحون"
					},
					{
						"id": 4379,
						"region_name": "Kfar Jarra - كفرجرا"
					},
					{
						"id": 4380,
						"region_name": "Kfar Taala - كفرتعلا"
					},
					{
						"id": 4381,
						"region_name": "Khallet Khazen - خلة خازن"
					},
					{
						"id": 4382,
						"region_name": "Khirkhaya - خرخيا"
					},
					{
						"id": 4383,
						"region_name": "Lebaa - لبعا"
					},
					{
						"id": 4384,
						"region_name": "Louaiziye - لويزة جزين"
					},
					{
						"id": 4385,
						"region_name": "Maarous el Branieh - ماروس البرانية"
					},
					{
						"id": 4386,
						"region_name": "Maarous el Jouanieh - ماروس الجوانية"
					},
					{
						"id": 4387,
						"region_name": "Machmouche - مشموشة"
					},
					{
						"id": 4388,
						"region_name": "Mahmoudiye Jezzine - محمودية جزين"
					},
					{
						"id": 4389,
						"region_name": "Maknouniye - مكنونية "
					},
					{
						"id": 4390,
						"region_name": "Mazraat  Aarqoub - مزرعة العرقوب"
					},
					{
						"id": 4391,
						"region_name": "Mazraat  Jabal Toura - مزرعة جبل طوره"
					},
					{
						"id": 4392,
						"region_name": "Mazraat  Tamra - مزرعة طمره"
					},
					{
						"id": 4393,
						"region_name": "Mazraat Aaqmata - مزرعة عقماتا "
					},
					{
						"id": 4394,
						"region_name": "Mazraat Aaraji - مزرعة عراجي"
					},
					{
						"id": 4395,
						"region_name": "Mazraat Al Souairi - مزرعة الصويري"
					},
					{
						"id": 4396,
						"region_name": "Mazraat el Btadiniye - مزرعة البتدينية"
					},
					{
						"id": 4397,
						"region_name": "Mazraat el Khaoukh - مزرعة الخوخ"
					},
					{
						"id": 4398,
						"region_name": "Mazraat el Mathane - مزرعة المطحنة"
					},
					{
						"id": 4399,
						"region_name": "Mazraat er Rohbane - مزرعة الرهبان "
					},
					{
						"id": 4400,
						"region_name": "Mazraat Louzyde - مزرعة لوزيد "
					},
					{
						"id": 4401,
						"region_name": "Mharbiye - محاربية"
					},
					{
						"id": 4402,
						"region_name": "Midane - ميدان "
					},
					{
						"id": 4403,
						"region_name": "Mjaydel - مجيدل"
					},
					{
						"id": 4404,
						"region_name": "Mlikh - مليخ"
					},
					{
						"id": 4405,
						"region_name": "Mrah Abdu Chedid - مراح أبو شديد"
					},
					{
						"id": 4406,
						"region_name": "Mrah El Hbas - مراح الحباس"
					},
					{
						"id": 4407,
						"region_name": "Mrah Mghaibe - مراح مغبيه"
					},
					{
						"id": 4408,
						"region_name": "Mzairaa - مزيرعة"
					},
					{
						"id": 4409,
						"region_name": "Nabaa - نبعه"
					},
					{
						"id": 4410,
						"region_name": "Nabi Sejoud - نبي سجد"
					},
					{
						"id": 4411,
						"region_name": "Ouadi Baanqoudine - وادي بعنقودين"
					},
					{
						"id": 4412,
						"region_name": "Ouadi el Leimoun - وادي الليمون"
					},
					{
						"id": 4413,
						"region_name": "Ouardiye - وردية"
					},
					{
						"id": 4414,
						"region_name": "Ouazaaiye - اوزاعية"
					},
					{
						"id": 4415,
						"region_name": "Qaitoule - قيتولي"
					},
					{
						"id": 4416,
						"region_name": "Qotrani - قطراني"
					},
					{
						"id": 4417,
						"region_name": "Qrouh - قروح"
					},
					{
						"id": 4418,
						"region_name": "Qtale Jezzine - قتالة جزين"
					},
					{
						"id": 4419,
						"region_name": "Raimat - ريمات"
					},
					{
						"id": 4420,
						"region_name": "Rihane Jezzine - ريحانة جزين"
					},
					{
						"id": 4421,
						"region_name": "Roum - روم"
					},
					{
						"id": 4422,
						"region_name": "Roummane Jezzine - رمانة "
					},
					{
						"id": 4423,
						"region_name": "Rous El Franj - راس الوادي الحوة"
					},
					{
						"id": 4424,
						"region_name": "Sabah - صّباح"
					},
					{
						"id": 4425,
						"region_name": "Salima - صليما"
					},
					{
						"id": 4426,
						"region_name": "Sejoud - سجد"
					},
					{
						"id": 4427,
						"region_name": "Sfarai - صفاريه"
					},
					{
						"id": 4428,
						"region_name": "Sidoun - صيدون"
					},
					{
						"id": 4429,
						"region_name": "Sniye - سنيَا"
					},
					{
						"id": 4430,
						"region_name": "Sriri - سريرة"
					},
					{
						"id": 4431,
						"region_name": "Taaid - تعيد"
					},
					{
						"id": 4432,
						"region_name": "Tamra - طمره"
					},
					{
						"id": 4433,
						"region_name": "Wadi Jezzine - وادي جزين"
					},
					{
						"id": 4434,
						"region_name": "Zaghrine Jezzine - زغرين جزين"
					},
					{
						"id": 4435,
						"region_name": "Zhalta - زحلتا"
					}
				]
			},
			{
				"id": 25,
				"DistrictDescription": "Saida - صيدا",
				"Cities": [
					{
						"id": 4436,
						"region_name": "Aabra - عبرا "
					},
					{
						"id": 4437,
						"region_name": "Aaddoussiye - عدّوسية"
					},
					{
						"id": 4438,
						"region_name": "Aadloun - عدلون"
					},
					{
						"id": 4439,
						"region_name": "Aanqoun - عنقون"
					},
					{
						"id": 4440,
						"region_name": "Aaqbiye - العاقبية"
					},
					{
						"id": 4441,
						"region_name": "Aaqtanit - عقتنيت"
					},
					{
						"id": 4442,
						"region_name": "Aarnaba - عرنابة"
					},
					{
						"id": 4443,
						"region_name": "Abou el Assouad - ابو الاسود"
					},
					{
						"id": 4444,
						"region_name": "Ain Ed Delb - عين الدلب"
					},
					{
						"id": 4445,
						"region_name": "Ain el Hiloue - عين الحلوة"
					},
					{
						"id": 4446,
						"region_name": "Arkey - اركي"
					},
					{
						"id": 4447,
						"region_name": "Arzay - أرزي"
					},
					{
						"id": 4448,
						"region_name": "Babliye - بابلية"
					},
					{
						"id": 4449,
						"region_name": "Barti - برتي"
					},
					{
						"id": 4450,
						"region_name": "Bissariyeh - بيسارية"
					},
					{
						"id": 4451,
						"region_name": "Bnaafoul - بنعفول"
					},
					{
						"id": 4452,
						"region_name": "Bqosta - بقسطا"
					},
					{
						"id": 4453,
						"region_name": "Brak et Tall - براك التل"
					},
					{
						"id": 4454,
						"region_name": "Bramiye - برامية"
					},
					{
						"id": 4455,
						"region_name": "Daoudiye - الداودية"
					},
					{
						"id": 4456,
						"region_name": "Darb es Sim - درب السيم"
					},
					{
						"id": 4457,
						"region_name": "Deir Taqla - دير تقلا"
					},
					{
						"id": 4458,
						"region_name": "Dekerman - الدكرمان"
					},
					{
						"id": 4459,
						"region_name": "Dhour Darb es Sim - ضهور درب السيم"
					},
					{
						"id": 4460,
						"region_name": "El Achrafiye - أشرفية المية ومية"
					},
					{
						"id": 4461,
						"region_name": "El Mahmoudiye - محمودية"
					},
					{
						"id": 4462,
						"region_name": "Er Rouayess - روايس"
					},
					{
						"id": 4463,
						"region_name": "Ez Zahrani - الزهراني"
					},
					{
						"id": 4464,
						"region_name": "Ghassaniye - غسانية"
					},
					{
						"id": 4465,
						"region_name": "Ghaziye - غازية"
					},
					{
						"id": 4466,
						"region_name": "Hababiye - حبابية"
					},
					{
						"id": 4467,
						"region_name": "Hajje - حجة"
					},
					{
						"id": 4468,
						"region_name": "Haret Saida - حارة صيدا"
					},
					{
						"id": 4469,
						"region_name": "Hartai - حارتية"
					},
					{
						"id": 4470,
						"region_name": "Hlaliye Saida - هلالية صيدا"
					},
					{
						"id": 4471,
						"region_name": "Insariye - إنصارية"
					},
					{
						"id": 4472,
						"region_name": "Jazire - جزيرة صيدا"
					},
					{
						"id": 4473,
						"region_name": "Jdaide Arzai - جديدة  أرزي"
					},
					{
						"id": 4474,
						"region_name": "Jinjlaya - جنجلاية"
					},
					{
						"id": 4475,
						"region_name": "Kafraiya - كفريا"
					},
					{
						"id": 4476,
						"region_name": "Kaouthariyet es Siyad - كوثرية السياد"
					},
					{
						"id": 4477,
						"region_name": "Kfar Badde - كفر بدَه"
					},
					{
						"id": 4478,
						"region_name": "Kfar Beit - كفربيت"
					},
					{
						"id": 4479,
						"region_name": "Kfar Chellal - كفر شلال"
					},
					{
						"id": 4480,
						"region_name": "Kfar Hatta - كفرحتى"
					},
					{
						"id": 4481,
						"region_name": "Kfar Melki Saida - كفرملكي صيدا"
					},
					{
						"id": 4482,
						"region_name": "Khannoussiye - خنوسية"
					},
					{
						"id": 4483,
						"region_name": "Kharayeb Saida - الخرائب صيدا"
					},
					{
						"id": 4484,
						"region_name": "Khartoum - خرطوم"
					},
					{
						"id": 4485,
						"region_name": "Khaziz - خزيز"
					},
					{
						"id": 4486,
						"region_name": "Khirbet Ain el Qanater - خربة عين القناطر"
					},
					{
						"id": 4487,
						"region_name": "Khirbet Bassal - خربة البصل"
					},
					{
						"id": 4488,
						"region_name": "Khirbet ed Daoueir - خربة الدوير صيدا"
					},
					{
						"id": 4489,
						"region_name": "Loubiye - لوبية"
					},
					{
						"id": 4490,
						"region_name": "Maamriye - معمارية"
					},
					{
						"id": 4491,
						"region_name": "Maamriyet el Kharab - معمرية الخراب"
					},
					{
						"id": 4492,
						"region_name": "Maghdouche - مغدوشة"
					},
					{
						"id": 4493,
						"region_name": "Majdelyoun - مجدليون"
					},
					{
						"id": 4494,
						"region_name": "Maqsam El  JAOUHARI - مقسم الجوهري"
					},
					{
						"id": 4495,
						"region_name": "Matariyet ech Choumar - مطرية الشومر"
					},
					{
						"id": 4496,
						"region_name": "Mazraat Aabboudiye - مزرعة العبودية"
					},
					{
						"id": 4497,
						"region_name": "Mazraat Aarab ej Jall - مزرعة عرب الجل"
					},
					{
						"id": 4498,
						"region_name": "Mazraat Ain el Qantara - مزرعة عين القنطرة"
					},
					{
						"id": 4499,
						"region_name": "Mazraat Arab Soukkar - مزرعة عرب سكر"
					},
					{
						"id": 4500,
						"region_name": "Mazraat Arab Tobbaya - مزرعة عرب  طبَايا"
					},
					{
						"id": 4501,
						"region_name": "Mazraat ej Joudaye - مزرعة الجودية"
					},
					{
						"id": 4502,
						"region_name": "Mazraat el Aite Niye - مزرعة العيتانية"
					},
					{
						"id": 4503,
						"region_name": "Mazraat el Daoudiye - مزرعة الداودية"
					},
					{
						"id": 4504,
						"region_name": "Mazraat el Hsainiye - مزرعة الحسينية"
					},
					{
						"id": 4505,
						"region_name": "Mazraat el Ouasta - مزرعة الواسطة"
					},
					{
						"id": 4506,
						"region_name": "Mazraat el Yahoudiye - مزرعة  اليهودية"
					},
					{
						"id": 4507,
						"region_name": "Mazraat Iskandarouna - مزرعة إسكندرونة"
					},
					{
						"id": 4508,
						"region_name": "Mazraat Jemjim - مزرعة جمجيم"
					},
					{
						"id": 4509,
						"region_name": "Mazraat Khaizarane - مزرعة خيزران"
					},
					{
						"id": 4510,
						"region_name": "Mazraat Khoutaryet er Rezz - مزرعة كوثرية الرز"
					},
					{
						"id": 4511,
						"region_name": "Mazraat Matariyet Jbaa - مزرعة مطيرية جباع"
					},
					{
						"id": 4512,
						"region_name": "Mazraat Sekkaniye - مزرعة السكنية"
					},
					{
						"id": 4513,
						"region_name": "Mazraat Sinai - مزرعة سيناي"
					},
					{
						"id": 4514,
						"region_name": "Mazraat Zaita - مزرعة زيتا"
					},
					{
						"id": 4515,
						"region_name": "Merouaniye - مروانية"
					},
					{
						"id": 4516,
						"region_name": "Mghairiye - مغيرية "
					},
					{
						"id": 4517,
						"region_name": "Mhaidle - المحيدلة"
					},
					{
						"id": 4518,
						"region_name": "Miyeh ou Miyeh - مية ومية"
					},
					{
						"id": 4519,
						"region_name": "Mrah Kiouane - مراح كيوان"
					},
					{
						"id": 4520,
						"region_name": "Msaileh - المصيلح"
					},
					{
						"id": 4521,
						"region_name": "Najjaryie - نجارية"
					},
					{
						"id": 4522,
						"region_name": "Oussamiyat - الاوساميات"
					},
					{
						"id": 4523,
						"region_name": "Qaaqaaii es Snoubar - قعقعية الصنوبر"
					},
					{
						"id": 4524,
						"region_name": "Qennarit - قناريت"
					},
					{
						"id": 4525,
						"region_name": "Qiyaa - قياعة"
					},
					{
						"id": 4526,
						"region_name": "Qnaitra - القنيطرة"
					},
					{
						"id": 4527,
						"region_name": "Qraiye Saida - قرية صيدا"
					},
					{
						"id": 4528,
						"region_name": "Saida - صيدا"
					},
					{
						"id": 4529,
						"region_name": "Saida Ed Dekermane - صيدا الدكرمان"
					},
					{
						"id": 4530,
						"region_name": "Saksakiye - سكسكية"
					},
					{
						"id": 4531,
						"region_name": "Salhiye - صالحية"
					},
					{
						"id": 4532,
						"region_name": "Sarafand - صرفند"
					},
					{
						"id": 4533,
						"region_name": "Sari - ساري"
					},
					{
						"id": 4534,
						"region_name": "Sfenti - سفنتا"
					},
					{
						"id": 4535,
						"region_name": "Sinai - سيناي"
					},
					{
						"id": 4536,
						"region_name": "Snaiber - مزرعة سنيبر"
					},
					{
						"id": 4537,
						"region_name": "Tanbourit - طنبوريت"
					},
					{
						"id": 4538,
						"region_name": "Tebna - تبنا"
					},
					{
						"id": 4539,
						"region_name": "Toufahta - تفاحتا"
					},
					{
						"id": 4540,
						"region_name": "Wastani - الوسطاني"
					},
					{
						"id": 4541,
						"region_name": "Zaghdraiya - زغدرايا"
					},
					{
						"id": 4542,
						"region_name": "Zaita - زيتا"
					},
					{
						"id": 4543,
						"region_name": "Zrariye - زرارية"
					}
				]
			},
			{
				"id": 26,
				"DistrictDescription": "Sour - صور",
				"Cities": [
					{
						"id": 4544,
						"region_name": "Aabbassiye - عباسية "
					},
					{
						"id": 4545,
						"region_name": "Aaitit - عيتيت"
					},
					{
						"id": 4546,
						"region_name": "Aalma ech Chaab - علما الشعب"
					},
					{
						"id": 4547,
						"region_name": "Aamrane - عمران"
					},
					{
						"id": 4548,
						"region_name": "Aazziye - عزِّيه"
					},
					{
						"id": 4549,
						"region_name": "Abou Chach - أبو شاش"
					},
					{
						"id": 4550,
						"region_name": "Ain Abu Aabdalla - عين أبو عبدالله"
					},
					{
						"id": 4551,
						"region_name": "Ain Baal - عين بعل"
					},
					{
						"id": 4552,
						"region_name": "Arzoun - ارزون"
					},
					{
						"id": 4553,
						"region_name": "Bafliye - بافليه"
					},
					{
						"id": 4554,
						"region_name": "Barich - باريش"
					},
					{
						"id": 4555,
						"region_name": "Batouliye - باتوليه"
					},
					{
						"id": 4556,
						"region_name": "Bazouriye - بازورية"
					},
					{
						"id": 4557,
						"region_name": "Bedias - بدياس"
					},
					{
						"id": 4558,
						"region_name": "Bestiyat - بستيات"
					},
					{
						"id": 4559,
						"region_name": "Biyad Sour - البياض صور"
					},
					{
						"id": 4560,
						"region_name": "Borj ech Chmali - برج الشمالي"
					},
					{
						"id": 4561,
						"region_name": "Borj el Haoua - برج الهوا"
					},
					{
						"id": 4562,
						"region_name": "Borj el Qibli - برج قبلي"
					},
					{
						"id": 4563,
						"region_name": "Borj Rahhal - برج رحال"
					},
					{
						"id": 4564,
						"region_name": "Bourghliye - برغلية"
					},
					{
						"id": 4565,
						"region_name": "Boustane Sour - بستان صور"
					},
					{
						"id": 4566,
						"region_name": "Btaichiye - بطيشية"
					},
					{
						"id": 4567,
						"region_name": "Chaaitiyeh - شعيتية"
					},
					{
						"id": 4568,
						"region_name": "Chabriha - شبريحا"
					},
					{
						"id": 4569,
						"region_name": "Chahour - شحور"
					},
					{
						"id": 4570,
						"region_name": "Chamaa - شمع"
					},
					{
						"id": 4571,
						"region_name": "Charnaiye - شارنية"
					},
					{
						"id": 4572,
						"region_name": "Chehabiye - شهابية "
					},
					{
						"id": 4573,
						"region_name": "Chihine - شيحين"
					},
					{
						"id": 4574,
						"region_name": "Debaal - دبعل"
					},
					{
						"id": 4575,
						"region_name": "Deir Aamess - ديرعامص"
					},
					{
						"id": 4576,
						"region_name": "Deir Kifa - دير كيفا"
					},
					{
						"id": 4577,
						"region_name": "Deir Qanoun - دير قانون"
					},
					{
						"id": 4578,
						"region_name": "Deir Qanoun en Nahr - دير قانون النهر"
					},
					{
						"id": 4579,
						"region_name": "Derdghaiya - دردغايا"
					},
					{
						"id": 4580,
						"region_name": "Dhaira - ظهيرة "
					},
					{
						"id": 4581,
						"region_name": "Ech Choumara - الشومارا"
					},
					{
						"id": 4582,
						"region_name": "Ed Dikiye - الديكية"
					},
					{
						"id": 4583,
						"region_name": "El Bass -  البصَ"
					},
					{
						"id": 4584,
						"region_name": "El Biyada - بياضة"
					},
					{
						"id": 4585,
						"region_name": "El Borj En-Naqoura - برج الناقورة"
					},
					{
						"id": 4586,
						"region_name": "El Kleile - قليلة"
					},
					{
						"id": 4587,
						"region_name": "El Ouardani - ورداني"
					},
					{
						"id": 4588,
						"region_name": "Er Rafid - الرافد"
					},
					{
						"id": 4589,
						"region_name": "Hallousiyet el Faouqa - حلوسية الفوقا "
					},
					{
						"id": 4590,
						"region_name": "Halloussiye - حلوسية"
					},
					{
						"id": 4591,
						"region_name": "Hammadiye - حمَادية"
					},
					{
						"id": 4592,
						"region_name": "Hamoul - حامول"
					},
					{
						"id": 4593,
						"region_name": "Hannaouiye - حناوي"
					},
					{
						"id": 4594,
						"region_name": "Hanniye - حنية"
					},
					{
						"id": 4595,
						"region_name": "Haumeiri - حميري "
					},
					{
						"id": 4596,
						"region_name": "Iskandarouna Sour - إسكندرونة"
					},
					{
						"id": 4597,
						"region_name": "Jannata - جناتا"
					},
					{
						"id": 4598,
						"region_name": "Jbal el Botm - جبال البطم"
					},
					{
						"id": 4599,
						"region_name": "Jebbain - جبين"
					},
					{
						"id": 4600,
						"region_name": "Jijim - ججيم"
					},
					{
						"id": 4601,
						"region_name": "Jouaiya - جويا"
					},
					{
						"id": 4602,
						"region_name": "Jour en Nakhl - جوار النخل"
					},
					{
						"id": 4603,
						"region_name": "Kfarnay - كفرنية"
					},
					{
						"id": 4604,
						"region_name": "Knisse Sour - كنيسة صور"
					},
					{
						"id": 4605,
						"region_name": "Labboune - لبونة"
					},
					{
						"id": 4606,
						"region_name": "Maachouq - معشوق"
					},
					{
						"id": 4607,
						"region_name": "Maaliye - معليَة"
					},
					{
						"id": 4608,
						"region_name": "Maarake - معركة"
					},
					{
						"id": 4609,
						"region_name": "Maaroub - معروب"
					},
					{
						"id": 4610,
						"region_name": "Machta El Aaziye - مشتى العزِية"
					},
					{
						"id": 4611,
						"region_name": "Mahrouneh - محرونه"
					},
					{
						"id": 4612,
						"region_name": "Majdel Zoun - مجدل زون"
					},
					{
						"id": 4613,
						"region_name": "Malkeit es Sahel - مالكية الساحل"
					},
					{
						"id": 4614,
						"region_name": "Mansouri - المنصوري"
					},
					{
						"id": 4615,
						"region_name": "Marnaba - مرنبا"
					},
					{
						"id": 4616,
						"region_name": "Marouahine - مروحين"
					},
					{
						"id": 4617,
						"region_name": "Matmoura - المطمورة"
					},
					{
						"id": 4618,
						"region_name": "Mazraat Aaiye - مزرعة عيا"
					},
					{
						"id": 4619,
						"region_name": "Mazraat Ain ez Zarqa - مزرعة عين الزرقا"
					},
					{
						"id": 4620,
						"region_name": "Mazraat Biout es Siyad - مزرعة بيوت السياد"
					},
					{
						"id": 4621,
						"region_name": "Mazraat Bsaile - مزرعة بسيلة"
					},
					{
						"id": 4622,
						"region_name": "Mazraat Deir Hanna - مزرعة دير حنا"
					},
					{
						"id": 4623,
						"region_name": "Mazraat Mechref - مزرعة المشرف"
					},
					{
						"id": 4624,
						"region_name": "Mheilib - محيليب"
					},
					{
						"id": 4625,
						"region_name": "Mjadel - المجادل"
					},
					{
						"id": 4626,
						"region_name": "Nabi Qassem - نبي قاسم"
					},
					{
						"id": 4627,
						"region_name": "Naffakhiye - نفاخية"
					},
					{
						"id": 4628,
						"region_name": "Naqoura - الناقورة"
					},
					{
						"id": 4629,
						"region_name": "Niha Sour - نيحا صور"
					},
					{
						"id": 4630,
						"region_name": "Ouadi Jilou - وادي جيلو"
					},
					{
						"id": 4631,
						"region_name": "Oum Toute - ام التوت"
					},
					{
						"id": 4632,
						"region_name": "Qalaat Maroun - قلعة مارون"
					},
					{
						"id": 4633,
						"region_name": "Qana - قانا"
					},
					{
						"id": 4634,
						"region_name": "Qasmiye - قاسمية"
					},
					{
						"id": 4635,
						"region_name": "Rachidiye - الرشيدية"
					},
					{
						"id": 4636,
						"region_name": "Ras el Ain - راس العين"
					},
					{
						"id": 4637,
						"region_name": "Rechkananey - رشكنانيه"
					},
					{
						"id": 4638,
						"region_name": "Rmadiyeh - رمادية"
					},
					{
						"id": 4639,
						"region_name": "Salaa - سلعا "
					},
					{
						"id": 4640,
						"region_name": "Sammaaiye - سماعية"
					},
					{
						"id": 4641,
						"region_name": "Siddiqine - صديقين"
					},
					{
						"id": 4642,
						"region_name": "Sour - صور"
					},
					{
						"id": 4643,
						"region_name": "Srifa - صريفا"
					},
					{
						"id": 4644,
						"region_name": "Taibe Sour - الطيبة صور"
					},
					{
						"id": 4645,
						"region_name": "Tair Debba - طير دبّه"
					},
					{
						"id": 4646,
						"region_name": "Tair Filsay - طير فلسيه"
					},
					{
						"id": 4647,
						"region_name": "Tair Harfa - طير حرفا"
					},
					{
						"id": 4648,
						"region_name": "Tair Semhat - طير سمحات"
					},
					{
						"id": 4649,
						"region_name": "Tair Zebna - طير زبنا"
					},
					{
						"id": 4650,
						"region_name": "Touairi - طويري"
					},
					{
						"id": 4651,
						"region_name": "Toura - طورا"
					},
					{
						"id": 4652,
						"region_name": "Yarine - يارين"
					},
					{
						"id": 4653,
						"region_name": "Ynouh - يانوح صور"
					},
					{
						"id": 4654,
						"region_name": "Zabqine - زبقين"
					},
					{
						"id": 4655,
						"region_name": "Zahriye - زهيرية"
					},
					{
						"id": 4656,
						"region_name": "Zalloutiye - مزرعة الزلوطية"
					}
				]
			}
		]
	}
]