export const templates = [
  {
    id: 1,
    name: "template1",
    colors: [
      "maincolor",
      "backgroundcolor",
      "textcolor",
      "categoryactive",
      "categoryunactive",
      "scrollanimator",
    ],
    features:[
      {featureName:"cart",defaultValue:true},
      {featureName:"install_app",defaultValue:true},
      {featureName:"delivery_order",defaultValue:true},
      {featureName:"dinein_order",defaultValue:true},
      {featureName:"takeaway_order",defaultValue:true},
    ]
  },
  {
    id: 2,
    name: "template2",
    colors: [
      "mainColor",
      "backgroundColor",
      "textColor",
      "categoryActive",
      "categoryActiveText",
      "categoryActiveIcon",
      "categoryUnActive",
      "categoryUnactiveIcon",
      "BoxColor",
      "BoxTextColor",
      "BoxPriceColor",
      "languageTextColor",
      "popupTextColor",
      "popupbackgroundColor",
      "searchbackground",
      "searchTextColor",
      "languagebackground",
      "formColor",
      "sidebartext",
      "sidebarbackground",
      "sidebarsearch",
      "sidebarsearchText",
      "popupbuttonText"
    ],
    features:[
      {featureName:"cart",defaultValue:true},
      {featureName:"install_app",defaultValue:true},
      {featureName:"delivery_order",defaultValue:true},
      {featureName:"dinein_order",defaultValue:true},
      {featureName:"takeaway_order",defaultValue:true},
    ]
  },
  {
    id: 3,
    name: "template3",
    colors: ["maincolor", "backgroundcolor", "textcolor"],
    features:[
      {featureName:"cart",defaultValue:true},
      {featureName:"install_app",defaultValue:true},
      {featureName:"delivery_order",defaultValue:true},
      {featureName:"dinein_order",defaultValue:true},
      {featureName:"takeaway_order",defaultValue:true},
    ]
  }


]
